import { request } from '@/components/common/transport-axios';
import { HttpRequestOptions } from '@/components/common/transport-contracts';
import { registerDependencyInRootScope } from '@/components/common/di';

export class LanguagesClient {
	public getAvailableLanguages({ getAbortFunc }: HttpRequestOptions = {}) {
		const url = `/resources/languages.json`;
		const method = 'get';
		const jsonResponseExpected = true;
		return request<ILanguageModel[]>({ url, getAbortFunc, method, jsonResponseExpected });
	}
}

export interface ILanguageModel {
	id: number;
	name: string;
	fromName: string;
	englishName: string;
	cultureName: string;
	generalizedLanguageId: number;
}

export default new LanguagesClient();

registerDependencyInRootScope(LanguagesClient);
