import { CustomizationBase } from "@/script/logic/customization/customization-base";

export class NfpCustomization extends CustomizationBase {
	override getPageSwitchDelay() {
		return 5000;
	}

	override isIgnored(el: Element): boolean {
		if (el.nodeName.toLowerCase() == 'meta' && el.getAttribute('name') === 'og:title')
			return true;
		if (el.nodeName.toLowerCase() == 'meta' && el.getAttribute('content'))
			return true;
		return false;

	}
}