<template>
	<section
		id="why"
		class="why"
	>
		<div class="container">
			<h2 class="why-title"> Why you need a multilingual website </h2>
			<div class="why-list">
				<div
					v-for="(item, key) in items"
					:key="key"
					class="why_item"
				>
					<div class="why_item--rate">
						<p class="why_item--count">
							{{ item.rate }}
						</p>
						<svg
							width="200"
							height="200"
							class="circle"
						>
							<circle
								transform="rotate(-90)"
								r="92"
								cx="-100"
								cy="100"
							/>
							<circle
								transform="rotate(-90)"
								:style="`stroke: ${item.color}; stroke-dasharray: ${578 * (item.rate / 100)}px 408px;`"
								r="92"
								cx="-100"
								cy="100"
							/>
						</svg>
					</div>
					<div class="why_item--text">
						{{ item.text }}
					</div>
					<a
						class="why_item--link"
						:href="item.linkHref"
						target="_blank"
						>{{ item.linkLabel }}</a
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import LandingMainSection from '@/components/landing/landing-main-section.vue';

	interface WhyItem {
		rate: number;
		text: string;
		linkLabel: string;
		linkHref: string;
		color: string;
	}

	@Component({
		components: { LandingMainSection },
	})
	export default class LandingWhySection extends Vue {
		public rateConst = 578;

		public items: WhyItem[] = [
			{
				rate: 90,
				text: 'of the internet users would prefer a website in their native language if they had a choice',
				linkLabel: 'Harvard Business Review',
				linkHref:
					'https://hbr.org/2012/08/speak-to-global-customers-in-t?utm_source=weglot&utm_medium=blog&utm_campaign=multilingual-website-growth-data',
				color: '#8AECDB',
			},
			{
				rate: 42,
				text: 'of your prospects never purchased from a website in a foreign language',
				linkLabel: 'Harvard Business Review',
				linkHref:
					'https://hbr.org/2012/08/speak-to-global-customers-in-t?utm_source=weglot&utm_medium=blog&utm_campaign=multilingual-website-growth-data',
				color: '#E7A3F9',
			},
			{
				rate: 56,
				text: 'of people in different countries prefer information in their own language to a lower price',
				linkLabel: 'CSA Research',
				linkHref:
					'https://weglot.com/blog/top-multilingual-website-stats-and-website-localization-trends-2021/',
				color: '#A2CAFA',
			},
		];
	}
</script>

<style lang="less" src="./landing-why-section.less" scoped></style>
