import { render, staticRenderFns } from "./toolbars-page.vue?vue&type=template&id=5b4359dc&scoped=true&"
import script from "./toolbars-page.vue?vue&type=script&lang=ts&"
export * from "./toolbars-page.vue?vue&type=script&lang=ts&"
import style0 from "./toolbars-page.vue?vue&type=style&index=0&id=5b4359dc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b4359dc",
  null
  
)

export default component.exports