<template>
	<div
		ref="container"
		:class="classes"
	>
		<slot
			name="activator"
			v-bind="{ isOpened, openMenu, toggleMenu }"
		/>
		<div
			v-if="isOpened"
			:class="dropDownClasses"
			:style="{ width }"
		>
			<slot
				name="default"
				v-bind="{ isOpened, closeMenu }"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { onClickOutside } from '@/utils/click-outside';

	@Component({})
	export default class WtMenu extends Vue {
		@Prop({ type: Boolean, default: false })
		public readonly disabled: boolean;

		private clickOutsideUnsub: (() => void) | null;

		@Prop({
			type: String,
			default: 'left bottom',
			validator: (value: string) =>
				[
					'top left',
					'top right',
					'right bottom',
					'right top',
					'bottom left',
					'bottom right',
					'left bottom',
					'left top',
				].includes(value),
		})
		public readonly position: string;

		@Prop({ type: String })
		public readonly width: string;

		public isOpened = false;

		public get classes(): Record<string, boolean> {
			return {
				'wt-menu': true,
				'wt-menu_opened': this.isOpened,
			};
		}

		public get dropDownClasses(): string {
			const [x, y] = this.position.split(' ');
			return `wt-menu__dropdown wt-menu__dropdown_position-${x} wt-menu__dropdown_position-${y}`;
		}

		public mounted() {
			this.clickOutsideUnsub = onClickOutside(
				document.body,
				this.$refs.container as HTMLElement,
				this.closeMenu,
				null,
				true,
			);
		}

		beforeDestroy() {
			this.clickOutsideUnsub?.();
		}

		public openMenu(): void {
			if (this.disabled) {
				return;
			}
			this.isOpened = true;
			this.$emit('open');
		}

		public toggleMenu(): void {
			if (this.disabled) {
				return;
			}
			if (this.isOpened) {
				this.closeMenu();
			} else {
				this.openMenu();
			}
		}

		public closeMenu(): void {
			this.isOpened = false;
			this.$emit('close');
		}
	}
</script>

<style lang="less">
	@import '~@smartcat/design-system-vue2/colors';

	.wt-menu {
		display: inline-flex;
		position: relative;
	}

	.wt-menu__dropdown {
		display: block;
		min-width: 28rem;
		box-sizing: border-box;
		max-width: 95vw;
		width: max-content;
		position: absolute;
		padding: 0.6rem;
		background: @white;
		box-shadow: 0 0 0.2rem rgba(44, 36, 64, 0.48), 0 0.4rem 1rem rgba(44, 36, 64, 0.16);
		border-radius: 0.6rem;
		z-index: 9999;
	}

	.wt-menu__dropdown_position-top {
		bottom: 100%;
		margin-bottom: 0.4rem;

		&::before {
			content: '';
			display: block;
			height: 1.6rem;
			bottom: 100%;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	.wt-menu__dropdown_position-bottom {
		top: 100%;
		margin-top: 0.4rem;

		&::after {
			content: '';
			display: block;
			height: 1.6rem;
			top: 100%;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	.wt-menu__dropdown_position-left {
		left: 0;
	}

	.wt-menu__dropdown_position-right {
		right: 0;
	}
</style>
