<template>
	<div class="sign-in-popup">
		<WtPopup
			:form-width="760"
			close-button
			@close="$emit('hide')"
		>
			<template #body>
				<img
					src="../../../assets/icons/rocket.png"
					class="sign-in-popup__logo"
					alt="Smartcat"
				/>
				<h3 class="sign-in-popup__title"> Only 3 steps to translate your entire website: </h3>
				<div class="steps">
					<div class="steps-item">
						<img
							src="../../../assets/icons/cursor-click.svg"
							class="steps-item__icon"
							alt="Cursor click"
						/>
						<p class="steps-item__text"> Create a free account on Smartcat </p>
					</div>
					<div class="steps-item">
						<img
							src="../../../assets/icons/write-text.svg"
							class="steps-item__icon"
							alt="Preview"
						/>
						<p class="steps-item__text"> Preview and edit your translation </p>
					</div>
					<div class="steps-item">
						<img
							src="../../../assets/icons/code.svg"
							class="steps-item__icon"
							alt="Code"
						/>
						<p class="steps-item__text"> Add a simple code to your website header to push it live </p>
					</div>
				</div>
				<p class="sign-in-popup__subtitle"> Make your entire website multilingual! </p>
				<!-- TODO -->
				<div
					v-if="false"
					class="input-group"
				>
					<label
						class="input-group__label"
						for="email"
						>Email address</label
					>
					<input
						id="email"
						type="text"
						class="input-group__field"
						placeholder="Enter your email address"
					/>
				</div>
				<div class="sign-in-popup__footer">
					<WtButton
						label="Sign up for free"
						view="cta-gradient"
						class="sign-in-popup__button"
						@click="signUp"
					/>
				</div>
				<!-- TODO -->
				<div
					v-if="false"
					class="sign-in-popup__social"
				>
					<a
						href="#"
						target="_blank"
						><img
							src="../../../assets/icons/social/g.svg"
							alt="Google"
					/></a>
					<a
						href="#"
						target="_blank"
						><img
							src="../../../assets/icons/social/f.svg"
							alt="Facebook"
					/></a>
					<a
						href="#"
						target="_blank"
						><img
							src="../../../assets/icons/social/p.svg"
							alt="P"
					/></a>
					<a
						href="#"
						target="_blank"
						><img
							src="../../../assets/icons/social/in.svg"
							alt="In"
					/></a>
				</div>
			</template>
		</WtPopup>
	</div>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';

	@Component({
		components: { WtPopup, WtButton },
	})
	export default class SignInPopup extends Vue {
		signUp() {
			this.$emit('click-sign-up');
		}
	}
</script>

<style lang="less" src="./sign-in-popup.less"></style>
