import { LangSelectorConfig } from "@/script/lang-selector/lang-selector-config";
import { ILangSelector } from "@/script/lang-selector/i-lang-selector";
import { InjectedCodeLang } from "@/shared";

export class LovisaLangSelector implements ILangSelector {
	private cfg: LangSelectorConfig;
	private langs: InjectedCodeLang[];
	private selectorCtr: HTMLDivElement;

	constructor(cfg: LangSelectorConfig) {
		this.cfg = cfg;
	}

	async init() {
		this.langs = await this.cfg.langs();
		this.render();
	}

	async reloadLangs(): Promise<void> {
		this.langs = await this.cfg.langs();
		this.render();
	}

	render(): void {
		const parent = document.getElementsByClassName('footer__region-settings')[0];

		if (!this.selectorCtr) {
			this.selectorCtr = document.createElement('div');
			this.selectorCtr.setAttribute('sc-ignore', 'true')
			this.selectorCtr.style.marginTop = '-1px';
			parent.appendChild(this.selectorCtr);
		}

		this.selectorCtr.innerHTML = '';
		const selector = document.createElement('select');
		this.selectorCtr.appendChild(selector);
		this.langs.forEach(lang => {
			const option = document.createElement('option');
			option.value = lang.locale;
			option.text = lang.name;
			selector.appendChild(option);
		});
		selector.value = this.cfg.getCurrentLang(this.langs)?.locale;
		// listen to change event
		selector.addEventListener('change', () => {
			if (selector.value) {
				this.cfg.switchLocale(selector.value);
			}
		});
	}

	updateConfig(config: Partial<LangSelectorConfig>): void {
		this.cfg = { ...this.cfg, ...config };
		this.init();
	}

	setIsLoading(): void {
	}
}

