<template>
	<div class="wt-code">
		<div
			class="wt-code__code"
			:class="{ error: error }"
		>
			<span>{{ code }}</span>
			<div
				v-if="false"
				class="wt-code__code--instruction"
			>
				<img
					src="../../../assets/icons/instruction.svg"
					alt="Instruction"
				/>
				<div class="info"> Share instructions with colleagues </div>
			</div>
			<a
				ref="copyButton"
				href="/copy"
				class="copy"
				@click.prevent="$emit('copy')"
			>
				<img
					src="../../../assets/icons/copy.svg"
					alt="Copy"
				/>
				<span class="label">Copy code</span>
			</a>
		</div>
		<div
			v-if="error"
			class="alert-danger"
		>
			<img
				src="../../../assets/icons/info-danger.svg"
				alt="Alert"
			/>
			<span>{{ error }}</span>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component
	export default class WtCode extends Vue {
		@Prop({ type: String, default: '' })
		public readonly code: string;

		@Prop({ type: String, default: false })
		public readonly error: string;

		public mounted() {
			if (this.error) {
				(this.$refs.copyButton as any).focus();
			}

			this.$watch(
				() => this.error,
				(newVal) => {
					if (newVal) {
						(this.$refs.copyButton as any).focus();
					}
				},
			);
		}
	}
</script>

<style lang="less" scoped>
	@import '~@smartcat/design-system-vue2/colors';

	.wt-code {
		&__code {
			padding: 20px;
			border-radius: 6px;
			background: #f9f8f9;
			color: #413a53;
			position: relative;
			text-align: left;
			line-height: 16px;
			font-size: 12px;
			word-break: break-all;

			&--instruction {
				position: absolute;
				right: 30px;
				top: 0;
				cursor: pointer;

				&:hover {
					> .info {
						display: block;
					}
				}

				> .info {
					display: none;
					position: absolute;
					width: 211px;
					height: 32px;
					color: #ffffff;
					background: #413a53;
					border-radius: 6px;
					padding: 8px;
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					text-align: center;
					top: -40px;
					left: -100px;

					&::before,
					&::after {
						content: '';
						position: absolute;
						left: 100px;
						bottom: -10px;
						border: 10px solid transparent;
						border-top: 10px solid #413a53;
						border-radius: 5px;
					}

					&::after {
						border-top: 10px solid #413a53;
						bottom: -14px;
					}
				}
			}
		}

		.alert-danger {
			display: flex;
			color: @radiation-carrot-70;
			align-items: flex-start;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			text-align: left;
			margin-top: 6px;

			span {
				display: block;
				margin-left: 5px;
			}

			img {
				margin-top: 1px;
			}
		}

		.copy {
			color: #6239c6;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			padding: 6px 10px;
			margin-right: 10px;
			width: 120px;
			margin-top: 20px;

			&:focus {
				box-shadow: 0 0 0 4px rgba(98, 57, 198, 0.1);
				border-radius: 4px;
			}

			&:hover {
				background: #dbd1f2;
			}

			> .label {
				display: block;
				margin-left: 8px;
			}
		}
	}
</style>
