<template>
	<div class="wt-vertical-tabs">
		<div class="wt-vertical-tabs__sidebar">
			<div class="wt-vertical-tabs__sidebar-title">
				{{ title }}
			</div>
			<div class="wt-vertical-tab-buttons">
				<div
					v-for="(name, i) in tabs"
					:key="name"
					:class="{ active: i === activeTab }"
					@click="selectTab(i)"
				>
					{{ name }}
				</div>
			</div>
		</div>
		<div class="wt-vertical-tabs__active-tab">
			<div class="wt-vertical-tabs__active-tab-title">
				{{ tabs[activeTab] }}
			</div>
			<slot :active-tab="activeTab" />
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component({})
	export default class WtTabs extends Vue {
		public activeTab = 0;

		@Prop({ type: String, required: true })
		public title: string;

		@Prop({ type: Array, default: () => [] as any[] })
		public tabs: string[];

		public selectTab(index: number) {
			this.activeTab = index;
		}
	}
</script>

<style lang="less" scoped>
	@import '~@smartcat/design-system-vue2/colors';

	.wt-vertical-tabs {
		display: flex;
		align-items: stretch;
		width: 100%;
	}

	.wt-vertical-tab-buttons {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 20px;

		& > div {
			position: relative;
			color: @mulberry-purple-new;
			font-size: 14px;
			line-height: 20px;
			font-weight: 600;
			user-select: none;
			width: 100%;
			text-align: left;

			&.active {
				color: @fuchsia-blue;

				&::after {
					position: absolute;
					top: 0;
					right: 0;
					content: '';
					height: 100%;
					width: 3px;
					background: @fuchsia-blue;
					border-radius: 2px;
				}
			}

			&:not(.active) {
				cursor: pointer;
				transition: opacity 200ms;

				&:hover {
					color: @fuchsia-blue;
				}
			}
		}
	}

	.wt-vertical-tabs__sidebar {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
		box-shadow: 1px 0 0 #efefef;
		min-width: 200px;
	}

	.wt-vertical-tabs__sidebar-title {
		color: @mulberry-purple-40;
		font-size: 12px;
		line-height: 16px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.wt-vertical-tabs__active-tab {
		display: flex;
		flex-direction: column;
		margin-left: 40px;
		flex: 1;
		min-height: 470px;
	}

	.wt-vertical-tabs__active-tab-title {
		color: @mulberry-purple-90;
		font-size: 24px;
		line-height: 30px;
		font-weight: 700;
		text-align: left;
		padding-bottom: 30px;
	}
</style>
