type Scope = 'root' | 'page';
type Type<T> = new () => T;
type InjectionMap<T = any> = Map<Type<any>, T>;

const registeredDependencies: InjectionMap<Scope> = new Map();
const instancesCache: InjectionMap = new Map();

function injector<T>(target: Type<T>): T {
	const scope = registeredDependencies.get(target);
	if (scope == null) {
		throw new Error(`[di] Injection '${target.name}' is not registered`);
	}
	if (instancesCache.has(target)) {
		return instancesCache.get(target);
	}
	const instance = new target(); // eslint-disable-line
	instancesCache.set(target, instance);
	return instance;
}

function registerDependency<T>(type: Type<T>, scope: Scope) {
	if (registeredDependencies.has(type)) {
		throw new Error(`[di] Injection '${type.name}' already registered`);
	}
	registeredDependencies.set(type, scope);
}

export function registerInstanceInRootScope<T>(type: Type<T>, instance: T) {
	registerDependency(type, 'root');
	instancesCache.set(type, instance);
}

export function registerDependencyInRootScope<T>(type: Type<T>) {
	registerDependency(type, 'root');
}

export function registerDependencyInPageScope<T>(type: Type<T>) {
	registerDependency(type, 'page');
}

export function clearInstance<T>(type: Type<T>) {
	instancesCache.delete(type);
}

export function clearInstancesInPageScope() {
	instancesCache.forEach((_instance, type) => {
		if (registeredDependencies.get(type) === 'page') {
			clearInstance(type);
		}
	});
}

export function inject<T>(target: Type<T>): T {
	return process.env.NODE_ENV !== 'test' ? injector<T>(target) : undefined;
}
