<template>
	<button
		class="browser-toolbar-button"
		:class="{ disabled: isDisabled }"
		@click="triggerClick"
	>
		<ScIcon
			v-if="icon && !showLoading"
			color="white"
			:name="icon"
		/>
		<ScLoaderSpin
			v-if="showLoading"
			white
		/>
		<p class="browser-toolbar-button__content">
			<slot />
		</p>
	</button>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ScIcon, ScLoaderSpin } from '@smartcat/design-system-vue2';

	@Component({
		components: { ScIcon, ScLoaderSpin },
	})
	export default class ToolbarButton extends Vue {
		public asyncActionInProgress = false;

		@Prop({ required: false })
		public icon: string;

		@Prop({ required: false })
		public loading: boolean;

		@Prop({ required: false })
		public disabled: boolean;

		@Prop({ required: false })
		public asyncAction: () => Promise<void>;

		public get isDisabled() {
			if (this.disabled) {
				return true;
			}
			return this.showLoading;
		}

		public get showLoading() {
			if (this.loading === true || this.loading === false) {
				return this.loading;
			}
			return this.asyncActionInProgress;
		}

		public async triggerClick() {
			if (this.isDisabled) {
				return;
			}

			this.$emit('click');
			if (this.asyncActionInProgress) {
				return;
			}
			if (!this.asyncAction) {
				return;
			}
			this.asyncActionInProgress = true;
			try {
				await this.asyncAction();
			} finally {
				this.asyncActionInProgress = false;
			}
		}
	}
</script>

<style lang="less" src="./toolbar-button.less" scoped />
