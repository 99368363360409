import { render, staticRenderFns } from "./wt-switch.vue?vue&type=template&id=02b3eff8&scoped=true&"
import script from "./wt-switch.vue?vue&type=script&lang=ts&"
export * from "./wt-switch.vue?vue&type=script&lang=ts&"
import style0 from "./wt-switch.vue?vue&type=style&index=0&id=02b3eff8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b3eff8",
  null
  
)

export default component.exports