<template>
	<section
		id="faq"
		class="faq"
	>
		<div class="container">
			<h2 class="faq-title"> Frequently Asked Questions </h2>
			<div class="faq-questions">
				<div
					v-for="(question, key) in questions"
					:key="key"
					class="faq-questions__item"
					:class="{ active: activeQuestions.includes(key) }"
				>
					<h4
						class="faq-questions__item--title"
						@click="showAnswer(key)"
					>
						<span>{{ question.title }}</span>
						<img
							class="arrow"
							src="../../assets/images/landing/arrow.svg"
							alt="Arrow"
						/>
					</h4>
					<div
						class="faq-questions__item--content"
						v-html="question.answer"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';

	interface Question {
		title: string;
		answer: string;
	}

	@Component
	export default class LandingFaqSection extends Vue {
		public activeQuestions: number[] = [];
		public questions: Question[] = [
			{
				title: 'Does Smartcat Website Translator work with any technology or Content Management System?',
				answer: 'Yes, it does! Just add a special JavaScript code snippet on your website to enable other website languages. No need to set up an integration or manually copy-paste and upload your translations.',
			},
			{
				title: 'Where are translations located?',
				answer: 'Along with the Website Translator JavaScript code, your website translations will be published in our content delivery network (CDN), a geographically distributed group of data centers, to ensure your website visitors get the translated content as quickly as possible.',
			},
			{
				title: 'I have high website traffic. Can Smartcat’s Website Translator handle it?',
				answer: "Smartcat’s Website Translator serves all of the assets from a CDN and doesn't use any Smartcat cloud infrastructure. So no matter how many website visitors you get each day, you are guaranteed to get the best website performance regardless of Smartcat platform uptime/availability. Smartcat uses a proxy-based rendering mode only when you are previewing the website as a manager: this way you can see all the current translations and work with any website pages prior to publishing translations live.",
			},
			{
				title: 'How many languages does the Website Translator support?',
				answer: 'With Smartcat, you can translate your website into more than 175 languages. You can find the list of supported languages <a style="text-decoration: underline; color: #7e64bd;" href="https://smartcat.com/Home/Languages" target="_blank">here</a>. All of these languages are supported by the Website Translator.',
			},
			{
				title: 'What kind of machine translation (MT) engines do you use?',
				answer: 'Under the hood, Smartcat uses the best-in-class machine translation engines including Google, Microsoft, Baidu, DeepL, and Amazon in a single interface. We select the best engine for each website language to ensure the highestt quality results.',
			},
			{
				title: 'Can I preview more than one page?',
				answer: 'As you use the Website Translator, we will immediately give you a preview of your website home page in the first language that you picked. Simply create a free account on Smartcat to continue working on your website: preview other pages or switch to other languages.',
			},
			{
				title: 'Do I have to use machine translation? Can I translate everything manually?',
				answer: 'You start with machine translation to speed up the translation process and preview your website. Once the automatic translation has been completed, you can press Edit on any web page in the Website Translator, to review the translations and edit any of them. Most importantly, as you review or tweak these translations, Smartcat will remember them, and use them first when translating other website pages. The more you edit and review your translations, the faster Smartcat will learn and improve the automatic translation quality even further.',
			},
			{
				title: 'Do I have to edit the translations myself?',
				answer: "You can, but you don't have to. You can bring in your own colleagues or language agency, or find freelancers directly on Smartcat and invite them to work on your website.",
			},
			{
				title: 'Can I bring my own translators to Smartcat?',
				answer: 'Yes, you can invite as many users as you like without having to pay for additional seats. Check out <a style="text-decoration: underline; color: #7e64bd;" href="https://www.smartcat.com/blog/5-tips-to-build-and-grow-your-localization-team-with-smartcat/" target="_blank">our guide</a> to learn how you can collaborate with your whole team on Smartcat.',
			},
			{
				title: 'I want to hire freelancers on Smartcat, but how do I pay them?',
				answer: 'The best thing about Smartcat is that you can work with as many freelancers or language companies as needed without any contract hassle. As a Smartcat client, you will have a single contract with Smartcat, and a single monthly invoice from Smartcat. We will, in turn, manage all the individual payments, currencies, and legal matters to pay each supplier.',
			},
		];

		showAnswer(key: number) {
			if (this.activeQuestions.includes(key)) {
				this.activeQuestions = this.activeQuestions.filter((question) => question !== key);
			} else {
				this.activeQuestions.push(key);
			}
		}
	}
</script>

<style lang="less" src="./landing-faq-section.less" scoped></style>
