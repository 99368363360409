<template>
	<section class="toolbars">
		<div class="toolbars__item">
			<BrowserToolbar :is-show-sign-up="true">
				<template #buttons>
					<WtButton
						label="Edit"
						icon="pencil"
						:async-action="() => asyncButton()"
					/>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						:machine-translation="100"
						:professional-translation="0"
						:forward-disabled="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
					/>
				</template>
			</BrowserToolbar>
		</div>
		<hr style="border-top: 1px solid #e3e3e3" />
		<div class="toolbars__item">
			<BrowserToolbar
				:smart-word-count="13000"
				:is-show-settings="true"
			>
				<template #buttons>
					<WtButton
						label="Edit"
						icon="pencil"
						:async-action="() => asyncButton()"
					/>
					<WtButton
						label="Publish"
						icon="upload"
						view="border"
						:async-action="() => asyncButton()"
					/>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						:machine-translation="100"
						:professional-translation="0"
						:forward-disabled="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
					/>
				</template>
			</BrowserToolbar>
		</div>
		<hr style="border-top: 1px solid #e3e3e3" />
		<div class="toolbars__item">
			<BrowserToolbar
				:smart-word-count="13000"
				:is-show-settings="true"
			>
				<template #buttons>
					<WtButton
						label="Start translation"
						icon="magic"
						:async-action="() => asyncButton()"
					/>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						:machine-translation="0"
						:professional-translation="0"
						:forward-disabled="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
					/>
				</template>
			</BrowserToolbar>
		</div>
		<hr style="border-top: 1px solid #e3e3e3" />
		<div class="toolbars__item">
			<BrowserToolbar
				:smart-word-count="13000"
				:is-show-settings="true"
			>
				<template #buttons>
					<WtButton
						label="Start translation"
						icon="magic"
						spin
					/>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						:machine-translation="12"
						:professional-translation="0"
						:forward-disabled="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
					/>
				</template>
			</BrowserToolbar>
		</div>
		<hr style="border-top: 1px solid #e3e3e3" />
		<div class="toolbars__item">
			<BrowserToolbar
				:smart-word-count="7123"
				:is-show-settings="true"
			>
				<template #buttons>
					<WtButton
						label="Edit"
						icon="pencil"
						spin
					/>
					<WtButton
						label="Publish"
						icon="upload"
						view="border"
						:async-action="() => asyncButton()"
					/>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						:machine-translation="100"
						:professional-translation="0"
						:forward-disabled="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
					/>
				</template>
			</BrowserToolbar>
		</div>
		<hr style="border-top: 1px solid #e3e3e3" />
		<div class="toolbars__item">
			<BrowserToolbar
				:smart-word-count="7123"
				:is-show-settings="true"
			>
				<template #buttons>
					<WtButton
						label="Edit"
						icon="pencil"
						:async-action="() => asyncButton()"
					/>
					<WtButton
						label="Publish"
						icon="upload"
						view="border"
						:async-action="() => asyncButton()"
					/>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						:machine-translation="78"
						:professional-translation="12"
						:forward-disabled="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
						:sync-project-action="() => asyncButton()"
						:machine-translation-spin="true"
						:professional-translation-spin="true"
					/>
				</template>
			</BrowserToolbar>
		</div>
		<hr style="border-top: 1px solid #e3e3e3" />
		<div class="toolbars__item">
			<BrowserToolbar
				:smart-word-count="7123"
				:is-show-settings="true"
			>
				<template #buttons>
					<WtButton
						label="Edit"
						icon="pencil"
						view="border"
						:async-action="() => asyncButton()"
					/>
					<WtButton
						label="Publish"
						icon="upload"
						:async-action="() => asyncButton()"
					/>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						:machine-translation="100"
						:professional-translation="100"
						:forward-disabled="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
						:sync-project-action="() => asyncButton()"
					/>
				</template>
			</BrowserToolbar>
		</div>
		<hr style="border-top: 1px solid #e3e3e3" />
		<div class="toolbars__item">
			<BrowserToolbar
				:smart-word-count="7123"
				:is-show-settings="true"
			>
				<template #buttons>
					<WtButton
						label="Edit"
						icon="pencil"
						view="border"
						:async-action="() => asyncButton()"
					/>
					<WtButton
						label="Publish"
						icon="upload"
						spin
					/>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						:machine-translation="100"
						:professional-translation="100"
						:forward-disabled="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
						:sync-project-action="() => asyncButton()"
					/>
				</template>
			</BrowserToolbar>
		</div>
	</section>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import BrowserToolbar from '@/components/widgets/browser-toolbar/browser-toolbar.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import BrowserNavigation from '@/components/widgets/browser-navigation/browser-navigation.vue';

	@Component({
		components: { BrowserToolbar, BrowserNavigation, WtButton },
	})
	export default class ToolbarsPage extends Vue {
		public async asyncButton() {
			try {
				await this.asyncFunc();
			} catch (e) {
				console.error(e);
			}
		}

		public asyncFunc() {
			return new Promise((resolve) => {
				setTimeout(resolve, 1000);
			});
		}

		public event() {}
	}
</script>

<style lang="less" scoped>
	.toolbars {
		width: 100%;

		&__item {
			width: 100%;
			margin-bottom: 20px;
		}
	}
</style>
