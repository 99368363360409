<template>
	<div style="padding: 150px; width: 100%; text-align: center">
		<h1>Access denied.</h1>
		<div>Please sign in to continue.</div>
		<br />
		<ScButton
			view="simple"
			@click="signIn"
		>
			Sign In
		</ScButton>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { SmartcatApiService } from '@/shared/api/smartcatApiService';
	import { ApiClient } from '@/shared/api/api-client';
	import { WebSiteInfo } from '@/shared';
	import { ScButton } from '@smartcat/design-system-vue2';

	@Component({
		components: {
			ScButton,
		},
	})
	export default class WebSitePageList extends Vue {
		private apiService = new SmartcatApiService(new ApiClient());

		@Prop({ required: true })
		public webSite: WebSiteInfo = null;

		public async signIn() {
			const currentUrl = window.location.href;
			const currentUserSettings = await this.apiService.getSettings();
			const wstAuthenticationUrl = '/integrations/web-translation?backUrl=' + encodeURIComponent(currentUrl);
			window.location.href =
				currentUserSettings.smartcatApiUrl.replace(/\/$/, '') +
				'/signIn?requiredAccountId=' +
				this.webSite.accountId +
				'&backUrl=' +
				encodeURIComponent(wstAuthenticationUrl);
		}
	}
</script>

<style lang="less" scoped>
@import '@smartcat/design-system-vue2/colors';

	* {
		font-family: Inter, sans-serif;
	}
</style>
