export function trimEndChar(input: string, c: string) {
	while (input.endsWith(c)) {
		input = input.slice(0, -c.length);
	}

	return input;
}

export function trimStartChar(input: string, c: string) {
	while (input.startsWith(c)) {
		input = input.slice(c.length);
	}

	return input;
}
