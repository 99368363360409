import { CustomizationBase } from '@/script/logic/customization/customization-base';
import { ISectionInfo } from '@/script/logic/extraction/section-info';
import { currencyRegex, taxesRegex } from '@/script/logic/customization/shopify-customization';
import { ShopifyBaseSection } from '@/script/logic/customization/shopify-base-section';

export const trademarkRegex = /^®|™/g

export abstract class ShopifyCustomizationV2 extends CustomizationBase {
	getAutoExportAudiencePercent(): number | undefined {
		return 0.1;
	}

	canCreateLangSelector(): boolean {
		return true;
	}

	public isIgnored(node: Node): boolean {
		if (node instanceof Element) {
			const el = node as HTMLElement;
			if (el.childNodes.length == 1 && el.childNodes[0].nodeType == 3) {
				return this.isInvalidText(node.textContent);
			}
		} else if (node.nodeType === 3) {
			return this.isInvalidText(node.textContent);
		}
		return false;

	}

	isInvalidText(t: string): boolean {
		return !!(t.match(currencyRegex) || t.match(taxesRegex) || t.match(trademarkRegex));
	}

	protected getSection(baseSection: ShopifyBaseSection, element: Element): ISectionInfo | null {
		const section = baseSection.getSection();
		if (section) {
			return {
				id: section
			};
		}

		const productLink = element.closest('a[href]');
		if (productLink) {
			const href = productLink.getAttribute('href');
			if (href && href.indexOf('/products/') > 0) {
				const productName = href.split('/products/')[1].split('?')[0];
				if (productName.match(/[a-z]{3,}/)) {
					return {
						id: 'dynamic/description/' + productName,
					}
				}
			}
		}
	}
}
