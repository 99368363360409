<template>
	<div
		class="wt-badge"
		:class="classes"
		@click="triggerClick"
	>
		<WtIcon
			v-if="showLoading"
			class="wt-badge__icon wt-badge__spin"
			name="spinner"
			:color="view === 'info' ? 'green' : 'inherit'"
			:disabled="disabled || showLoading"
		/>
		<WtIcon
			v-if="hasIcon && !showLoading"
			class="wt-badge__icon"
			:name="icon"
			:color="view === 'info' ? 'green' : 'inherit'"
			:disabled="disabled"
		/>
		<span class="wt-badge__value">{{ value }}%</span>
		<p
			v-if="tooltip"
			class="wt-badge__tooltip"
		>
			{{ tooltip }}
		</p>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import WtIcon from '@/components/widgets/wt-icon/wt-icon.vue';

	@Component({
		components: { WtIcon },
	})
	export default class WtBadge extends Vue {
		@Prop({ default: '' })
		public readonly value: string | number;

		@Prop({ type: String, default: null })
		public readonly icon: string;

		@Prop({ type: Boolean, default: false })
		public readonly disabled: boolean;

		@Prop({ type: String, default: 'basic' })
		public readonly view: string;

		@Prop({ type: Boolean, default: false })
		public readonly spin: boolean;

		@Prop({ required: false })
		public asyncAction: () => Promise<void>;

		@Prop({ type: String, default: null })
		public readonly tooltip: string;

		public asyncActionInProgress = false;

		private views = ['basic', 'info'];

		public get hasIcon() {
			return this.icon !== null;
		}

		public get viewClass(): string {
			return this.views.includes(this.view) ? this.view : 'basic';
		}

		public get classes() {
			return {
				[`view-${this.viewClass}`]: true,
				disabled: this.disabled || this.showLoading,
			};
		}

		public get showLoading() {
			return this.spin || this.asyncActionInProgress;
		}

		public async triggerClick() {
			if (this.disabled) {
				return;
			}

			this.$emit('click');

			if (this.asyncActionInProgress) {
				return;
			}

			if (!this.asyncAction) {
				return;
			}

			this.asyncActionInProgress = true;

			try {
				await this.asyncAction();
			} finally {
				this.asyncActionInProgress = false;
			}
		}
	}
</script>

<style lang="less" scoped>
	.wt-badge {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px 4px;
		background: #f2f1f4;
		border-radius: 4px;
		cursor: pointer;
		width: 60px;
		height: 20px;
		position: relative;

		&.disabled {
			pointer-events: none;
		}

		&.view-basic.disabled {
			background: #f2f1f4;
			color: #aba7b3;
		}

		&.view-info.disabled {
			background: #f0faf3;
			color: #98ddaa;
		}

		&.view-info {
			background: #d3f0db;
			color: #226735;
		}

		&__icon {
			margin-right: 4px;
		}

		&__value {
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
		}

		&__spin {
			animation-name: spin;
			animation-duration: 1s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

		&:hover {
			.wt-badge__tooltip {
				opacity: 1;
				visibility: visible;
			}
		}

		&__tooltip {
			cursor: auto;
			transition: 0.1s ease;
			opacity: 0;
			visibility: hidden;
			color: #ffffff;
			background: #413a53;
			border-radius: 6px;
			padding: 8px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			position: absolute;
			bottom: calc(-100% - 20px);
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;
			z-index: 99999;

			&::after {
				content: '';
				height: 15px;
				position: absolute;
				top: -15px;
				left: 0;
				width: 100%;
			}

			&::before {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				position: absolute;
				margin: 0 5px;
				border-color: #413a53;
				z-index: 1;
				border-width: 0 5px 5px 5px;
				border-left-color: transparent !important;
				border-right-color: transparent !important;
				border-top-color: transparent !important;
				top: -5px;
				left: calc(50% - 10px);
			}
		}

		@keyframes spin {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
</style>
