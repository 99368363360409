import { CustomizationCfg } from '@/script/logic/customization/customization-cfg';
import { CustomizationBase } from "@/script/logic/customization/customization-base";

export class InokimCustomization extends CustomizationBase {
	constructor(private cfg: CustomizationCfg) {
		super();
		this.cfg = cfg;
	}

	public override canChangeLocale(): boolean {
		return true;
	}

	public override canDetectLocale(): boolean {
		return true;
	}

	public override changeLocale(newLocale: string): void {
		const newLocation = this.getNewLocation(window.location.href, newLocale);
		window.location.href = newLocation;
	}

	public getUrlForLocale(url: string, newLocale: string): string {
		const newLocation = this.getNewLocation(url, newLocale);
		return newLocation;
	}

	public getNewLocation(location: string, newLocale: string): string {
		const url = new URL(location);
		const lang = newLocale.split('-')[0];
		if (lang.toLowerCase() == 'en') {
			return `https://inokim.com` + url.pathname + url.search;
		} else {
			return `https://` + lang + `.inokim.com` + url.pathname + url.search;
		}
	}

	public detectLocaleByUrl(location: string): string {
		const url = new URL(location);
		const lang = this.cfg.langs.find(
			(lang) => lang.toLowerCase().split('-')[0] == url.hostname.split('.')[0].split('-')[0].toLowerCase(),
		);
		if (lang) {
			return lang;
		}
		return 'en-GB';
	}

}
