<template>
	<button
		class="edit-dropdown"
		:class="{
			'edit-dropdown-disabled': disabled,
			'edit-dropdown-loading': loading
		}"
		@blur="showPopover=false"
	>
		<div class="main">
			<div class="clickZone" @click="onEditClick">
				<svg
					v-if="loading"
					class="spinner"
					width="22"
					height="22"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle
						opacity="0.15"
						cx="12"
						cy="12"
						r="9"
						stroke="#A4A4A4"
						stroke-width="2.2"
					/>
					<path
						d="M3 12C3 7.05 7.05 3 12 3C16.95 3 21 7.05 21 12C21 16.95 16.95 21 12 21"
						stroke="#A4A4A4"
						stroke-width="2.2"
						stroke-miterlimit="10"
						stroke-linecap="round"
					/>
				</svg>
				<WtIcon
					v-else
					name="pencil"
					class="icon"
					:size="24"
				/>
				Edit
			</div>
			<div class="separator"></div>
			<div class="arrow"
				 @click="togglePopup"
				 :class="{'arrow-open': showPopover}">
				<img
					style="transform: rotate(3.142rad);"
					v-if="showPopover"
					width="12px"
					height="12px"
					src="@/assets/icons/arrow-down.svg"
				/>
				<img
					v-else
					width="12px"
					height="12px"
					src="@/assets/icons/arrow-down.svg"
				/>
			</div>
		</div>
		<div class="dropdown-panel" v-if="showPopover">
			<div v-if="!options.length" class="dropdown-title">
				No sections to edit
			</div>
			<div v-if="options.length" class="dropdown-title">EDIT SECTION</div>
			<div v-if="options.length" class="items-container">

				<EditDropdownItem
					v-for="option in options"
					v-bind:key="option"
					:title="option"
					@click="select(option)"
				/>
			</div>
		</div>
	</button>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import SourceLanguageSelector from '@/components/widgets/languages/source-selector.vue';
	import LangSelector from '@/components/widgets/lang-selector/lang-selector.vue';
	import WtBadge from '@/components/widgets/wt-badge/wt-badge.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import WtIcon from "@/components/widgets/wt-icon/wt-icon.vue";
	import EditDropdownItem from "@/components/widgets/browser-navigation/edit-dropdown/edit-dropdown-item.vue";

	@Component({
		components: { EditDropdownItem, WtIcon, LangSelector, SourceLanguageSelector, WtBadge, WtButton },
	})
	export default class EditDropdown extends Vue {
		@Prop({ required: true })
		public options: string[];

		@Prop({ required: true })
		public disabled: boolean;

		@Prop({ required: true })
		public loading: boolean;

		showPopover = false;

		onEditClick() {
			if (this.disabled) {
				return;
			}
			this.showPopover = false;
			this.$emit('edit-click');
		}

		select(option: string) {
			if (this.disabled) {
				return;
			}
			this.$emit('edit-item', option);
			this.showPopover = false;
		}

		togglePopup() {
			if (this.disabled) {
				return;
			}
			this.showPopover = !this.showPopover;
		}
	}
</script>

<style lang="less" scoped>
@import "@smartcat/design-system-vue2/colors";

.edit-dropdown {
	position: relative;
	text-align: left;
	background: transparent;

	.main {
		background: white;
		text-align: left;
		align-items: center;
		display: flex;
		flex-direction: row;
		border-radius: 6px;
		border: 2px solid @mulberry-purple-20;
		padding: 4px 0px 4px 8px;
		cursor: pointer;
		color: @mulberry-purple;
		font-size: 14px;
		height: 34px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;

		&:hover {
			background: @mulberry-purple-20;
		}

		.clickZone {
			display: flex;
			flex-direction: row;
		}
	}
}

.separator {
	height: 20px;
	width: 2px;
	margin-right: 0px;
	margin-left: 8px;
	background: @mulberry-purple-20;
}

.arrow {
	flex-direction: row;
	align-items: center;
	justify-content: center;
	display: flex;
	width: 12px;
	height: 25px;
	padding-left: 13px;
	padding-right: 13px;
	margin: 2px;
	border-radius: 6px;

	&:hover {
		background: @mulberry-purple-35;
	}
}

.arrow-open {
	background: @mulberry-purple-30;
}

.dropdown-title {
	color: @mulberry-purple-40;
	font-size: 12px;
	text-transform: capitalize;
	padding: 10px 18px 10px 18px;
}

.spinner {
	animation-name: spin;
	animation-duration: 0.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	margin-right: 8px;
}

.edit-dropdown-disabled {

	.main {
		cursor: auto;
		background: @mulberry-purple-10;
		color: @mulberry-purple-40;

		&:hover {
			background: @mulberry-purple-10;
		}

		.arrow {
			&:hover {
				background: @mulberry-purple-10;
			}
		}
	}
}

.edit-dropdown-loading {
	.main {
		cursor: progress;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.icon {
	background: none;
	color: #6b6679;
	padding: 5px 2px 1px 2px;
	border: none;

	/deep/ svg {
		width: 16px;
		height: 16px;
		stroke: #6b6679;
	}
}

.target-container {
	display: flex;
	flex-direction: row;
	padding-bottom: 4px;
	padding-top: 4px;
}

.dropdown-panel {
	position: absolute;
	width: 460px;
	border-radius: 6px;
	top: 32px;
	color: @mulberry-purple;
	background: white;
	border: 1px solid @mulberry-purple-20;
	right: 0;
	padding: 8px 0px;
	max-height: 200px;
	overflow-y: auto;
	box-shadow: 0px 4px 10px 0px rgba(44, 36, 64, 0.16), 0px 0px 2px 0px rgba(44, 36, 64, 0.48);


	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background: @mulberry-purple-35;
		border-radius: 3px;
		padding-left: 6px;
		padding-right: 4px;

		&:hover {
			background: @mulberry-purple-40;
		}

		&:disabled {
			background: @mulberry-purple-10;
		}
	}

}
</style>
