<template>
	<div class="browser-nav">
		<div class="browser-nav-controls">
			<IconButton
				class="browser-nav-back"
				:disabled="backwardDisabled"
				tooltip="Previous page"
				@click="$emit('click-back')"
			>
				<img src="../../../assets/icons/arrow-left.svg"/>
			</IconButton>
			<IconButton
				class="browser-nav-forward"
				:disabled="forwardDisabled"
				tooltip="Next page"
				@click="$emit('click-forward')"
			>
				<img src="../../../assets/icons/arrow-right.svg"/>
			</IconButton>
		</div>
		<input
			class="browser-nav-address"
			:value="url"
			@change="onUrlChange"
			@keydown="submitNavPath"
		/>
		<div class="browser-nav-lang">
			<LangSelector
				:disable-language-addition="disableLanguageAddition"
				:source-lang="sourceLang"
				:langs="siteLangs"
				:lang-list-is-loading="langListIsLoading"
				:is-loading="langIsAdding"
				@show-lang-panel="$emit('show-lang-panel')"
				@select-lang="$emit('select-lang', $event)"
				@select-available-lang="$emit('select-lang', $event)"
			>
				<template #button-caption>
					{{ currentLangName }}
				</template>
			</LangSelector>
		</div>
		<div class="browser-nav__badges">
			<StatsPopover>
				<template #target>
					<WtBadge
						:value="(stats && stats.wholePage && stats.wholePage.totalTranslatedPercent) || 0"
						icon="magic-stick"
						:disabled="(stats && stats.wholePage && stats.wholePage.totalFragmentCount  || 0 )=== 0"
						:spin="machineTranslationSpin"
					/>
					<WtBadge
						:async-action="() => syncProjectAction()"
						:value="(stats &&  stats.wholePage && stats.wholePage.scTranslatedPercent) || 0"
						icon="user"
						view="info"
						:spin="professionalTranslationSpin"
					/>
				</template>
				<template #items>
					<StatsPopoverItem
						v-if="stats && stats.main"
						title="Primary page content"
						:total-translation-percent="toStr(stats.main.totalTranslatedPercent)"
						:mt-translation-percent="toStr(stats.main.mtTranslatedPercent)"
						:sc-translation-percent="toStr(stats.main.scTranslatedPercent)"
					/>
					<StatsPopoverItem
						v-for="section in ((stats && stats.sections) || [])"
						v-bind:key="section.sectionId"
						:title="section.sectionId"
						:total-translation-percent="toStr(section.totalTranslatedPercent)"
						:mt-translation-percent="toStr(section.mtTranslatedPercent)"
						:sc-translation-percent="toStr(section.scTranslatedPercent)"
					/>
				</template>
			</StatsPopover>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import SourceLanguageSelector from '@/components/widgets/languages/source-selector.vue';
	import IconButton from '../browser-toolbar/icon-button.vue';
	import LangSelector from '@/components/widgets/lang-selector/lang-selector.vue';
	import langs from '@/resources/languages.json';
	import WtBadge from '@/components/widgets/wt-badge/wt-badge.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import { IPageTranslationProgress } from "@/shared";
	import StatsPopover from "@/components/widgets/browser-navigation/stats-popover/stats-popover.vue";
	import StatsPopoverItem from "@/components/widgets/browser-navigation/stats-popover/stats-popover-item.vue";

	@Component({
		components: {
			StatsPopoverItem,
			StatsPopover,
			LangSelector,
			SourceLanguageSelector,
			IconButton,
			WtBadge,
			WtButton
		},
	})
	export default class BrowserNavigation extends Vue {
		@Prop({ required: true })
		public selectedLang: string;

		@Prop({ required: true })
		public url: string;

		@Prop({ required: true })
		public stats: IPageTranslationProgress;

		@Prop({ required: true })
		public forwardDisabled: boolean;

		@Prop({ required: true })
		public backwardDisabled: boolean;

		@Prop({ required: true })
		public langs: string[];

		@Prop({ required: true })
		public sourceLang: string;

		@Prop({ required: false })
		public langListIsLoading?: boolean;

		@Prop({ required: false })
		public langIsAdding?: boolean;

		@Prop()
		public disableLanguageAddition?: boolean;

		@Prop({ required: false })
		public syncProjectAction: () => Promise<void>;

		@Prop({ default: false })
		public professionalTranslationSpin: boolean;

		@Prop({ default: false })
		public machineTranslationSpin: boolean;

		public toStr(v?: number) {
			if (v == null) {
				return ''
			}
			return v.toString();
		}

		public get currentLangName() {
			return langs.find((l) => l.cultureName === this.selectedLang)?.englishName;
		}

		public get siteLangs() {
			return this.langs.map((lang) => langs.find((l) => l.cultureName === lang));
		}

		public submitNavPath(e: KeyboardEvent) {
			if (e.key.toLowerCase() === 'enter') {
				const newUrl = (e.target as HTMLInputElement).value;
				this.$emit('navigate', newUrl);
			}
		}

		public onUrlChange(e: any) {
			const newUrl = (e.target as HTMLInputElement).value;
			this.$emit('urlChange', newUrl);
		}
	}
</script>

<style lang="less" src="./browser-navigation.less" scoped/>
