import { CustomizationBase } from '@/script/logic/customization/customization-base';
import { ISectionInfo } from '@/script/logic/extraction/section-info';
import { NodeAnalyzer } from '@/script/logic/extraction/node-analyzer';

export class GitbookCustomization extends CustomizationBase {

	private readonly _footerSelector = 'body > .border-t > .scroll-nojump > .mx-auto';
	private readonly _sidebarSelector = 'body > div > div > aside';
	private readonly _headerSelector = 'body > header';
	private readonly _searchResultsSelector = 'div[data-test="search-results"]';

	public isIgnored(node: Node): boolean {
		if (node instanceof Element) {
			return !!node.closest(this._searchResultsSelector);
		}
		return false;
	}

	public findSectionForElement(element: Element): ISectionInfo | null {
		const footer = element.closest(this._footerSelector);

		if (footer) {
			return {
				id: 'footer',
				customLang: NodeAnalyzer.tryGetExplicitlySetLang(footer),
			};
		}

		const sidebar = element.closest(this._sidebarSelector);

		if (sidebar) {
			return {
				id: 'sidebar',
				customLang: NodeAnalyzer.tryGetExplicitlySetLang(sidebar),
			};
		}

		const header = element.closest(this._headerSelector);

		if (header) {
			return {
				id: 'header',
				customLang: NodeAnalyzer.tryGetExplicitlySetLang(header),
			};
		}

		return null;
	}
}
