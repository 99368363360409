import Vue from 'vue';
import { registerDependencyInRootScope } from '@/components/common/di';

export class NotificationService {
	constructor() {
		Vue.observable(this);
	}

	public successMessage: string = null;
	public errorMessage: string = null;

	hide() {
		this.successMessage = null;
		this.errorMessage = null;
	}

	showSuccess(message: string) {
		this.successMessage = message;
		setTimeout(() => (this.successMessage = null), 3000);
	}

	showError(message: string) {
		this.errorMessage = message;
		setTimeout(() => (this.errorMessage = null), 3000);
	}
}

registerDependencyInRootScope(NotificationService);
