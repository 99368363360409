<template>
	<div data-testid="smart-words-usages-header-popover-toggle">
		<WtMenu
			position="right bottom"
			width="350px"
			@close="onClose"
		>
			<template #activator="{ isOpened, toggleMenu }">
				<ScButtonSmartwords
					:activated="isOpened"
					@click="toggleMenu"
				>
					{{ isFewWords ? $t('buySmartwords') : $n(currentSmartWordsUsageCount) }}
				</ScButtonSmartwords>
			</template>
			<template>
				<div
					class="l-smartwords-widget-new__wrap"
					data-testid="smart-words-usages-header-popover"
				>
					<div
						class="l-smartwords-widget-new__flex l-smartwords-widget-new__flex_space-between g-indent g-indent_bottom-06"
					>
						<div class="l-smartwords-widget-new__flex">
							<img
								class="l-smartwords-widget-new__logo g-indent g-indent_right-06"
								src="../../../assets/images/smartwords-logo.svg"
								alt="logo"
							/>
							<ScText
								color="mulberry-purple-new"
								size="14"
							>
								{{ $t('smartwordsBalance') }}
							</ScText>
						</div>
						<ScDropdown ref="dropdown">
							<template #activator>
								<ScDropdownButton
									icon="dots"
									view="flat"
									size="28"
								/>
							</template>
							<template #content>
								<ScDropdownGroup>
									<!--	TODO: hidden until implementation in new app.-->
									<ScDropdownOption
										v-if="false"
										@click="$router.push('billing-details')"
									>
										{{ $t('MenuPaymentSettings') }}
									</ScDropdownOption>
									<ScDropdownOption
										v-if="false"
										@click="$router.push('invoices')"
									>
										{{ $t('MenuPurchaseHistory') }}
									</ScDropdownOption>
									<ScDropdownOption @click="goToSubscriptions">
										{{ $t('MenuChangePlan') }}
									</ScDropdownOption>
									<ScDropdownOption @click="goToFaq">
										{{ $t('MenuHelp') }}
										<ScIcon
											name="external-link"
											size="20"
											color="mulberry-purple-45"
										/>
									</ScDropdownOption>
								</ScDropdownGroup>
							</template>
						</ScDropdown>
					</div>
					<div class="l-smartwords-widget-new__flex g-indent g-indent_bottom-08">
						<div class="g-indent g-indent_right-1">
							<ScText
								size="36"
								class="l-smartwords-widget-new__text-bold"
								weight="bold"
							>
								{{ $n(currentSmartWordsUsageCount) }}
							</ScText>
						</div>
						<div>
							<button
								v-if="isFewWords"
								class="l-smartwords-widget-new__button l-smartwords-widget-new__button_large"
								@click="goToTopUpBalance"
							>
								<ScText
									color="fuchsia-blue"
									size="20"
									semibold
								>
									{{ $t('topUpBalance') }}
								</ScText>
							</button>
							<ScTooltip
								v-else
								size="small"
							>
								<template #activator>
									<ScButtonCircle
										icon="plus"
										class="l-smartwords-widget-new__button"
										size="28"
										view="light"
										@click="goToTopUpBalance"
									/>
								</template>
								<template>{{ $t('topUpBalance') }}</template>
							</ScTooltip>
						</div>
					</div>
					<ScTooltip>
						<template #activator>
							<div class="l-smartwords-usage-container">
								<ScProgress :percent="smartwordsUsagePercent" />
							</div>
						</template>
						<template>
							<ScText
								color="white"
								size="14"
							>
								{{ $n(currentSmartWordsUsageCount) }} / {{ $n(maxSmartwords) }}
							</ScText>
						</template>
					</ScTooltip>
					<div class="l-smartwords-widget-new__flex l-smartwords-widget-new__flex_space-between">
						<div>
							<ScText size="12">
								{{ isFewWords ? $t('noCurrentPlan') : $t('currentPlan') }}
							</ScText>
							<ScText
								v-if="!isFewWords"
								size="12"
								weight="semibold"
							>
								{{ subscriptionName }}
							</ScText>
						</div>
						<div>
							<a
								href="#"
								class="l-smartwords-widget-new__link"
								@click.prevent="goToSubscriptions"
							>
								<ScText
									color="fuchsia-blue"
									size="12"
								>
									{{ $t('upgradeSubscription') }}
								</ScText>
							</a>
						</div>
					</div>
				</div>
			</template>
		</WtMenu>
	</div>
</template>

<script lang="ts">
	import * as en from './i18n/en.json';

	import {
		ScButton,
		ScButtonCircle,
		ScIcon,
		ScProgress,
		ScTooltip,
		ScText,
		ScButtonSmartwords,
		ScDropdownButton,
		ScDropdown,
		ScDropdownGroup,
		ScDropdownOption,
	} from '@smartcat/design-system-vue2';
	import { Component } from '@/components/common/vue';
	import Vue from 'vue';
	import { Prop } from 'vue-property-decorator';
	import WtMenu from '@/components/widgets/wt-menu/wt-menu.vue';

	@Component({
		i18n: { messages: { en } } as any,
		components: {
			ScButtonSmartwords,
			ScButtonCircle,
			ScProgress,
			ScTooltip,
			ScIcon,
			ScButton,
			WtMenu,
			ScText,
			ScDropdownButton,
			ScDropdown,
			ScDropdownGroup,
			ScDropdownOption,
		},
	})
	export default class SmartwordIndicator extends Vue {
		@Prop({ required: false })
		public subscriptionName: string;

		@Prop({ required: false })
		public currentSmartWordsUsageCount: number;

		@Prop({ required: false })
		public maxSmartwords: number;

		public get smartwordsUsagePercent() {
			return Math.min(Math.ceil((this.currentSmartWordsUsageCount / this.maxSmartwords) * 100), 100);
		}

		public get isFewWords() {
			return this.currentSmartWordsUsageCount < 100;
		}

		public async goToTopUpBalance() {
			this.$emit('click-top-up-balance');
		}

		public goToSubscriptions() {
			this.$emit('click-go-subscribtion');
		}

		public goToFaq() {
			this.$emit('click-go-faq');
		}

		public async onClose() {
			(this.$refs.dropdown as any)?.closeDropdown()
		}
	}
</script>

<style lang="less" src="./styles.less"></style>
