import { addMessageListener } from './message-listen';
import { genId } from './gen-id';

export function createClientProxy<T>(serviceName: string, serverWindow: Window): { proxy: T; dispose: () => void } {
	const serviceClient: MessagesClient = new MessagesClient(serviceName, serverWindow);
	serviceClient.init();
	const proxy = new Proxy(
		{},
		{
			get(target, prop) {
				// (A)
				if (prop.toString() === 'then') {
					return undefined;
				}
				return function () {
					// eslint-disable-next-line prefer-rest-params
					return serviceClient.proxy(prop.toString(), arguments);
				};
			},
		},
	) as any as T;
	return { proxy, dispose: () => serviceClient.stop() };
}

const logPostedMessages = false;

export class MessagesClient {
	__promises: { [key: string]: { resolve: (result: any) => void; reject: (error: any) => void } } = {};
	__handlers: { [key: string]: ((arg: any) => void)[] } = {};
	private onmessage: (event: any) => void;
	private serverWindow: Window;
	private dispose: () => void;

	constructor(
		public serviceName: string,
		serverWindow: Window,
	) {
		this.serverWindow = serverWindow;
	}

	public init() {
		this.onmessage = (event) => {
			if (event.eventName) {
				this.triggerEvent(event.eventName, event.arguments);
			} else if (event.promiseId && this.__promises[event.promiseId]) {
				if (event.error) {
					console.log('ServiceProxy got error:');
					console.log(event.error);
					this.__promises[event.promiseId].reject(event.error);
				} else {
					if (logPostedMessages) {
						console.log(this.serviceName + ' got result:');
						console.log(event.result);
					}
					this.__promises[event.promiseId].resolve(event.result);
				}
			}
		};
		this.dispose = addMessageListener(this.onmessage);
	}

	stop() {
		this.dispose();
	}

	protected triggerEvent(eventName: string, args: any) {
		const handlerList = this.__handlers[eventName];
		if (!handlerList) {
			return;
		}
		for (let i = 0; i < handlerList.length; i++) {
			try {
				handlerList[i].apply(window, args);
			} catch (e) {
				console.error(e);
			}
		}
	}

	protected on(eventName: string, handler: any): any {
		if (!this.__handlers[eventName]) {
			this.__handlers[eventName] = [];
		}

		this.__handlers[eventName].push(handler);
		if (this.__handlers[eventName].length == 1) {
			parent.postMessage(
				{
					target: this.serviceName,
					eventName,
					action: 'on',
				},
				'*',
			);
		}
	}

	protected off(eventName: string, handler: any): any {
		this.__handlers[eventName] = this.__handlers[eventName].filter((x) => x != handler);
		if (this.__handlers[eventName].length == 0) {
			this.serverWindow.postMessage(
				{
					target: this.serviceName,
					eventName,
					action: 'off',
				},
				'*',
			);
		}
	}

	public proxy(methodName: string, args: IArguments): any {
		const promiseId: string = genId(methodName);
		const result = new Promise((resolve, reject) => {
			this.__promises[promiseId] = {
				resolve,
				reject: (err) => {
					reject(err);
				},
			};
		});

		const postedArgs = [];
		for (let i = 0; i < args.length; i++) {
			if (typeof args[i] !== 'function') {
				postedArgs[i] = args[i];
			}
		}

		this.serverWindow.postMessage(
			{
				target: this.serviceName,
				methodName,
				promiseId,
				arguments: postedArgs,
			},
			'*',
		);
		return result;
	}
}
