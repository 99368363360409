export function onClickOutside(
	root: HTMLElement,
	target: Node | (() => Node),
	handler: (e: Event) => void,
	preCondition: ((e: Event) => boolean) | null = null,
	triggerOnWindowBlur = false,
) {
	const internalHandler = (e: Event) => {
		if (!e.target || (preCondition && !preCondition(e))) {
			return;
		}

		if (e.type === 'blur') {
			handler(e);
			return;
		}

		const targetNode = typeof target === 'function' ? target() : target;

		if (targetNode && e.target !== targetNode && !targetNode.contains(e.target as Node)) {
			handler(e);
		}
	};

	root.addEventListener('click', internalHandler);

	if (triggerOnWindowBlur) {
		window.addEventListener('blur', internalHandler);
	}

	return () => {
		root.removeEventListener('click', internalHandler);
		window.removeEventListener('blur', internalHandler);
	};
}
