<template>
	<section
		id="what"
		class="what"
	>
		<div class="container">
			<h2 class="what-title"> What is Special About Smartcat Website Translator </h2>
			<div class="what-list">
				<div class="what-list__item">
					<img
						src="../../assets/images/landing/speed-to-market.svg"
						alt="Speed to market"
						class="what-list__item--image"
					/>
					<h4 class="what-list__item--title"> Speed to Market </h4>
					<p class="what-list__item--text">
						Automatically translate your entire website to multiple languages and push it live in minutes
					</p>
				</div>
				<div class="what-list__item">
					<img
						src="../../assets/images/landing/quality-control.svg"
						alt="Speed to market"
						class="what-list__item--image"
					/>
					<h4 class="what-list__item--title"> Quality Control </h4>
					<p class="what-list__item--text"> Review and improve selected pages to optimize your budget </p>
				</div>
				<div class="what-list__item">
					<img
						src="../../assets/images/landing/scalability.svg"
						alt="Speed to market"
						class="what-list__item--image"
					/>
					<h4 class="what-list__item--title"> Scalability </h4>
					<p class="what-list__item--text">
						Hire and manage any number of linguists under one contract and invoice
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';

	@Component({
		components: {},
	})
	export default class LandingWhatSection extends Vue {}
</script>

<style lang="less" src="./landing-what-section.less" scoped></style>
