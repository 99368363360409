import { apiClient } from '@/shared';
import Vue from 'vue';

export class LangSynchronizer {
	constructor(
		private webSiteId: string,
		defaultValue: string[],
	) {
		Vue.observable(this);
		this._langs = defaultValue;
	}

	private _syncPromise: Promise<string[]> = null;
	private _langs: string[] = [];
	private _syncInProgress = false;

	public get isSyncInProgress() {
		return this._syncInProgress;
	}

	public get langs() {
		return this._langs;
	}

	notifyLangAdded(lang: string) {
		if (this._langs.indexOf(lang) < 0) {
			this._langs.push(lang);
		}
	}

	beginSync() {
		this.sync();
	}

	sync() {
		if (this._syncPromise) {
			return this._syncPromise;
		}
		this._syncPromise = this.syncPrivate();
		return this._syncPromise;
	}

	private async syncPrivate() {
		this._syncInProgress = true;
		try {
			this._langs = await this.syncLangsOrGetFromCache();
			return this._langs;
		} finally {
			this._syncInProgress = false;
		}
	}

	private async syncLangsOrGetFromCache() {
		const webSite = await apiClient.syncLanguages(this.webSiteId);
		return webSite.targetLanguages || [];
	}
}
