<template>
	<p class="wt-text">
		<slot />
	</p>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';

	@Component
	export default class WtText extends Vue {}
</script>

<style lang="less" scoped>
	.wt-text {
		text-align: left;
		line-height: 20px;
		color: #797389;
		font-weight: 400;
		font-size: 14px;
	}
</style>
