export class Event<T> {
	private listeners: ((e: T) => void)[] = [];

	trigger(e: T) {
		this.listeners.forEach((l) => {
			try {
				l(e);
			} catch (e) {
				console.error(e);
			}
		});
	}

	addHandler(h: (e: T) => void) {
		if (this.listeners.indexOf(h) < 0) {
			this.listeners.push(h);
		}
	}
}
