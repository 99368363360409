<template>
	<div>
		<slot/>
		<slot
			v-if="loading"
			name="loader"
		/>
		<slot
			v-if="!loaded"
			name="loaded"
		/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';

	@Component
	export default class InfiniteScroll extends Vue {
		@Prop({ type: String, default: null }) selector!: string | null;
		@Prop({ type: Boolean, default: false }) loading!: boolean;
		@Prop({ type: Boolean, default: false }) loaded!: boolean;

		private scrollGap = 200;

		getScrollContainer() {
			return this.selector ? document.querySelector(this.selector) : window;
		}

		upScrollHandler(event: Event) {
			if (this.loading || this.loaded) {
				return;
			}
			const target = event.target as HTMLDivElement;
			if (this.selector) {
				if (target.scrollTop <= this.scrollGap) {
					this.$emit('load-more');
				}
			} else {
				if (window.scrollY <= this.scrollGap) {
					this.$emit('load-more');
				}
			}
		}

		downScrollHandler(event: Event) {
			if (this.loading || this.loaded) {
				return;
			}
			const target = event.target as HTMLDivElement;
			if (this.selector) {
				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.scrollGap) {
					this.$emit('load-more');
				}
			} else {
				if (window.scrollY + document.body.clientHeight >= document.body.scrollHeight - this.scrollGap) {
					this.$emit('load-more');
				}
			}
		}

		mounted() {
			this.getScrollContainer()?.addEventListener('scroll', this.downScrollHandler);
			if (!this.loading && !this.loaded) {
				this.$emit('load-more');
			}
		}

		beforeDestroy() {
			this.getScrollContainer()?.removeEventListener('scroll', this.downScrollHandler);
		}
	}
</script>