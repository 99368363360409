import { CustomizationBase } from '@/script/logic/customization/customization-base';
import { ISectionInfo } from '@/script/logic/extraction/section-info';
import { NodeAnalyzer } from '@/script/logic/extraction/node-analyzer';

export const isShopify = function () {
	return (window as any).Shopify || !!([...document.head.childNodes].find(x => x.textContent.indexOf('shopify') > 0))
}

const knownSections = new Set(['header', 'footer', 'cookie']);
export const currencyRegex = /^[\d.,\s]*[\p{Sc}][\d.,\s]*$/ug;
export const taxesRegex = /^Including.{1,10}taxes$/ug;

export class ShopifyCustomization extends CustomizationBase {
	private readonly _sectionSelector = '.shopify-section';
	private readonly _productJsonSelector = '[data-product-json]';
	private readonly _productHandleSelector = '[data-apz-upsell-product-handle]';

	getAutoExportAudiencePercent(): number | undefined {
		return 0.01;
	}

	public isIgnored(node: Node): boolean {
		if (node instanceof Element) {
			const el = node as HTMLElement;
			if (el.childNodes.length == 1 && el.childNodes[0].nodeType == 3) {
				return this.isInvalidText(node.textContent);
			}
		} else if (node.nodeType === 3) {
			if (node.parentElement.closest(this._productJsonSelector)) {
				return true;
			}
			return this.isInvalidText(node.textContent);
		}
		return false;

	}

	isInvalidText(t: string): boolean {
		return !!(t.match(currencyRegex) || t.match(taxesRegex));
	}

	public findSectionForElement(element: Element): ISectionInfo | null {
		const productLink = element.closest('a[href]');
		if (productLink) {
			const href = productLink.getAttribute('href');
			if (href && href.indexOf('/products/') > 0) {
				const productName = href.split('/products/')[1].split('?')[0];
				if (productName.match(/[a-z]{3,}/)) {
					return {
						id: 'dynamic/description/' + productName,
					}
				}
			}
		}

		const productHandle = element.closest(this._productHandleSelector);
		if (productHandle) {
			const val = productHandle.getAttribute('data-apz-upsell-product-handle');
			if (val) {
				return {
					id: 'dynamic/description/' + val,
				}
			}
		}
		const section = element.closest(this._sectionSelector);
		if (section) {
			// Sample sections:
			// shopify-section-sections--15254767730758__header
			// shopify-section-header
			// shopify-section__header

			const sectionNameMatch = section.id?.match(/shopify-section.*?([a-z]{3,})$/);
			if (sectionNameMatch) {
				const sectionName = sectionNameMatch?.[1];
				if (knownSections.has(sectionName?.toLowerCase())) {
					return {
						id: sectionName,
						customLang: NodeAnalyzer.tryGetExplicitlySetLang(section),
					};
				}
			}
		}

	}

}
