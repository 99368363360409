import Vue from 'vue';

export class HistoryTracker {
	constructor() {
		Vue.observable(this);
	}

	historyStack: string[] = [];
	positionInStack = -1;

	public movePrevious() {
		this.positionInStack--;
		if (this.positionInStack < 0) {
			this.positionInStack = -1;
		}
		return this.historyStack[this.positionInStack];
	}

	public moveNext() {
		this.positionInStack++;
		this.positionInStack = Math.min(this.historyStack.length - 1, this.positionInStack);
		return this.historyStack[this.positionInStack];
	}

	public add(url: string) {
		if (this.norm(this.currentUrl) === this.norm(url)) {
			return;
		}
		this.historyStack.splice(this.positionInStack + 1);
		this.historyStack.push(url);
		this.positionInStack = this.historyStack.length - 1;
	}

	public get currentUrl() {
		return this.historyStack[this.positionInStack];
	}

	public get canGoBackward() {
		if (this.positionInStack < 0) {
			return false;
		}
		return this.positionInStack > 0;
	}

	public get canGoForward() {
		if (this.positionInStack < 0) {
			return false;
		}
		if (this.positionInStack >= this.historyStack.length - 1) {
			return false;
		}
		return true;
	}

	private norm(url: string) {
		if (!url) {
			return url;
		}
		return url.toLowerCase().replace(/\/$/, '');
	}
}
