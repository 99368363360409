export enum Roles {
	Required,
	URL,
	ArrayNotEmpty,
}

const validateField = (value: any, role: Roles) => {
	switch (role) {
		case Roles.Required:
			return value || /^\s*$/.test(value);
		case Roles.URL:
			return /.+/.test(value);
		case Roles.ArrayNotEmpty:
			return value.length > 0;
		default:
			return true;
	}
};

export interface ValidationField {
	key: string;
	value: any;
	role: Roles;
}

export function validator(fileds: ValidationField[]) {
	const errorFields = [];
	for (const filed of fileds) {
		if (!validateField(filed.value, filed.role)) {
			errorFields.push(filed.key);
		}
	}
	return errorFields;
}
