import Vue, { WatchOptions } from 'vue';

const watchBus = new Vue();

export function state<T>(obj: T) {
	return Vue.observable(obj);
}

export function watch<T>(fn: () => T, callback: (newValue: T, oldValue: T) => void, options?: WatchOptions) {
	return watchBus.$watch(fn, callback, options);
}
