<template>
	<div
		class="md-input__wrapper"
		:class="classes"
	>
		<div class="md-input__base">
			<div
				class="md-input__suffix"
				:hidden="!showSuffix"
			>
				<div
					v-if="type === 'password'"
					:class="'md-input__icon md-input__icon_clickable g-icon g-icon_eye-' + (isPassword ? 'on' : 'off')"
					@click="toggleType"
				/>
				<div
					v-else-if="clearable"
					class="md-input__icon md-input__icon_clickable md-input__icon_size_xs g-icon g-icon_cross"
					@click="clear"
				/>
				<div
					v-else-if="validationIcon"
					class="md-input__icon g-icon g-icon_check g-icon_green"
				/>
				<slot
					v-else
					name="suffix"
				/>
			</div>
			<textarea
				v-if="isTextarea"
				v-bind="attributes"
				ref="input"
				v-model="model"
				:style="upperCase ? 'text-transform: uppercase' : ''"
				data-testid="md-input"
				class="md-input__textfield md-input__textfield_area"
				:class="textareaModifClass"
				@change="onChange"
				@focus="onFocus"
				@blur="onBlur"
				@keydown="$emit('keydown', $event)"
			/>
			<input
				v-else-if="mask != null"
				v-bind="attributes"
				ref="input"
				v-model="model"
				v-prevent-autocomplete="preventAutocomplete"
				v-mask="mask"
				:type="type"
				:style="upperCase ? 'text-transform: uppercase' : ''"
				data-testid="md-input"
				class="md-input__textfield"
				:class="textareaModifClass"
				@change="onChange"
				@focus="onFocus"
				@blur="onBlur"
				@keydown="$emit('keydown', $event)"
			/>
			<input
				v-else
				v-bind="attributes"
				ref="input"
				v-model="model"
				v-prevent-autocomplete="preventAutocomplete"
				:type="type"
				:style="upperCase ? 'text-transform: uppercase' : ''"
				data-testid="md-input"
				class="md-input__textfield"
				:class="textareaModifClass"
				@change="onChange"
				@paste="onPaste"
				@focus="onFocus"
				@blur="onBlur"
				@keydown="$emit('keydown', $event)"
			/>
			<label
				class="md-input__label"
				:class="{ 'md-input__label_suffix': showSuffix, 'md-input__label_required': isRequired }"
				v-text="label"
			/>
			<div
				v-if="!boxMode"
				class="md-input__underline"
			/>
		</div>
		<div
			v-show="showHint"
			class="md-input__hint-wrapper"
		>
			<div
				v-show="hasError"
				class="md-input__hint-validation"
				data-testid="md-input-errors"
			>
				<!--        <slot name="errors" :validate="validate"/>-->
			</div>
			<div class="md-input__hint-custom">
				<slot name="hint" />
			</div>
			<span v-if="charCounter">{{ valueLength }}/{{ maxlength }}</span>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop } from 'vue-property-decorator';
	import MdValidatable from '../../common/validatable';

	interface NumberInputParams {
		decimalCount: number;
	}

	@Component
	export default class MdInput extends MdValidatable {
		@Prop([Number, String])
		public value: string;

		@Prop(String)
		public textareaModifClass: string;

		@Prop(String)
		public type: string;

		@Prop(String)
		public label: string;

		@Prop({ default: false })
		public upperCase: boolean;

		@Prop({ type: String, default: 'float' })
		public labelMode: string;

		@Prop(Boolean)
		public disabled: boolean;

		@Prop({ type: Boolean })
		public boxMode: boolean;

		@Prop({ type: Boolean, default: false })
		public fluent: boolean;

		@Prop({ type: Boolean, default: false })
		public fluentSimple: boolean;

		@Prop({ type: Boolean, default: false })
		public fluentOutline: boolean;

		@Prop({ type: Boolean, default: false })
		public isRequired: boolean;

		@Prop([Number, String])
		public maxlength: number | string;

		@Prop(String)
		public mask: string;

		@Prop({ type: Boolean, default: false })
		public charCounter: boolean;

		@Prop({ type: Boolean, default: false })
		public clearable: boolean;

		@Prop({ type: Boolean, default: false })
		public validationIcon: boolean;

		@Prop({ type: [Boolean, Object], default: false })
		public numberInput: boolean | NumberInputParams;

		@Prop({ type: Boolean, default: null })
		public autofocus: boolean;

		@Prop({ type: Boolean, default: false })
		public error: boolean;

		@Prop({ default: false })
		public preventAutocomplete: boolean;

		@Prop({ type: Boolean, default: null })
		public autoselect: boolean;

		public model: string = null;
		private nowType: string = null;

		private $input: HTMLInputElement;
		private $focused = false;
		private $changed = false;
		private $pasted = false;

		public get attributes() {
			return {
				...this.$attrs,
				disabled: this.disabled,
				maxlength: this.maxlength,
			};
		}

		public get classes() {
			return {
				['md-input__wrapper_label_' + this.labelMode]: true,
				'md-input__disabled': this.disabled,
				'md-input__error': this.hasError,
				'md-input__filled': this.model?.toString()?.length > 0,
				'md-input__wrapper_box': this.boxMode,
				'md-input_fluent': this.fluent,
				'md-input_fluent_simple': this.fluentSimple,
				'md-input_fluent_outline': this.fluentOutline,
			};
		}

		public get hasError() {
			return this.error;
		}

		public get isPassword() {
			return this.nowType === 'password';
		}

		public get isTextarea() {
			return this.type === 'textarea';
		}

		public get showSuffix() {
			return (
				this.type === 'password' ||
				(this.clearable && this.value) ||
				(this.validationIcon && this.dirty && !this.hasError) ||
				this.$slots.suffix != null
			);
		}

		public get showHint() {
			return this.$slots.hint != null || this.validate != null || this.charCounter;
		}

		public get valueLength() {
			return (this.model != null && this.model.length) || 0;
		}

		private get decimalCount() {
			return typeof this.numberInput === 'boolean' ? 2 : this.numberInput.decimalCount;
		}

		public created() {
			if (this.mask && this.numberInput) {
				throw new Error('You should set either mask or number input mode.');
			}

			this.model = this.value;
			this.nowType = this.type;

			this.$watch(
				() => this.value,
				(value: string) => {
					this.model = this.sanitizeInput(value);
					if (this.$focused) {
						this.$changed = true;
					}
				},
			);

			this.$watch(
				() => this.model,
				(value: string) => {
					this.$emit('input', !this.numberInput || value !== '' ? value : null);
				},
			);
		}

		public mounted() {
			this.$input = this.$refs.input as HTMLInputElement;
			if (this.isTextarea) {
				const css = window.getComputedStyle(this.$input);
				const maxHeight =
					parseInt(css.maxHeight, 10) + parseInt(css.paddingTop, 10) + parseInt(css.paddingBottom, 10);
				const minHeight = parseInt(css.minHeight, 10) || parseInt(css.height, 10);

				this.updateHeight(minHeight, maxHeight);
				this.$watch(
					() => this.value,
					() => this.updateHeight(minHeight, maxHeight),
				);
			}

			if (this.autofocus) {
				this.focus();
			}
			if (this.autoselect) {
				this.select();
			}
		}

		public clear() {
			this.$emit('input', '');
			this.$input.focus();
			this.dirty = true;
		}

		public focus() {
			this.$input.focus();
		}

		public select() {
			this.$input.select();
		}

		public toggleType() {
			this.nowType = this.isPassword ? 'text' : 'password';
			this.$input.setAttribute('type', this.nowType);
		}

		public onFocus() {
			this.$focused = true;
		}

		public onBlur() {
			this.$focused = false;
			if (this.$changed) {
				this.$changed = false;
				this.dirty = true;
			}
			if (this.$pasted) {
				this.$pasted = false;
			}
			this.$emit('blur');
		}

		public onChange(event: Event) {
			const value = (event.target as HTMLInputElement).value;
			this.$emit('change', value);
		}

		public onPaste() {
			this.$pasted = true;
		}

		private sanitizeInput(value: string) {
			if (this.mask || !this.numberInput) {
				return value;
			}

			const regexp = new RegExp(
				`\\d$|0\\.\\d{0,${this.decimalCount}}|[1-9]\\d*\\.\\d{0,${this.decimalCount}}|[1-9]\\d+`,
			);
			const match = value?.toString().match(regexp);
			return match ? match[0] : '';
		}

		private updateHeight(minHeight: number, maxHeight: number) {
			this.$input.style.height = '1px';
			if (this.$input.scrollHeight > maxHeight) {
				this.$input.style.height = maxHeight + 'px';
				this.$input.style.overflowY = 'scroll';
			} else {
				this.$input.style.height =
					this.$input.scrollHeight > minHeight * 1.5 ? this.$input.scrollHeight + 'px' : '';
				this.$input.style.overflowY = 'hidden';
			}
		}
	}
</script>

<style lang="less" src="./md-input-styles.less" />
