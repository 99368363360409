<template>
	<div class="sc-loading-error">
		<ScText
			size="x-large"
			semibold
		>
			We weren't able to load this page. Try again later or
			<a href="mailto:support@smartcat.ai">contact our support</a>.
		</ScText>
	</div>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import { ScText } from '@smartcat/design-system-vue2';

	@Component({ components: { ScText } })
	export default class LoadingError extends Vue {}
</script>

<style lang="less" scoped>
	.sc-loading-error {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		gap: 20px;
		opacity: 0.8;
		background: white;
	}

	.sc-ghost {
		font-size: 90px;
	}
</style>
