<template>
	<p class="wt-title">
		<slot />
	</p>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';

	@Component
	export default class WtTitle extends Vue {}
</script>

<style lang="less" scoped>
	.wt-title {
		text-align: left;
		color: #231d33;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
	}
</style>
