
export class StringUtils {
	public static containsAlphanumericCharacter(v: string) {
		return !/^[^\p{L}]+$/u.test(v);
	}

	public static isBlank(text: string | null): boolean {
		if (!text || text == ' ') {
			return true;
		}
		return text.match(/^\s*$/) !== null;
	}
}
