<template>
	<ScTooltip>
		<template #activator>
			<ScButton
				:view="view"
				:icon="icon"
				size="36"
				@click="triggerClick"
				:response="showLoading"
				:disabled="disabled || showLoading"
			>
				<template v-if="label">
					{{ label }}
				</template>
			</ScButton>
		</template>
		<template v-if="tooltip">
			{{ tooltip }}
		</template>
	</ScTooltip>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ScButton, ScTooltip } from "@smartcat/design-system-vue2";
	import WtIcon from '@/components/widgets/wt-icon/wt-icon.vue';

	@Component({
		components: { WtIcon, ScButton, ScTooltip },
	})
	export default class WtButton extends Vue {
		@Prop({ type: String, default: '' })
		public readonly label: string;

		@Prop({ type: String, default: null })
		public readonly icon: any;

		@Prop({ type: String, default: 'cta-black' })
		public readonly view: any;

		@Prop({ type: Boolean, default: false })
		public readonly disabled: boolean;

		@Prop({ type: Boolean, default: false })
		public readonly spin: boolean;

		@Prop({ type: Boolean, default: false })
		public readonly badge: boolean;

		@Prop({ required: false })
		public asyncAction: () => Promise<void>;

		@Prop({ type: String, default: null })
		public readonly tooltip: string;

		public asyncActionInProgress = false;

		public get hasIcon() {
			return this.icon !== null;
		}

		public get classes() {
			return {
				[`wt-button--${this.view}`]: true,
				'disabled': this.disabled || this.showLoading,
				'wt-button__badge': this.badge,
			};
		}

		public get showLoading() {
			return this.spin || this.asyncActionInProgress;
		}

		public async triggerClick() {
			if (this.disabled) {
				return;
			}
			this.$emit('click');
			if (this.asyncActionInProgress) {
				return;
			}
			if (this.asyncAction) {
				this.asyncActionInProgress = true;
				try {
					await this.asyncAction();
				} finally {
					this.asyncActionInProgress = false;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '~@smartcat/design-system-vue2/colors';

	.wt-button {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background: #231d33;
		color: #ffffff;
		border-radius: 6px;
		height: 34px;
		width: auto;
		padding: 0 16px;
		border: 1px solid #231d33;

		&:hover {
			.wt-button__tooltip {
				opacity: 1;
				visibility: visible;
			}
		}

		&.wt-button--icon {
			background: none;
			color: #6b6679;
			padding: 0;
			border: none;

			/deep/ svg {
				stroke: #6b6679;
			}
		}

		&.wt-button__badge::after {
			content: '';
			box-sizing: border-box;
			position: absolute;
			display: block;
			top: -5px;
			right: -5px;
			border-radius: 50%;
			background: @pink-insanity;
			width: 10px;
			height: 10px;
		}

		&.disabled {
			pointer-events: none;
		}

		&.disabled.wt-button--basic {
			background: #aba7b3;
			color: #f2f1f4;
			border-color: #aba7b3;
		}

		&.wt-button--border {
			background: #ffffff;
			border-color: #e6e5e8;
			color: #413a53;
		}

		&.wt-button--arrow {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 21px;
			width: 21px;
			cursor: pointer;
			flex-shrink: 0;
			border-radius: 4px;

			&:hover {
				background: #eee;
			}

			&:disabled {
				opacity: 0.4;
			}
		}

		&.wt-button--gradient {
			background: linear-gradient(90deg, #8160d1 0%, #c010ef 99.78%);
			border: unset;
			color: #ffffff;
		}

		&__icon {
			margin-right: 8px;
			margin-top: 5px;
		}

		&__label {
			font-weight: 600;
			font-size: 14px;
		}

		&__spin {
			animation-name: spin;
			animation-duration: 0.5s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			margin-right: 8px;
		}

		&__tooltip {
			cursor: auto;
			transition: 0.1s ease;
			opacity: 0;
			visibility: hidden;
			color: #ffffff;
			background: #413a53;
			border-radius: 6px;
			padding: 8px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			position: absolute;
			bottom: calc(-100% - 20px);
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;
			z-index: 99999;

			&::after {
				content: '';
				height: 15px;
				position: absolute;
				top: -15px;
				left: 0;
				width: 100%;
			}

			&::before {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				position: absolute;
				margin: 0 5px;
				border-color: #413a53;
				z-index: 1;
				border-width: 0 5px 5px 5px;
				border-left-color: transparent !important;
				border-right-color: transparent !important;
				border-top-color: transparent !important;
				top: -5px;
				left: calc(50% - 10px);
			}
		}

		@keyframes spin {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
</style>
