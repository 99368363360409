import { PageTranslationDto, PageTranslationInfo } from "@/shared";

export const getPageKey = (page: PageTranslationDto) => page.pagePath + '|' + page.lang;

export const parsePageKey = (key: string) => {
	const [pagePath, lang] = key.split('|');
	return { pagePath, lang };
}

export class PageListItem {

	constructor(page: PageTranslationInfo) {
		this.page = page;
		this.key = getPageKey(page);
	}

	public key: string;
	public page: PageTranslationInfo;
	public isEditing = false;
	public isPublishing = false;
	public isUpdating = false;

	update(newPage: PageTranslationInfo) {
		this.page = newPage;
	}
}