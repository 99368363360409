import { render, staticRenderFns } from "./demo-browser-page.vue?vue&type=template&id=0095b716&scoped=true&"
import script from "./demo-browser-page.vue?vue&type=script&lang=ts&"
export * from "./demo-browser-page.vue?vue&type=script&lang=ts&"
import style0 from "./demo-browser-page.less?vue&type=style&index=0&id=0095b716&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0095b716",
  null
  
)

export default component.exports