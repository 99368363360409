import { trimEndChar, trimStartChar } from './string';
import { decodeBase32ToString, encodeBase32 } from "@/shared/utils/base32";

export class ProxiedUrl {
	constructor(
		public protocol: string,
		public pathAndQuery: string,
		public slug: string,
		public domain: string,
		public lang: string,
		public region: string,
		public proxyHost: string,
		public port: string = null,
	)
	{
	}
}

export function proxyUrlToString(url: ProxiedUrl) {
	const domain = url.domain ? '_' + encodeBase32(url.domain) : '';
	const host = `${url.slug}${domain}-${url.lang}-${url.region}.${url.proxyHost}`;
	const path = trimStartChar(url.pathAndQuery, '/');
	return `${url.protocol}://${host}${url.port ? ':' + url.port : ''}/${path}`;
}

export function proxyUrlToOriginalUrl(url: ProxiedUrl, originalHost: string) {
	const path = trimStartChar(url.pathAndQuery, '/');
	const host = url.domain ? url.domain : originalHost;
	return `https://${host}/${path}`;
}

export function originalUrlToProxyUrl(url: string, locale: string, format: string) {
	const parsed = new URL(url);
	const baseProxyUrl = format
		.replace('{domain}', '_' + encodeBase32(parsed.hostname))
		.replace('{locale}', 'en');

	const proxyUrl = parseProxyUrl(baseProxyUrl);
	proxyUrl.pathAndQuery = parsed.pathname + parsed.search;
	proxyUrl.lang = locale;
	return proxyUrlToString(proxyUrl);
}

export function parseProxyUrl(url: string): ProxiedUrl | null {
	const uri = new URL(url);
	const regex = /^(?<slug>.*?)(_(?<domain>.*?))?-(?<lang>[^.]*)-(?<region>[a-z]+?)\.(?<proxyHost>.*)$/;
	const match = uri.hostname.match(regex);
	if (!match || match.length === 0) {
		return null;
	}
	const domain = match.groups.domain ?
		decodeBase32ToString(match.groups.domain) : null;
	return new ProxiedUrl(
		trimEndChar(uri.protocol, ':'),
		uri.pathname + uri.search,
		match.groups.slug,
		domain,
		match.groups.lang,
		match.groups.region,
		match.groups.proxyHost.split(':')[0],
		uri.port || null,
	);
}
