import { PageTranslationSelection, PageTranslationSelectionMode, SelectedPageTranslationLanguage } from "@/shared";
import { parsePageKey } from "@/components/web-site-pages/page-list/page-list-item";
import Vue, { set } from 'vue';

export class PageSelectionTracker {
	public v = 1;
	public selectedPageKeys: { [k: string]: boolean } = {};
	private allSelected = false;

	constructor() {
		Vue.observable(this)
		this.selectedPageKeys = Vue.observable(this.selectedPageKeys);
	}

	getCurrentSelection(currentFilter: string): PageTranslationSelection {
		if (this.allSelected) {
			const excludedPageKeys: string[] = this._getKeysWithSelectedIs(false);
			const res: PageTranslationSelection = {
				selectionMode: PageTranslationSelectionMode.QueryBased,
				explicitSelection: { selectedItems: [] },
				queryBasedSelection: {
					query: currentFilter,
					excludeItems: excludedPageKeys.map(x => this._keyToSelection(x))
				}
			};
			return res;
		} else {
			const selectedItems = this._getKeysWithSelectedIs(true).map(x => this._keyToSelection(x));
			const res: PageTranslationSelection = {
				selectionMode: PageTranslationSelectionMode.Explicit,
				explicitSelection: { selectedItems }
			};
			return res;
		}
	}

	isAnySelected(): boolean {
		if (this.allSelected) {
			return true;
		}
		for (const k of Object.keys(this.selectedPageKeys)) {
			if (this.selectedPageKeys[k]) {
				return true;
			}
		}
		return false;
	}

	getAllSelected(): boolean {
		return this.allSelected;
	}

	getSelectedCountText(totalCount: number, isAllLoaded: boolean) {
		if (this.allSelected) {
			const excludedCount = this._getKeysWithSelectedIs(false).length;
			return (totalCount - excludedCount) + (isAllLoaded ? '' : '+');
		} else {
			const selectedCount = this._getKeysWithSelectedIs(true).length;
			return selectedCount.toString();
		}
	}

	setAllSelected(v: boolean) {
		this.allSelected = v;
		this.selectedPageKeys = {};
	}

	getIsSelected(pageKey: string): boolean {
		const v = this.selectedPageKeys[pageKey];
		if (v !== undefined) {
			return v;
		}
		if (this.allSelected) {
			return true;
		}
	}

	setIsSelected(pageKey: string, isSelected: boolean) {
		set(this.selectedPageKeys, pageKey, isSelected);
	}

	_keyToSelection(pageKey: string): SelectedPageTranslationLanguage {
		const parsed = parsePageKey(pageKey);
		return {
			path: parsed.pagePath,
			lang: parsed.lang
		};
	}

	_getKeysWithSelectedIs(v: boolean): string[] {
		console.log(v);
		const selectedItems: string[] = [];
		Object.keys(this.selectedPageKeys).forEach(k => {
			if (this.selectedPageKeys[k] == v) {
				selectedItems.push(k);
			}
		});
		return selectedItems;
	}
}