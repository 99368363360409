// Base32 alphabet according to RFC 4648
const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567'.toLowerCase();
const PADDING = '0';

export function encodeBase32(input: string | Uint8Array): string {
	// Convert string to Uint8Array if needed
	const data = typeof input === 'string'
		? new TextEncoder().encode(input)
		: input;

	let bits = 0;
	let value = 0;
	let output = '';

	for (let i = 0; i < data.length; i++) {
		value = (value << 8) | data[i];
		bits += 8;

		while (bits >= 5) {
			const index = (value >>> (bits - 5)) & 31;
			output += ALPHABET[index];
			bits -= 5;
		}
	}

	if (bits > 0) {
		const index = (value << (5 - bits)) & 31;
		output += ALPHABET[index];
	}

	// Add padding
	while ((output.length % 8) !== 0) {
		output += PADDING;
	}

	return output;
}

export function decodeBase32(input: string): Uint8Array {
	// Remove padding and convert to uppercase
	const str = input.replace(/0+$/, '').toLowerCase();

	const output: number[] = [];
	let bits = 0;
	let value = 0;

	for (let i = 0; i < str.length; i++) {
		const index = ALPHABET.indexOf(str[i]);
		if (index === -1) {
			throw new Error(`Invalid character at position ${i}: ${str[i]}`);
		}

		value = (value << 5) | index;
		bits += 5;

		if (bits >= 8) {
			output.push((value >>> (bits - 8)) & 255);
			bits -= 8;
		}
	}

	return new Uint8Array(output);
}

// Helper function to decode to string
export function decodeBase32ToString(input: string): string {
	return new TextDecoder().decode(decodeBase32(input));
}

