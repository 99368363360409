import { InjectedCodeLang, LanguageSelectorLocation } from '@/shared';
import './lang-selector.css';
import selectorStyles from '!!raw-loader!./lang-selector.css';
import { ResilientStorage } from '@/shared/utils/resilient-storage';
import { webflowPostInitHook, webflowPostRenderHook } from '@/script/lang-selector/integrations/webflow';
import { LangSelectorConfig } from '@/script/lang-selector/lang-selector-config';
import { onClickOutside } from '@/utils/click-outside';
import { ILangSelector } from "@/script/lang-selector/i-lang-selector";

export const icon = `
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="scLangSelectorIcon" d="M16.4001 9.00156C16.4001 13.0885 13.087 16.4016 9.0001 16.4016M16.4001 9.00156C16.4001 4.91466 13.087 1.60156 9.0001 1.60156M16.4001 9.00156H1.6001M9.0001 16.4016C4.91319 16.4016 1.6001 13.0885 1.6001 9.00156M9.0001 16.4016C10.851 14.3752 11.9029 11.7455 11.9601 9.00156C11.9029 6.25767 10.851 3.62794 9.0001 1.60156M9.0001 16.4016C7.14915 14.3752 6.09726 11.7455 6.0401 9.00156C6.09726 6.25767 7.14915 3.62794 9.0001 1.60156M1.6001 9.00156C1.6001 4.91466 4.91319 1.60156 9.0001 1.60156"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const loader = `
<div class="sc-loader">
	<span></span><span></span>
	<span></span><span></span>
</div>
`;

export function genId(): string {
	const w = window as any;
	w.__scId = w.__scId || 0;
	w.__scId++;
	return 'sc' + w.__scId;
}

interface HTMLElementInfo {
	tag: string;
	classes: { [key: string]: boolean } | string[];
	innerHTML?: string;
	innerDom?: HTMLElement[];
	parent: Node;
	style?: string;
}

export class LangSelector implements ILangSelector {
	private button: HTMLDivElement;
	private currentLocale: InjectedCodeLang;
	private langs: InjectedCodeLang[];
	private panelIsHidden = true;
	private langsPromise: Promise<InjectedCodeLang[]>;
	private storage = new ResilientStorage();
	private id: string;
	private _renderedElements = new Map<string, HTMLElement>();
	private _isLoading = false;
	private _container: HTMLElement;

	private get root() {
		return this.button?.shadowRoot;
	}

	constructor(private _cfg: LangSelectorConfig) {
		this.id = genId();
		(window as any)[this.id] = this;
	}

	public async init() {
		const langs = await this.loadLangs();
		this.currentLocale = this._cfg.getCurrentLang(langs);

		this.button = document.createElement('div');
		this.button.attachShadow({ mode: 'open' });
		this.button.setAttribute('sc-ignore', 'true');
		this.button.className = 'scLangSelector';
		this._container = this.findContainerElement();
		this._container.append(this.button);

		this.trackContainer();

		this.button.addEventListener('click', () => {
			if (this.panelIsHidden) {
				this.showPanel();
			} else {
				this.hidePanel();
			}
		});

		onClickOutside(document.body, this.button, () => {
			this.hidePanel();
		});

		this.render();
		this.runPostInitHooks();

		if (this.isFirstTime()) {
			this.button.classList.add('fadeIn');
			setTimeout(() => {
				this.button.classList.add('shown');
				this.setFirstTimeFalse();
			}, 1500);
		} else {
			this.button.classList.add('shown');
		}
	}

	public async reloadLangs() {
		this.langsPromise = null;
		await this.loadLangs();
		this.render();
	}

	public updateConfig(config: Partial<LangSelectorConfig>) {
		this._cfg = {
			...this._cfg,
			...config,
		};
		this.root.innerHTML = '';
		this._renderedElements.clear();
		this.render();
	}

	public render() {
		if (this.langs == null) {
			return;
		}

		if (!this.langs.find((x) => x.locale === this.currentLocale.locale)) {
			return;
		}

		const currentLang = this.currentLocale;

		this.renderElement('styles', {
			tag: 'style',
			parent: this.root,
			innerHTML: selectorStyles,
			classes: [],
		});

		this.renderElement('global-styles', {
			tag: 'style',
			parent: document.head,
			innerHTML: selectorStyles,
			classes: [],
		});

		const watermarkInfo = {
			tag: 'span',
			parent: this.root,
			innerHTML: `Powered by <a href="https://smartcat.com/" target="_blank">Smartcat</a>`,
			classes: ['scLangSelectorWatermark'],
		};

		const selectedLangInfo = {
			tag: 'div',
			parent: this.root,
			innerHTML: `${this._isLoading ? loader : icon} <span>${currentLang.name}</span`,
			classes: ['scLangSelectorLabel', `scLangSelectorLabel-${!this.panelIsHidden && 'open'}`],
		};

		if (this._cfg.location == LanguageSelectorLocation.BottomRight) {
			this.renderElement('selectedLang', selectedLangInfo);

			if (this._cfg.showWatermark) {
				this.renderElement('watermark', watermarkInfo);
			}
		} else {
			if (this._cfg.showWatermark) {
				this.renderElement('watermark', watermarkInfo);
			}

			this.renderElement('selectedLang', selectedLangInfo);
		}

		this.renderLangPanel();

		const { offsets, location } = this._cfg;

		this.button.classList.toggle('scLangSelector-None', location === LanguageSelectorLocation.None);

		if (location === LanguageSelectorLocation.BottomRight) {
			this.button.style.right = `${offsets.right}px`;
			this.button.style.bottom = `${offsets.bottom}px`;
			this.button.style.top = 'unset';
		} else if (location === LanguageSelectorLocation.TopRight) {
			this.button.style.right = `${offsets.right}px`;
			this.button.style.top = `${offsets.top}px`;
			this.button.style.bottom = 'unset';
		}

		this.runPostRenderHooks();
	}

	public setIsLoading(value: boolean) {
		if (value !== this._isLoading) {
			this._isLoading = value;
			this.render();
		}
	}

	private findContainerElement(): HTMLElement {
		return document.getElementById('scLangSelectorContainer') || document.body;
	}

	private trackContainer() {
		const observer = new MutationObserver(() => {
			if (!document.body.contains(this.button)) {
				if (!this._container.isConnected) {
					this._container = this.findContainerElement();
				}
				this._container.append(this.button);
			}
		});
		observer.observe(document.body, {
			childList: true,
			subtree: true
		});
	}

	private isFirstTime() {
		try {
			const v = this.storage.tryGetItem('selector-shown');
			return v !== 'true';
		} catch {
			return false;
		}
	}

	private setFirstTimeFalse() {
		try {
			this.storage.trySetItem('selector-shown', 'true');
		} catch {
		}
	}

	private renderElement(name: string, elInfo: HTMLElementInfo) {
		let el: HTMLElement;

		if (this._renderedElements.has(name)) {
			el = this._renderedElements.get(name);
		} else {
			el = document.createElement(elInfo.tag);
			this._renderedElements.set(name, el);
			elInfo.parent.appendChild(el);
		}

		if (elInfo.innerHTML && el.innerHTML != elInfo.innerHTML) {
			el.innerHTML = elInfo.innerHTML;
		} else if (elInfo.innerDom) {
			el.innerHTML = '';
			elInfo.innerDom.forEach((d) => el.appendChild(d));
		}

		if (Array.isArray(elInfo.classes)) {
			el.className = elInfo.classes.join(' ');
		} else {
			Object.keys(elInfo.classes).forEach((key) => {
				el.classList.toggle(key, (elInfo.classes as any)[key]);
			});
		}

		if (elInfo.style) {
			el.setAttribute('style', elInfo.style);
		}
	}

	private runPostInitHooks() {
		const hooks = [webflowPostInitHook];

		hooks.forEach((h) => h(this.button, this._cfg));
	}

	private runPostRenderHooks() {
		const hooks = [webflowPostRenderHook];

		hooks.forEach((h) => h(this.button, this._cfg));
	}

	private loadLangs() {
		if (this.langsPromise) {
			return this.langsPromise;
		}
		this.langsPromise = this._cfg.langs();
		this.langsPromise.then((v) => {
			this.langs = v;
		});
		return this.langsPromise;
	}

	private renderLangPanel() {
		if (!this.langs) {
			console.log("Can't render lang panel: no langs");
			return;
		}
		const langButtons = this.renderLangButtons();
		const gridColumnCount = this.getColumnCount(this.langs.length - 1);

		this.renderElement('langGrid', {
			tag: 'div',
			parent: this.root,
			innerDom: langButtons,
			classes: {
				'scLangPanel': true,
				'scLangPanel-Top': this._cfg.location == LanguageSelectorLocation.TopRight,
				'scLangPanel-Bottom': this._cfg.location == LanguageSelectorLocation.BottomRight,
				'hidden': this.panelIsHidden,
			},
			style: `grid-template-columns: repeat(${gridColumnCount}, 1fr)`,
		});
	}

	private renderLangButtons(): HTMLElement[] {
		const renderItem = (lang: InjectedCodeLang) => {
			const el = document.createElement('div');
			el.className = 'scLangItem';
			el.addEventListener('click', (e) => this.switchLang(lang.locale, e));
			el.innerHTML = lang.name;
			return el;
		};

		return this.langs
			.filter((x) => x.isSource || x.isPublished)
			.map((l) => renderItem(l));
	}

	getColumnCount(langCount: number): number {
		if (langCount < 4) {
			return 1;
		}
		if (langCount < 7) {
			return 2;
		}
		return 3;
	}

	async switchLang(locale: string, event?: any) {
		event?.stopPropagation();
		event?.preventDefault();
		this.hidePanel();

		this._cfg.switchLocale(locale);
		if (!this.langs) {
			await this.loadLangs();
		}
		this.currentLocale = this.langs.find((x) => x.locale === locale);
		this.render();
	}

	hidePanel() {
		this.panelIsHidden = true;
		this.render();
	}

	showPanel() {
		this.panelIsHidden = false;
		this.render();
	}
}
