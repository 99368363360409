<template>
	<div
		ref="overlay"
		class="wt-popup"
		@click="onOutsideClick"
	>
		<div
			class="wt-popup__form"
			:style="`width: ${formWidth}px;`"
		>
			<slot name="body" />
			<div class="wt-popup__button-group">
				<slot name="buttons" />
			</div>

			<ScButton
				v-if="closeButton"
				class="wt-popup__form--close"
				@click.prevent="$emit('close')"
				icon="close"
				view="flat"
		 	/>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ScButton } from "@smartcat/design-system-vue2";

	@Component({
		components: { ScButton },
	})
	export default class WtPopup extends Vue {
		@Prop({ type: Number, default: 700 })
		public readonly formWidth: number;

		@Prop({ type: Boolean, default: false })
		public readonly closeButton: boolean;

		mounted() {
			window.addEventListener('keydown', this.onKeyDown);
			window.focus();
		}

		beforeDestroy() {
			window.removeEventListener('keydown', this.onKeyDown);
		}

		public onOutsideClick(e: MouseEvent) {
			if (e.target === this.$refs.overlay) {
				e.preventDefault();
				this.$emit('close');
			}
		}

		public onKeyDown(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				e.preventDefault();

				this.$emit('close');
			}
		}
	}
</script>

<style lang="less" scoped>
	.wt-popup {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		background: rgba(20, 16, 29, 0.3);
		display: flex;
		justify-content: center;
		align-items: flex-start;
		z-index: 999;

		&__form {
			position: relative;
			top: 10%;
			background: #ffffff;
			padding: 50px 50px 60px 50px;
			width: 700px;
			box-shadow:
				0 0 2px rgba(44, 36, 64, 0.48),
				0 4px 10px rgba(44, 36, 64, 0.16);
			border-radius: 12px;

			&--close {
				position: absolute;
				right: 30px;
				top: 30px;
				cursor: pointer;
			}
		}

		&__button-group {
			display: flex;
			justify-content: space-between;
		}

		.wt-button {
			margin: 0;
		}
	}
</style>
