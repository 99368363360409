<template>
	<div />
</template>

<script lang="ts">
	import * as en from './i18n/en.json';
	import { Component, Vue } from '@/components/common/vue';
	import SmartwordIndicator from '@/components/widgets/smartword-indicator/smartword-indicator.vue';

	@Component({
		components: { SmartwordIndicator },
		i18n: { messages: { en } } as any,
	})
	export default class LnCheck extends Vue {}
</script>
