<template>
	<Transition name="bottom-popup">
		<div
			v-if="isShow"
			class="bottom-popup-wrapper"
		>
			<div class="bottom-popup">
				<div class="bottom-popup-left-side">
					<img
						src="../../../assets/icons/rocket.png"
						class="bottom-popup__logo"
						alt="Smartcat"
					/>
					<h3 class="bottom-popup__title"> Only 3 steps to translate your entire website: </h3>
				</div>
				<div class="steps">
					<div class="steps-item">
						<img
							src="../../../assets/icons/cursor-click.svg"
							class="steps-item__icon"
							alt="Cursor click"
						/>
						<p class="steps-item__text"> Create a free account on&nbsp;Smartcat </p>
					</div>
					<div class="steps-item">
						<img
							src="../../../assets/icons/write-text.svg"
							class="steps-item__icon"
							alt="Preview"
						/>
						<p class="steps-item__text"> Preview and edit your translation </p>
					</div>
					<div class="steps-item">
						<img
							src="../../../assets/icons/code.svg"
							class="steps-item__icon"
							alt="Code"
						/>
						<p class="steps-item__text"> Add a simple code to your website header to push it&nbsp;live </p>
					</div>
				</div>
				<div class="bottom-popup-right-side">
					<p class="bottom-popup__subtitle"> Make your entire website multilingual! </p>
					<WtButton
						label="Sign up for free"
						view="gradient"
						class="bottom-popup__button"
						@click="signUp"
					/>
				</div>
				<div
					class="bottom-popup__close"
					@click.prevent="$emit('close')"
				>
					<img
						src="../../../assets/icons/close.svg"
						alt="Close"
					/>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';

	@Component({
		components: { WtPopup, WtButton },
	})
	export default class SignUpDrawer extends Vue {
		signUp() {
			this.$emit('click-sign-up');
		}

		@Prop({ type: Boolean, default: false })
		public readonly isShow: boolean;
	}
</script>

<style lang="less" src="./bottom-popup.less"></style>
