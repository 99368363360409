<template>
	<div>
		<WtPopup
			class="script-popup"
			@close="$emit('cancel')"
		>
			<template #body>
				<div class="script-popup__head">
					<WtTitle>Almost there!</WtTitle>
					<a
						href="https://help.smartcat.com/hc/en-us/articles/5389670121874"
						target="_blank"
						class="script-popup__head--link"
					>
						<img
							src="../../../assets/icons/question.svg"
							alt="?"
						/>
						<span>How does it work?</span>
					</a>
				</div>
				<WtText>
					To publish translations on your website, add the following JavaScript code
					<br /><b>to the header</b> of your website:
				</WtText>
				<WtCode
					:code="script"
					:error="validationResultMessage"
					@copy="copyToClipboard"
				/>
			</template>
			<template #buttons>
				<div class="script-popup__footer">
					<WtButton
						label="Cancel"
						view="simple"
						@click="$emit('cancel')"
					/>
					<div class="script-popup__footer--side">
						<a
							v-if="false"
							href="#"
							class="script-popup__footer--copy"
						>
							<span
								class="label"
								style="margin-left: 0"
								>Share instructions</span
							>
						</a>
						<WtButton
							label="Skip validation and continue"
							view="simple"
							:spin="validateInProgress"
							:disabled="validateInProgress"
							@click="$emit('click-force-publish')"
						/>
						<span style="width: 10px"></span>
						<WtButton
							label="Validate and continue"
							:spin="validateInProgress"
							:disabled="validateInProgress"
							@click="$emit('click-validate')"
						/>
					</div>
				</div>
			</template>
			<Transition name="slide" />
		</WtPopup>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
	import WtTitle from '@/components/widgets/wt-title/wt-title.vue';
	import WtText from '@/components/widgets/wt-text/wt-text.vue';
	import WtCode from '@/components/widgets/wt-code/wt-code.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import { ScNotification } from '@smartcat/design-system-vue2';
	import { NotificationService } from '@/components/widgets/notifications/notificationService';
	import { inject } from '@/components/common/di';
	import { ScriptPublicationValidationResult } from '@/shared';

	@Component({
		components: { WtPopup, WtTitle, WtText, WtCode, WtButton, ScNotification },
	})
	export default class ScriptPopup extends Vue {
		@Prop({ type: String, default: null })
		public readonly script: string;

		@Prop({ type: String, default: ScriptPublicationValidationResult.ScriptIsOk })
		public readonly validationResult: ScriptPublicationValidationResult;

		@Prop({ type: Boolean, default: null })
		public readonly validateInProgress: boolean;

		@Prop({ required: false })
		public webSiteScript: string;

		public get validationResultMessage() {
			switch (this.validationResult) {
				case ScriptPublicationValidationResult.ScriptIsOk:
					return '';
				default:
					return this.$t(this.validationResult) as string;
			}
		}

		public async copyToClipboard() {
			await navigator.clipboard.writeText(this.script);
			this.$emit('copied');
			inject(NotificationService).showSuccess('Script copied');
		}
	}
</script>
<style lang="less" src="./script-popup.less" scoped />
