<template>
	<div class="stats-dropdown"
		 @mouseenter="showPopover = true"
		 @mouseleave="showPopover = false"
	>
		<div class="target-container">
			<slot name="target"/>
		</div>
		<div class="stats-dropdown-panel" v-if="showPopover">
			<div class="title">
				<div>
				</div>
				<div style="flex: 1"></div>
				<div style="width: 60px;margin-right: 6px;">
					Auto
					translated
				</div>
				<div style="width: 60px;">
					Human
					reviewed
				</div>
			</div>
			<div class="items-container">
				<slot name="items"/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import SourceLanguageSelector from '@/components/widgets/languages/source-selector.vue';
	import LangSelector from '@/components/widgets/lang-selector/lang-selector.vue';
	import WtBadge from '@/components/widgets/wt-badge/wt-badge.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';

	@Component({
		components: { LangSelector, SourceLanguageSelector, WtBadge, WtButton },
	})
	export default class StatsPopover extends Vue {
		showPopover = false;
	}
</script>

<style lang="less" scoped>
@import "@smartcat/design-system-vue2/colors";

.stats-dropdown {
	position: relative;
}

.target-container {
	display: flex;
	flex-direction: row;
	padding-bottom: 4px;
	padding-top: 4px;
}

.stats-dropdown-panel {
	position: absolute;
	z-index: 1000;
	width: 360px;
	border-radius: 6px;
	top: 28px;
	background: #413A53;
	right: 0;
	padding: 8px;
}

.items-container {
	max-height: 200px;
	overflow-y: auto;
	font-weight: normal;
	padding-right: 8px;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background: @mulberry-purple-35;
		border-radius: 3px;
		padding-left: 6px;

		&:hover {
			background: @mulberry-purple-40;
		}

		&:disabled {
			background: @mulberry-purple-10;
		}
	}
}

.title {
	color: white;
	font-size: 11px;
	font-weight: bold;
	display: flex;
	line-height: 11px;
	margin-bottom: 6px;
	margin-top: 5px;
	padding-right: 8px;
}
</style>
