import { ISectionInfo } from '@/script/logic/extraction/section-info';
import { ILangSelector } from '@/script/lang-selector/i-lang-selector';
import { LangSelectorConfig } from '@/script/lang-selector/lang-selector-config';
import { LovisaLangSelector } from '@/script/logic/customization/lovisa-lang-selector';
import { ShopifyCustomizationV2 } from '@/script/logic/customization/shopify-customization-v2';
import { TravelProSection } from '@/script/logic/customization/travel-pro';

export class TravelProCustomisation extends ShopifyCustomizationV2 {
	createLangSelector(config: LangSelectorConfig): ILangSelector {
		return new LovisaLangSelector(config);
	}

	public isIgnored(node: Node): boolean {
		if (node instanceof Element) {
			const el = node as HTMLElement;
			if (el.childNodes.length == 1 && el.childNodes[0].nodeType == 3) {
				return this.isInvalidText(node.textContent);
			}
		} else if (node.nodeType === 3) {
			return this.isInvalidText(node.textContent);
		}
		return false;

	}

	public findSectionForElement(element: Element, location: string): ISectionInfo | null {
		const lovisa = new TravelProSection(element, location);
		const sectionInfo = this.getSection(lovisa, element);

		if (sectionInfo) {
			return {
				id: sectionInfo.id
			}
		}
	}
}
