<template>
	<div class="target-languages">
		<ScMultiselect
			:modelValue="value"
			:options="languages"
			:groups="true"
			:error="error"
			placeholder="Languages"
			search-placeholder="Search by name"
			size="44"
			:class="{ 'sc-multiselect_invalid': isInvalid }"
			@update:modelValue="$emit('input', $event)"
		/>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ScMultiselect } from '@smartcat/design-system-vue2';
	import { inject } from '@/components/common/di';
	import { LanguagesService } from '@/components/widgets/languages/languages-service';
	import { ILanguageModel } from '@/components/widgets/languages/language-client';

	@Component({ components: { ScMultiselect } })
	export default class TargetLanguageSelector extends Vue {
		@Prop({ type: Array, required: true })
		public readonly value: number[];

		@Prop({ type: Number })
		public readonly sourceLanguageId: number;

		@Prop({ type: Boolean, required: false })
		public readonly isInvalid: boolean;

		@Prop({ type: Boolean, required: false })
		public readonly error: boolean;

		private readonly languagesService = inject(LanguagesService);

		public get languages() {
			return [
				{
					title: 'Popular',
					options: this.getLanguageOptions(this.languagesService.popularLanguages),
				},
				{
					title: 'Languages',
					options: this.getLanguageOptions(this.languagesService.generalLanguages),
				},
				{
					title: 'Dialects',
					options: this.getLanguageOptions(this.languagesService.dialectLanguages),
				},
			];
		}

		private getLanguageOptions(languages: ILanguageModel[]) {
			return languages
				.filter((l) => this.sourceLanguageId !== l.id)
				.map((l) => ({ value: l.id, text: l.englishName }));
		}

		public addTargetLanguage(languageId: number) {
			this.$emit('input', [...this.value, languageId]);
		}
	}
</script>

<style lang="less" scoped>
	.target-languages {
		display: flex;
	}
</style>
