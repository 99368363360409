<template>
	<div class="landing">
		<LandingMainSection :is-show-footer="isShowFooter" />
		<LandingWhySection />
		<LandingWhatSection />
		<LandingHowSection />
		<LandingWhySmartcatSection />
		<LandingFaqSection />
		<LandingFooterSection />
	</div>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import LandingMainSection from '@/components/landing/landing-main-section.vue';
	import LandingWhySection from '@/components/landing/landing-why-section.vue';
	import LandingWhatSection from '@/components/landing/landing-what-section.vue';
	import LandingHowSection from '@/components/landing/landing-how-section.vue';
	import LandingWhySmartcatSection from '@/components/landing/landing-why-smartcat-section.vue';
	import LandingFaqSection from '@/components/landing/landing-faq-section.vue';
	import LandingFooterSection from '@/components/landing/landing-footer-section.vue';
	import { MixpanelService } from '@/services/mixpanel-service';
	import { inject } from '@/components/common/di';
	import { vitalsClient } from '@smartcat/vitals-client';

	@Component({
		components: {
			LandingMainSection,
			LandingWhySection,
			LandingWhatSection,
			LandingHowSection,
			LandingWhySmartcatSection,
			LandingFaqSection,
			LandingFooterSection,
		},
	})
	export default class LandingPage extends Vue {
		public isShowFooter = true;
		public mixpanelService = inject(MixpanelService);

		private trackedScrollEvent = false;

		public created() {
			window.addEventListener('scroll', this.handleScroll, { passive: true });
		}

		public mounted() {
      // Disabled after reaching MTU limit
      // https://smartcat1.slack.com/archives/CFNEC1WC8/p1690490134154069
			// this.mixpanelService.trackDemoHomepageOpened();
			vitalsClient.sendPageLoadFinished();
		}

		public destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		}

		public handleScroll() {
			this.isShowFooter = window.scrollY < 50;

			// if (!this.trackedScrollEvent && window.scrollY > window.innerHeight) {
			// 	this.trackedScrollEvent = true;
			// 	this.mixpanelService.trackDemoHomepageScrolled();
			// }
		}
	}
</script>

<style lang="less" scoped>
	* {
		box-sizing: border-box;
	}

	.landing {
		font-family: 'Inter', sans-serif;
		width: 100%;
	}
</style>
