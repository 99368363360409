import Vue from 'vue';

export const confirmationContainer = Vue.observable({ items: [] });

class Confirmation {
	public message: string;
	public description: string;
	public buttonCaption: string;
	public resolve: (value: boolean) => void;
}

export function showConfirmation(msg: string, description: string, buttonCaption = 'OK'): Promise<boolean> {
	const promise = new Promise<boolean>((resolve) => {
		const onResolve = (value: boolean) => {
			confirmationContainer.items.splice(0, 1);
			resolve(value);
		}
		const newConfirmation = <Confirmation>{
			message: msg,
			description,
			buttonCaption,
			resolve: onResolve
		};
		confirmationContainer.items.push(newConfirmation);
	});
	return promise;
}