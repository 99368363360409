import { apiClient, PageSortField, PageTranslationDto, PageTranslationInfo, SortDirection } from "@/shared";
import { getPageKey, PageListItem } from "@/components/web-site-pages/page-list/page-list-item";
import { debounce } from "@/shared/utils/debounce";
import { delay } from "@/shared/utils/delay";

export class PageListLoader {
	public webSiteId: string = null;

	private latestBatchKey: string = null;
	private isLoadFinished = false;
	private setQueryDebounced: (v: string) => void;
	private nextLatestPause: Promise<unknown>;
	private _pages: Array<PageListItem> = [];
	private pagesByKey: Map<string, PageListItem> = new Map();
	private sortField: PageSortField = PageSortField.PagePath;
	private sortDirection: SortDirection = SortDirection.Desc;
	private loadQueue = Promise.resolve();
	private _loadCount = 0;
	private version = 0;
	private query = '';
	private loadLatestEnabled = false;

	constructor(webSiteId: string) {
		this.webSiteId = webSiteId;
		this.setQueryDebounced = debounce((v: string) => this.setQuery(v), 700);
	}

	init() {
		this._queue(() => this._loadNextBatch());
	}

	get pages(): Array<PageListItem> {
		return this._pages;
	}

	getLoadLatestEnabled() {
		return this.loadLatestEnabled;
	}

	isLoading() {
		return this._loadCount > 0;
	}

	isLoaded() {
		return this.isLoadFinished;
	}

	setLoadLatestEnabled(v: boolean) {
		if (this.loadLatestEnabled === v) {
			return;
		}
		this.resetLoadedPages();
		this.loadLatestEnabled = v;
		this._queue(() => this._loadNextBatch());
	}

	getQuery() {
		return this.query;
	}

	setQueryWithDebounce(v: string) {
		this.query = v;
		this.setQueryDebounced(v);
	}

	setQuery(v: string, skipLoad = false) {
		if (this.query === v) {
			return;
		}
		this.query = v;
		if (!skipLoad) {
			this.resetLoadedPages();
			this._queue(() => this._loadNextBatch());
		}
	}

	getSortField() {
		return this.sortField;
	}

	getSortDirection() {
		return this.sortDirection;
	}

	async loadNextBatch() {
		if (this.nextLatestPause) {
			await this.nextLatestPause;
		}

		await this._queue(() => this._loadNextBatch());
		if (this.loadLatestEnabled) {
			this.nextLatestPause = delay(2000);

		}
	}

	setSortField(sortField: PageSortField, sortDirection: SortDirection) {
		const isNewSortField = this.sortField !== sortField || sortDirection !== this.sortDirection;
		this.sortField = sortField;
		this.sortDirection = sortDirection;
		if (isNewSortField) {
			this.resetLoadedPages();
			this._queue(() => this._loadNextBatch());
		}
	}

	async update(selectedPaths: string[]) {
		const pageTranslationInfos = await apiClient.getPagesByPath({
			webSiteId: this.webSiteId,
			path: selectedPaths
		});
		this.updatePages(pageTranslationInfos);
	}


	updatePages(updatedPages: PageTranslationInfo[]) {
		updatedPages.forEach(newPage => {
			const existingPage = this.pagesByKey.get(getPageKey(newPage));
			if (existingPage) {
				existingPage.update(newPage);
			}
		});
	}

	private resetLoadedPages() {
		this.version++;
		this.loadLatestEnabled = false;
		this._pages = [];
		this.latestBatchKey = null;
		this.isLoadFinished = false;
	}

	private async _loadNextBatch() {
		this.version++;
		if (this.loadLatestEnabled) {
			await this._loadNextBatchLatest();
		} else {
			await this._loadNextBatchDefault();
		}
	}

	private async _loadNextBatchLatest() {
		const currentFilterVersion = this.version;
		const pagesBatch = await apiClient.getLatest({
			webSiteId: this.webSiteId,
			batchKey: this.latestBatchKey,
		});
		if (this.version !== currentFilterVersion) {
			return;
		}
		if (!this.latestBatchKey) {
			this._pages = []
		}
		this._appendPages(pagesBatch.items);

		this.latestBatchKey = pagesBatch.nextBatchKey;
	}

	private async _loadNextBatchDefault() {
		if (this.isLoadFinished) {
			return;
		}
		const currentFilterVersion = this.version;
		const pagesBatch = await apiClient.getPages({
			batchSize: 50,
			webSiteId: this.webSiteId,
			sortField: this.sortField,
			sortDirection: this.sortDirection,
			batchKey: this.latestBatchKey,
			query: this.query
		});
		if (this.version !== currentFilterVersion) {
			return;
		}
		if (!pagesBatch.nextBatchKey || pagesBatch.items.length === 0) {
			this.isLoadFinished = true;
		}
		if (!this.latestBatchKey) {
			this._pages = []
		}
		this._appendPages(pagesBatch.items);
		this.latestBatchKey = pagesBatch.nextBatchKey;
	}

	private _queue(func: () => Promise<void>) {
		this._loadCount++;
		this.loadQueue = this.loadQueue.then(func, func);
		this.loadQueue.then(() => this._loadCount--);
		return this.loadQueue;
	}

	private _appendPages(pages: PageTranslationDto[]) {
		const newPages = pages.map(p => new PageListItem(p));
		this._pages.push(...newPages);
		newPages.forEach(p => this.pagesByKey.set(p.key, p));
	}

}