<template>
	<div class="browser-toolbar">
		<div>
			<IconButton
				class="browser-toolbar-close"
				tooltip="Close preview and return to the project"
				@click="$emit('close')"
			>
				<img src="../../../assets/icons/close.svg" />
			</IconButton>
			<slot name="navigation" />
		</div>

		<div class="browser-toolbar__right-group">
			<div class="browser-toolbar-buttons">
				<slot name="buttons" />
			</div>

			<slot name="side-buttons" />
			<slot name="smartwords" />
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import IconButton from '@/components/widgets/browser-toolbar/icon-button.vue';
	import { ScLoaderSpin } from '@smartcat/design-system-vue2';
	import ToolbarButton from '../toolbar-button/toolbar-button.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import WtSmartwords from '@/components/widgets/wt-smartwords/wt-smartwords.vue';

	@Component({
		components: { ScLoaderSpin, IconButton, ToolbarButton, WtButton, WtSmartwords },
	})
	export default class BrowserToolbar extends Vue {
		@Prop({ type: Boolean, default: false })
		public readonly isShowSignUp: boolean;
	}
</script>

<style lang="less" src="./browser-toolbar.less" scoped />
