<template>

	<ScIcon v-if="sort==='asc'" name="chevron-up" color="black" size="16" />
	<ScIcon v-else-if="sort==='desc'" name="chevron-down" color="black" size="16" />
	<ScIcon v-else name="unknown" color="black" size="12" />
<!--	<ScIcon name="chevron-down" color="black" size="medium"/>-->
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ScIcon } from '@smartcat/design-system-vue2';


	@Component({
		components: { ScIcon },
	})
	export default class SortIcon extends Vue {
		@Prop({ required: false })
		public sort: string;
	}
</script>
