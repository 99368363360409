<template>
	<section class="footer">
		<div class="container">
			<div class="footer__copyright">
				<p class="footer__copyright--text">
					{{ copyrightText }}
				</p>
				<nav class="footer__copyright--nav">
					<a
						v-for="(link, key) in copyrightNav"
						:key="key"
						:href="link.href"
						class="footer__copyright--nav__link"
						>{{ link.label }}</a
					>
				</nav>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';

	@Component({
		components: {},
	})
	export default class LandingFooterSection extends Vue {
		public copyrightText = '© Smartcat Platform Inc. 2023 | 101 Main St., 17th Floor Cambridge, MA 02142';
		public copyrightNav = [
			{
				label: 'Privacy Policy',
				href: 'https://www.smartcat.com/privacy-policy/',
			},
			{
				label: 'Security Program',
				href: 'https://www.smartcat.com/smartcat-security-program/',
			},
			{
				label: 'Terms of Service',
				href: 'https://www.smartcat.com/terms/',
			},
			{
				label: 'Sitemap',
				href: 'https://www.smartcat.com/sitemap/',
			},
		];
	}
</script>

<style lang="less" src="./landing-footer-section.less" scoped></style>
