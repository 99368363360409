import { ISectionInfo } from '@/script/logic/extraction/section-info';
import { CustomizationService } from '@/script/logic/customization/customizations';
import { StringUtils } from '@/script/utils/string';
import { IContentUrlProvider } from "@/script/logic/content-url-provider";

export const scPlaceholderTag = 'pl';
export const explicitLangAttr = 'sc-source-lang';
const autoTranslateAttr = 'sc-autotranslate';
export const sharedSectionAttr = 'sc-shared-section';
const ignoreAttrs = ['sc-ignore', 'notranslate'];
const NonTranslatableTagNames = new Set([
	'script',
	'svg',
	'use',
	'style',
	'link',
	'object',
	'iframe',
	'noscript',
	'code',
]);
const InlineFormattingTagNames = new Set([
	'a',
	'acronym',
	'abbr',
	'address',
	'b',
	'bdi',
	'bdo',
	'big',
	'center',
	'cite',
	'del',
	'dfn',
	'em',
	'font',
	'i',
	'ins',
	'kbd',
	'mark',
	'meter',
	'progress',
	'q',
	'rp',
	'rt',
	'ruby',
	's',
	'samp',
	'span',
	'small',
	'strike',
	'strong',
	'sub',
	'sup',
	'time',
	'tt',
	'u',
	'var',
	'br',
]);

export class NodeAnalyzer {
	private static _translatableMetaNames = new Set<string>(['keywords', 'description', 'author']);
	private static _translatablePropNames = new Set<string>(['twitter:title', 'og:description', 'og:title']);
	private static _separatorTags = new Set<string>(['hr', 'br']);
	private static _selfClosingTags = new Set<string>([
		'area',
		'base',
		'br',
		'col',
		'embed',
		'hr',
		'img',
		'input',
		'link',
		'meta',
		'param',
		'source',
		'track',
		'wbr',
	]);

	public get translatableAttributes() {
		return ['title', 'aria-label', 'placeholder', 'value', 'alt', 'content'];
	}

	public get translatableAttributesQuery() {
		return this.translatableAttributes.map((attr) => `[${attr}]`).join(', ');
	}

	private readonly availableLocales: string[] = [];
	private readonly customizationService?: CustomizationService = null;
	private readonly contentUrlProvider?: IContentUrlProvider;

	constructor(contentUrlProvider?: IContentUrlProvider, customizationService?: CustomizationService, availableLocales?: string[]) {
		this.contentUrlProvider = contentUrlProvider;
		this.customizationService = customizationService;
		this.availableLocales = availableLocales || [];
	}

	public static tryGetExplicitlySetLang(element: Element): string | null {
		return element.getAttribute(explicitLangAttr);
	}

	public static isSelfClosingTag(tag: string) {
		if (!tag) {
			return false;
		}
		return NodeAnalyzer._selfClosingTags.has(tag.toLowerCase());
	}

	public static isTranslatableMetaElement(element: Element) {
		const name = element.getAttribute('name');
		if (name) {
			return NodeAnalyzer._translatableMetaNames.has(name);
		}
		const prop = element.getAttribute('property');
		if (prop) {
			return NodeAnalyzer._translatablePropNames.has(prop);
		}
		return false;
	}

	public static isSeparatorTag(tag: string) {
		if (!tag) {
			return false;
		}
		return NodeAnalyzer._separatorTags.has(tag.toLowerCase());
	}

	public static findElementBySectionAttribute(sectionId: string): HTMLElement | null {
		try {
			return document.documentElement.querySelector(`[${sharedSectionAttr}="${sectionId}"]`);
		} catch (_) {
			return null;
		}
	}

	public findSectionByChildNode(node: Node): ISectionInfo | null {
		try {
			const element = 'closest' in node ? (node as Element) : node.parentElement;
			const customization = this.customizationService?.getCustomization();
			const pageUrl = this.contentUrlProvider?.getPageUrl();
			const section = customization?.findSectionForElement(element, pageUrl);

			if (section) {
				return section;
			}

			const sectionRoot = element.closest(`[${sharedSectionAttr}]`);
			const sectionId = sectionRoot?.getAttribute(sharedSectionAttr) || null;

			if (!sectionId) {
				return null;
			}

			return {
				id: sectionId,
				customLang: NodeAnalyzer.tryGetExplicitlySetLang(sectionRoot),
			};
		} catch (_) {
			return null;
		}
	}

	public hasTranslatableAttributes(node: Node) {
		if (!(node instanceof Element)) {
			return false;
		}

		return this.translatableAttributes.some((attr) => !StringUtils.isBlank(node.getAttribute(attr)));
	}

	public isTranslatableElement(node: Node): boolean {
		return !NonTranslatableTagNames.has(node.nodeName.toLowerCase()) && ![4, 7, 8].includes(node.nodeType);
	}

	public isInlineElement(node: Node): boolean {
		return InlineFormattingTagNames.has(node.nodeName.toLocaleLowerCase());
	}

	public isBlockElement(node: Node) {
		if (node.nodeType == 1) {
			return !this.isInlineElement(node);
		}
		return false;
	}

	public isIgnoredTextNode(node: Node): boolean {
		return node.nodeType === 3 && this.isIgnored(node);
	}

	public isIgnored(node: Node): boolean {
		if (this.isIgnoredInternal(node)) {
			return true;
		}
		return this.anyParent(node, (n) => this.isIgnoredInternal(n));
	}

	public findExplicitlySetLang(node: Node): string {
		let current = node;
		while (current) {
			if (current.nodeType == 1) {
				const lang = (current as Element).getAttribute(explicitLangAttr);
				if (lang) {
					return this.patchLang(lang);
				}
			}
			current = current.parentNode;
		}
		return null;
	}

	public isAutoTranslatableElement(node: Node): boolean {
		if (this.isAutoTranslatableInternal(node)) {
			return true;
		}
		return this.anyParent(node, (n) => this.isAutoTranslatableInternal(n));
	}

	private isAutoTranslatableInternal(node: Node) {
		if (!(node instanceof Element)) {
			return false;
		}
		return node.hasAttribute(autoTranslateAttr);
	}

	public isTranslatableTextNode(node: Node) {
		if (node.nodeType !== 3) {
			return false;
		}
		return !this.isBlankTextNode(node) && StringUtils.containsAlphanumericCharacter(node.textContent);
	}

	public isBlankTextNode(node: Node): boolean {
		return node.nodeType === 3 && StringUtils.isBlank(node.textContent);
	}

	public anyParent(node: Node, predicate: (node: Node) => boolean, includeSelf?: boolean): boolean {
		let current: Node | null = includeSelf ? node : node.parentNode;
		while (current) {
			if (current == document.body || current == document.head || current == document.documentElement) {
				break;
			}
			if (predicate(current)) {
				return true;
			}
			current = current.parentNode;
		}
		return false;
	}

	public getAllTextNodes(root: Node): Node[] {
		if (root.nodeType === 3) {
			return [root];
		}

		const nodes = [];

		const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT);
		let n = walker.currentNode;

		while (n) {
			if (n.nodeType === 3 && !this.isIgnored(n)) {
				nodes.push(n);
			}
			n = walker.nextNode();
		}

		return nodes;
	}

	public getAllNodesWithTextAttributes(root: Element) {
		if (!root) {
			return [];
		}
		if (!root.querySelectorAll) {
			console.log('Invalid element used as a fragment root', root);
			return [];
		}

		const elementsWithAttrs = root.querySelectorAll(this.translatableAttributesQuery);

		if (!elementsWithAttrs) {
			return [];
		}

		const nodes = [...root.querySelectorAll(this.translatableAttributesQuery)].filter((n) => {
			return !this.isIgnored(n);
		});

		if (this.translatableAttributes.some((a) => root.hasAttribute(a))) {
			nodes.unshift(root);
		}

		return nodes;
	}

	public getTranslatableNodes(root: Element): Node[] {
		const textNodes = this.getAllTextNodes(root);
		const attrs = this.getAllNodesWithTextAttributes(root);

		return [...textNodes, ...attrs];
	}

	private isIgnoredInternal(node: Node) {
		if (this.isTextNodeWithAngularTemplate(node)) {
			return true;
		}

		if (!(node instanceof Element)) {
			return false;
		}

		if (
			node.className &&
			typeof node.className.includes === 'function' &&
			node.className.includes('sc-translator__header-content')
		) {
			return true;
		}

		if (
			NonTranslatableTagNames.has(node.tagName.toLowerCase()) ||
			node.tagName.toLowerCase().includes('grammarly')
		) {
			return true;
		}

		if (ignoreAttrs.some((x) => node.hasAttribute(x))) {
			return true;
		}

		const customization = this.customizationService?.getCustomization();

		return customization?.isIgnored(node) || false;
	}

	private patchLang(lang: string) {
		if (this.availableLocales.includes(lang)) {
			return lang;
		}
		const bestMatch = this.availableLocales.find((locale) => locale.split('-')[0] === lang.split('-')[0]);
		if (bestMatch) {
			return bestMatch;
		}
		return lang;
	}

	private isTextNodeWithAngularTemplate(node: Node) {
		return node.nodeType === 3
			&& 'angular' in window
			&& typeof node.textContent === 'string'
			&& node.textContent.includes('{{')
	}
}
