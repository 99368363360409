// Prevents fatal errors in Incognito mode where Storage API is blocked
export class ResilientStorage {
	public trySetItem(key: string, value: any) {
		try {
			localStorage.setItem(key, value);
		} catch (e) {}
	}

	public tryGetItem(key: string) {
		try {
			return localStorage.getItem(key);
		} catch (e) {
			return null;
		}
	}
}
