import { ScriptRendererConfigV1 } from '@/shared';
import { CustomizationCfg } from '@/script/logic/customization/customization-cfg';
import { TranslationTask } from '@/script/logic/translation/translation-task';
import { FragmentExportTask } from '@/script/logic/translation/fragment-export-task';
import { CustomizationBase } from '@/script/logic/customization/customization-base';

export class NfteCustomization extends CustomizationBase {
	private _timerRegex = /<span id="0"><span>\d+<\/span>d<\/span><span id="1"><span>\d+<\/span>h<\/span><span id="2"><span>\d+<\/span>m<\/span><span id="3"><span>\d+<\/span>s<\/span>/i;

	constructor(private cfg: CustomizationCfg) {
		super();
		this.cfg = cfg;
	}

	isIgnored(): boolean {
		return false;
	}

	public shouldIgnoreTask(task: TranslationTask | FragmentExportTask, pagePath: string): boolean {
		if (task.attr) {
			return false;
		}

		if (pagePath !== 'how-to-compete') {
			return false;
		}

		const fragment = task.tryGetWebFragment();

		if (!fragment) {
			// fragment may be null in case of extraction error
			return false;
		}

		return this._timerRegex.test(fragment.text);
	}

	tryGetCustomConfigForSection(_: string): ScriptRendererConfigV1 {
		return null;
	}

	hasAdditionalLangLinks(): boolean {
		return false;
	}

	getLangsForLinks(_: string[]): string[] {
		return [];
	}

	canGetPath(): boolean {
		return false;
	}

	getPath(_: string): string {
		return null;
	}

	public canChangeLocale(): boolean {
		return false;
	}

	public canDetectLocale(): boolean {
		return false;
	}

	public changeLocale(): void {
	}

	public getUrlForLocale(): string {
		return null;
	}

	public canPatchProxyUrl(): boolean {
		return false;
	}

	public patchProxyUrl(_: string, _2: string): string {
		return null;
	}

	public getNewLocation(): string {
		return null;
	}

	public detectLocaleByUrl(): string {
		return null;
	}

	shouldDisableScriptForPage(): boolean {
		return false;
	}

	selectLocaleByUrl(_: string, _2: string[]): string | null {
		return null;
	}

	allowToCleanupPages(): boolean {
		return false;
	}

	getPageSwitchDelay(): number | null {
		return undefined;
	}

	public shouldHideCrawlerButton(): boolean {
		return true;
	}
}
