<template>
	<div class="stats-popover-item"
	>
		<div class="title">
			{{ title }}
		</div>
		<div class="values">
			<WtBadge
				:value="totalTranslationPercent"/>
			<WtBadge
				:value="scTranslationPercent"
				view="info"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import WtBadge from '@/components/widgets/wt-badge/wt-badge.vue';

@Component({
	components: { WtBadge },
})
export default class StatsPopoverItem extends Vue {
	@Prop({ required: true })
	public title: string;

	@Prop({ required: true })
	public mtTranslationPercent: string;

	@Prop({ required: true })
	public totalTranslationPercent: string;

	@Prop({ required: true })
	public scTranslationPercent: string;

}
</script>

<style lang="less" scoped>
@import "@smartcat/design-system-vue2/colors";

.stats-popover-item {
	display: flex;
	flex-direction: row;
	gap: 8px;
	margin-bottom: 7px;

	.title {
		flex: 1;
		color: white;
		padding: 2px 2px 2px 0;
		font-size: 12px;
		font-weight: normal;
		word-break: break-word;
	}

	.values {
		display: flex;
		gap: 5px;
	}
}

.title {
	color: white;
	font-size: 12px;
	font-weight: bold;
}
</style>
