<template>
	<Transition name="slide">
		<ScNotification
			v-if="service.successMessage"
			class="wt-notification"
			type="success"
			@close="service.hide()"
		>
			{{ service.successMessage }}
		</ScNotification>
		<ScNotification
			v-if="service.errorMessage"
			class="wt-notification"
			type="alert"
			@close="service.hide()"
		>
			{{ service.errorMessage }}
		</ScNotification>
	</Transition>
</template>

<script lang="ts">
	import { ScNotification } from '@smartcat/design-system-vue2';
	import { Component } from 'vue-property-decorator';
	import Vue from 'vue';
	import { inject } from '@/components/common/di';
	import { NotificationService } from '@/components/widgets/notifications/notificationService';

	@Component({
		components: { ScNotification },
	})
	export default class ScriptPopup extends Vue {
		service = inject(NotificationService);
	}
</script>

<style lang="less" scoped>
	@import '../../common/styles/animations';

	.wt-notification {
		position: absolute !important;
		left: 10px;
		bottom: 0;
		z-index: 999999999;
	}
</style>
