export async function post<T>(url: string, request: any): Promise<T> {
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
		body: JSON.stringify(request),
	});
	if (response.status == 200) {
		return await response.json();
	}
	return Promise.reject(response.json());
}

export async function get<T>(url: string): Promise<T> {
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
	});
	if (response.status == 200) {
		return await response.json();
	} else if (response.status === 204) {
		return null;
	}
	return Promise.reject(response.json());
}

export async function getString(url: string): Promise<string> {
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
	});
	if (response.status == 200) {
		return await response.text();
	}
	return Promise.reject(response.json());
}
