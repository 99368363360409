import { CustomizationBase } from "@/script/logic/customization/customization-base";
import { TranslationTask } from "@/script/logic/translation/translation-task";
import { FragmentExportTask } from "@/script/logic/translation/fragment-export-task";

export class HclCustomization extends CustomizationBase {
	override isIgnored(el: Element) {
		return this.isAutocompleteElement(el);
	}

	override shouldIgnoreTask(task: TranslationTask | FragmentExportTask) : boolean {
		return this.isAutocompleteElement(task.getNode());
	}

	private isAutocompleteElement(node: Node): boolean {
		if (!node) {
			return false;
		}
		if (node.nodeType === 1) {
			if ((node as Element).classList.contains('ui-autocomplete')) {
				return true;
			}
		}
		return this.isAutocompleteElement(node.parentNode);
	}
}
