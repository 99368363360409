import mixpanel from 'mixpanel-browser';
import { ApiClient } from '@/shared';
import { inject } from '@/components/common/di';
import { UserService } from '@/services/user-service';
import { v4 } from 'uuid-browser';
import { SmartcatApiService } from '@/shared/api/smartcatApiService';

export type SignUpPopupSource = 'navigationAttempt' | 'toolbar' | 'languageSwitchAttempt' | 'editAttempt' | 'drawer';
const SESSION_STORAGE_KEY = 'mixpanelSession';

export class MixpanelService {
	private _sessionId: string;
	private _apiClient = new SmartcatApiService(new ApiClient());
	private _userService = inject(UserService);
	private initPromise: Promise<void>;

	public init() {
		this.initPromise = this.initPromise || this.initPrivate();
		return this.initPromise;
	}

	private async initPrivate() {
		try {
			this._sessionId = this.getSessionId();
			const settings = await this._apiClient.getMixpanelSettings();
			const accountId = await this._userService.getAccountId();

			if (process.env.NODE_ENV !== 'production') {
				return;
			}

			mixpanel.init(settings.corporateAppToken, {
				ignore_dnt: true,
			});

			if (accountId) {
				mixpanel.alias(accountId);
				mixpanel.identify(accountId);
			}
		} catch (e) {
			console.error(e);
		}
	}

	public trackDemoHomepageOpened() {
		this.trackDemoEvent('websiteTranslatorDemoHomepageOpened');
	}

	public trackDemoHomepageScrolled() {
		this.trackDemoEvent('websiteTranslatorDemoPageScrolled');
	}

	public trackDemoPreviewFormFilled(url: string, sourceLanguage: string, targetLanguages: string[]) {
		this.trackDemoEvent('websiteTranslatorDemoFormSubmitted', {
			url,
			sourceLanguage,
			targetLanguages,
		});
	}

	public trackDemoBrowserOpened(sharing: boolean) {
		this.trackDemoEvent('websiteTranslatorDemoBrowserOpened', {
			sharing,
		});
	}

	public trackDemoTranslatedPage(url: string, sourceLanguage: string, targetLanguage: string, sharing: boolean) {
		this.trackDemoEvent('websiteTranslatorDemoPageTranslated', {
			url,
			sourceLanguage,
			targetLanguage,
			sharing,
		});
	}

	public trackDemoTriedToNavigate(url: string, sharing: boolean) {
		this.trackDemoEvent('websiteTranslatorDemoNavigationAttempted', {
			url,
			sharing,
		});
	}

	public trackDemoTriedToSwitchLanguage(language: string, sharing: boolean) {
		this.trackDemoEvent('websiteTranslatorDemoLanguageSwitchAttempted', {
			language,
			sharing,
		});
	}

	public trackDemoTriedToEdit() {
		this.trackDemoEvent('websiteTranslatorDemoEditAttempted');
	}

	public trackDemoSignUpClicked(source: SignUpPopupSource, sharing: boolean) {
		this.trackDemoEvent('websiteTranslatorSignUpButtonClicked', {
			source,
			sharing,
		});
	}

	public trackDemoPopupClosed() {
		this.trackDemoEvent('websiteTranslatorSignUpPopupClosed');
	}

	public async trackPreviewBrowserOpened() {
		await this.trackEvent('websiteTranslatorPreviewBrowserOpened', {
			source: 'project',
		});
	}

	public trackTranslatedPage(url: string, sourceLanguage: string, targetLanguage: string) {
		this.trackEvent('websiteTranslatorPreviewPageTranslated', {
			url,
			sourceLanguage,
			targetLanguage,
			source: 'project',
		});
	}

	public trackNavigatedToOtherPage(url: string) {
		this.trackEvent('websiteTranslatorPreviewNavigationStarted', {
			url,
		});
	}

	public trackEnabledPageTranslation() {
		this.trackEvent('websiteTranslatorPreviewAutomaticTranslationEnabled');
	}

	public trackSwitchedLanguage(language: string) {
		return this.trackEvent('websiteTranslatorPreviewLanguageSwitched', {
			language,
		});
	}

	public trackEnteredEditMode(url: string) {
		this.trackEvent('websiteTranslatorPreviewEditModeStarted', {
			url,
		});
	}

	public trackReturnedFromEditMode(url: string) {
		this.trackEvent('websiteTranslatorPreviewEditModeFinished', {
			url,
		});
	}

	public trackHitPublish(url: string) {
		this.trackEvent('websiteTranslatorPreviewPublishButtonPressed', {
			url,
			source: 'browser',
		});
	}

	public trackJsCodeChecked(url: string) {
		this.trackEvent('websiteTranslatorPreviewJavaScriptCheckSucceeded', {
			url,
			source: 'browser',
		});
	}

	public async trackTranslationsPublished(url: string) {
		await this.trackEvent('websiteTranslatorPreviewPublishCompleted', {
			url,
			source: 'browser',
		});
	}

	private async trackDemoEvent(eventId: string, eventParams: { [key: string]: any } = {}) {
		try {
			await this.init();
			eventParams.session = this._sessionId;

			if (process.env.NODE_ENV !== 'production' || window.location.hostname.includes('stage')) {
				console.debug(eventId, eventParams);
				return;
			}

			mixpanel.track(eventId, eventParams);
		} catch (e) {
			console.error(e);
		}
	}

	private async trackEvent(eventId: string, eventParams: { [key: string]: any } = {}) {
		try {
			await this.init();
			eventParams.userId = await this._userService.getUserId();
			eventParams.accountId = await this._userService.getAccountId();
			eventParams.session = this._sessionId;

			if (process.env.NODE_ENV !== 'production' || window.location.hostname.includes('stage')) {
				return;
			}

			await this._apiClient.trackMixpanelEvent({
				eventId,
				eventParams,
			});
		} catch (e) {
			console.error(e);
		}
	}

	private getSessionId() {
		let session = sessionStorage.getItem(SESSION_STORAGE_KEY);

		if (!session) {
			session = v4();
			sessionStorage.setItem(SESSION_STORAGE_KEY, session);
		}

		return session;
	}
}
