import { ApiClient, CurrentSettingsDto } from '@/shared';
import { SmartcatApiService } from '@/shared/api/smartcatApiService';

export class UserService {
	private _settings: CurrentSettingsDto = null;
	private _api = new SmartcatApiService(new ApiClient());

	public async getUserId() {
		await this.ensureHaveSettings();
		return this._settings.currentUserId;
	}

	public async getAccountId() {
		await this.ensureHaveSettings();
		return this._settings.currentAccountId;
	}

	private async ensureHaveSettings() {
		if (this._settings) {
			return;
		}

		const settings = await this._api.getSettings();
		this._settings = settings;
	}
}
