<template>
	<div class="demo-publishing-admin-page">
		<h1>Published script for <a href="/test-publishing/">Demo</a>:</h1>
		<br />
		<textarea
			v-model="scriptValue"
			style="border: 1px solid #eaeaea"
		/>
		<br />
		<ScButton @click="updateScript"> Update </ScButton>
		<br />
		<div v-if="updated"> Updated... </div>
	</div>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import { ScButton } from '@smartcat/design-system-vue2';
	import MdInput from '@/components/widgets/md/md-input.vue';
	import { apiClient } from '@/shared';

	@Component({
		components: { ScButton, MdInput },
	})
	export default class DemoPublishingAdminPage extends Vue {
		public scriptValue = '';
		public updated = false;

		public async created() {
			this.scriptValue = await apiClient.getPublishedScript();
		}

		public async updateScript() {
			await apiClient.setPublishedScript(this.scriptValue);
			this.updated = true;
			setTimeout(() => {
				this.updated = false;
			}, 1000);
		}
	}
</script>
<style scoped lang="less" src="./demo-publishing-admin-page.less" />
