<template>
	<div class="wt-tabs">
		<div class="wt-tab-buttons">
			<div
				v-for="(name, i) in tabs"
				:key="name"
				:class="{ active: i === activeTab }"
				@click="selectTab(i)"
			>
				{{ name }}
			</div>
		</div>
		<div class="wt-active-tab">
			<slot :active-tab="activeTab" />
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component({})
	export default class WtTabs extends Vue {
		public activeTab = 0;

		@Prop({ type: Array, default: () => [] as any[] })
		public tabs: string[];

		public selectTab(index: number) {
			this.activeTab = index;
		}
	}
</script>

<style lang="less" scoped>
	@import '~@smartcat/design-system-vue2/colors';

	.wt-tabs {
		display: flex;
		flex-direction: column;
	}

	.wt-tab-buttons {
		display: flex;
		align-items: center;

		& > div {
			position: relative;
			color: @mulberry-purple-90;
			font-size: 14px;
			line-height: 20px;
			font-weight: 600;
			padding: 0 3px 5px;
			user-select: none;

			&:nth-child(n + 2) {
				margin-left: 20px;
			}

			&.active::after {
				position: absolute;
				bottom: 0;
				left: 0;
				content: '';
				height: 3px;
				width: 100%;
				background: @mulberry-purple-90;
				border-radius: 2px;
			}

			&:not(.active) {
				cursor: pointer;
			}
		}
	}

	.wt-active-tab {
		margin-top: 15px;
	}
</style>
