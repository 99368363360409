<template>
	<div class="browser-page">
		<slot name="toolbar" />
		<div
			ref="browserFrame"
			class="browser-frame"
		>
			<LoadingError v-if="error" />
			<div
				v-else-if="isLoading"
				class="browser-frame-loading"
			>
				<ScLoaderSpin size="32" />
			</div>
			<slot name="frame" />
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ScLoaderSpin } from '@smartcat/design-system-vue2';
	import LoadingError from '@/components/widgets/loading-error/loading-error.vue';

	@Component({
		components: { LoadingError, ScLoaderSpin },
	})
	export default class BrowserLayout extends Vue {
		@Prop({ required: false })
		public isLoading: boolean;

		@Prop({ required: false })
		public error: boolean;

		mounted() {
			if (this.$route.path.indexOf('widgets') == -1) {
				const scLangSelectorStyle = document.createElement('style');
				scLangSelectorStyle.innerHTML = `.scLangSelector{display: none;}`;
				document.body.appendChild(scLangSelectorStyle);
			}
		}
	}
</script>
<style scoped lang="less" src="./browser-layout.less" />
