import { addMessageListener, createClientProxy, IProxyFrameService } from '@/shared';
import Vue from 'vue';

export class FrameProxyProvider {
	constructor(frame: any) {
		this.frame = frame;
		Vue.observable(this);
	}

	public get isLoaded() {
		return this.proxy != null;
	}

	init(onProxyLoaded: (p: IProxyFrameService) => void) {
		addMessageListener((data: any) => {
			if (data.type == 'sc-proxy-loaded') {
				const service = createClientProxy<IProxyFrameService>('ProxyFrame', this.frame.contentWindow);
				this.proxy = service.proxy;
				this.proxyDispose = service.dispose;
				this.proxyWaiters.forEach((resolve) => resolve(this.proxy));
				this.proxyWaiters = [];
				onProxyLoaded(this.proxy);
			}
			if (data.type == 'sc-proxy-unloaded') {
				if (this.proxyDispose) {
					this.proxyDispose();
				}
				this.proxyDispose = null;
				this.proxy = null;
			}
		});
	}

	waitProxy(): Promise<IProxyFrameService> {
		return new Promise<IProxyFrameService>((resolve) => {
			if (this.proxy) {
				resolve(this.proxy);
			} else {
				this.proxyWaiters.push(resolve);
			}
		});
	}

	private frame: any;
	private proxy: IProxyFrameService = null;
	private proxyDispose: () => void;
	private proxyWaiters: ((p: IProxyFrameService) => void)[] = [];
}
