export function getPathSimple(pageUrl: string) {
	const path = pageUrl
		.replace(/https?:\/\//i, '')
		.replace(/^.*?(\/|$)/, '')
		.replace(/\?.*/, '')
		.replace(/#.*/, '')
		.replace(/\/$/, '')
		.replace(/^\/+/, '')
		.toLowerCase();
	return decodeURIComponent(path).replace(/\+/g,' ') || '__index';
}
