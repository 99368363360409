import Vue from 'vue';
import { IPageTranslationProgress } from "@/shared";

export class BrowserButtonState {
	public showTranslate = false;
	public showEdit = false;
	public showPublish = false;
	public showSettings = false;
	public disableButtons = false;

	constructor() {
		Vue.observable(this);
	}

	public reset() {
		this.showEdit = false;
		this.showPublish = false;
		this.showTranslate = false;
		this.showSettings = false;
	}

	public update(stats: IPageTranslationProgress | null, isTranslationAllowedBySettings: boolean, hasSections: boolean) {
		if (!isTranslationAllowedBySettings) {
			this.showSettings = true;
			this.updateState(false, false, hasSections);
			return;
		}
		if (!stats?.main) {
			this.showSettings = true;
			return;
		}


		const isTranslationPossible = stats.main.totalFragmentCount > 0;
		const isTranslationStarted = stats.main.mtTranslatedCount > 0 || stats.main.smartcatTranslatedCount > 0;
		this.showSettings = true;

		const pageSection = stats?.sections.find(x => x.sectionId.indexOf('page') > -1);
		const pageSectionTranslationStarted = pageSection && (pageSection.mtTranslatedCount > 0 || pageSection.smartcatTranslatedCount > 0);
		const pageSectionTranslationPossible = pageSection && pageSection.totalFragmentCount > 0;
		this.updateState(isTranslationStarted || pageSectionTranslationStarted, isTranslationPossible || pageSectionTranslationPossible, hasSections);
	}


	private updateState(isTranslationStarted: boolean, isTranslationPossible: boolean, hasSections: boolean) {
		if (!isTranslationPossible) {
			this.showTranslate = false;
			this.showEdit = hasSections;
			this.showPublish = false;
			return;
		}
		if (isTranslationStarted) {
			this.showTranslate = false;
			this.showEdit = true;
			this.showPublish = true;
		} else {
			this.showTranslate = true;
			this.showEdit = false;
			this.showPublish = false;
		}
	}
}
