import { sanitize } from '@/script/utils/sanitize';

export function kebabCase(textContent: string) {
	// get var name from text
	const words = textContent.split(' ');
	let varName = '';
	for (let i = 0; i < words.length; i++) {
		// skip whitespaces
		let w = words[i].trim();

		w = sanitize(w);

		if (!w) {
			continue;
		}
		varName += (varName ? '-' : '') + w.toLowerCase();
	}
	return varName;
}
