import { inject, registerDependencyInPageScope } from '@/components/common/di';
import { LanguagesStore } from '@/components/widgets/languages/language-store';

export class LanguagesService {
	private readonly popularLanguageIds = [
		9, // English
		10, // Spanish
		25, // Russian
		12, // French
		1055, // Turkish
		2052, // Chinese (simplified)
		7, // German
		2070, // Portuguese
		1041, // Japanese
		1042, // Korean
		42, // Vietnamese
	];

	constructor(private readonly languagesStore = inject(LanguagesStore)) {}

	public get popularLanguages() {
		return this.popularLanguageIds.map((id) => this.languagesStore.getById(id));
	}

	public get generalLanguages() {
		return this.languagesStore.allSortedByName.filter((l) => {
			return l.generalizedLanguageId === l.id && !this.popularLanguages.includes(l);
		});
	}

	public get dialectLanguages() {
		return this.languagesStore.allSortedByName.filter((l) => {
			return l.generalizedLanguageId !== l.id && !this.popularLanguages.includes(l);
		});
	}
}

registerDependencyInPageScope(LanguagesService);
