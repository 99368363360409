import { ISectionInfo } from '@/script/logic/extraction/section-info';
import { LovisaSection } from '@/script/logic/customization/lovisa';
import { ILangSelector } from '@/script/lang-selector/i-lang-selector';
import { LangSelectorConfig } from '@/script/lang-selector/lang-selector-config';
import { LovisaLangSelector } from '@/script/logic/customization/lovisa-lang-selector';
import { ShopifyCustomizationV2 } from '@/script/logic/customization/shopify-customization-v2';

export class LovisaCustomization extends ShopifyCustomizationV2 {
	private readonly _productJsonSelector = '[data-product-json]';
	private readonly _instaDate = '.fs-entry-date';
	private readonly _productHandleSelector = '[data-apz-upsell-product-handle]';
	private readonly _strongTag = 'strong[id="0"]';
	private readonly _spanWithTag = 'span[id="1"]';

	createLangSelector(config: LangSelectorConfig): ILangSelector {
		return new LovisaLangSelector(config);
	}

	public isIgnored(node: Node): boolean {
		if (node instanceof Element) {
			const el = node as HTMLElement;
			if (
				node.closest(this._productJsonSelector)
				|| node.closest(this._instaDate)
				|| node.closest(this._strongTag)
				|| node.closest(this._spanWithTag)
			) {
				return true;
			}
			if (el.childNodes.length == 1 && el.childNodes[0].nodeType == 3) {
				return this.isInvalidText(node.textContent);
			}
		} else if (node.nodeType === 3) {
			if (
				node.parentElement.closest(this._productJsonSelector)
				|| node.parentElement.closest(this._instaDate)
				|| node.parentElement.closest(this._strongTag)
				|| node.parentElement.closest(this._spanWithTag)
			) {
				return true;
			}
			return this.isInvalidText(node.textContent);
		}
		return false;

	}

	isInvalidText(t: string): boolean {
		return super.isInvalidText(t) || t.toLowerCase().includes('optimizely');
	}

	public findSectionForElement(element: Element, location: string): ISectionInfo | null {
		const lovisa = new LovisaSection(element, location);
		const sectionInfo = this.getSection(lovisa, element);

		if (sectionInfo) {
			return {
				id: sectionInfo.id
			}
		}

		const productHandle = element.closest(this._productHandleSelector);
		if (productHandle) {
			const val = productHandle.getAttribute('data-apz-upsell-product-handle');
			if (val) {
				return {
					id: 'dynamic/description/' + val,
				}
			}
		}
	}
}
