<template>
	<section
		id="why-smartcat"
		class="why-smartcat"
	>
		<div class="container">
			<h2 class="why-smartcat-title"> Why Smartcat? </h2>
			<p class="why-smartcat-text">
				Smartcat is a Language Delivery Platform™ that combines intelligent, plug-and-play, automatic
				translation with collaborative workflows, vetted linguists, and an embedded supply chain for
				unparalleled ease of use across your organization.
			</p>
			<div class="why-smartcat-list">
				<div class="why-smartcat-list__item">
					<img
						src="../../assets/images/landing/technology.png"
						alt="Image"
						class="why-smartcat-list__item--image"
					/>
					<h4 class="why-smartcat-list__item--title"> Technology </h4>
					<p class="why-smartcat-list__item--text">
						Take advantage of AI-driven machine translation that learns from your edits and gets better over
						time
					</p>
					<a
						href="https://www.smartcat.com/machine-translation/"
						target="_blank"
						class="why-smartcat-list__item--link"
						>Learn more</a
					>
				</div>
				<div class="why-smartcat-list__item">
					<img
						src="../../assets/images/landing/human-editing.png"
						alt="Image"
						class="why-smartcat-list__item--image"
					/>
					<h4 class="why-smartcat-list__item--title"> Human Editing </h4>
					<p class="why-smartcat-list__item--text">
						Find the best linguists in our built-in Marketplace of 500,000+ vetted language professionals
						and 5,000+ boutique agencies ready to work on your projects
					</p>
					<a
						href="https://www.smartcat.com/find-translators/"
						target="_blank"
						class="why-smartcat-list__item--link"
						>Learn more</a
					>
				</div>
				<div class="why-smartcat-list__item">
					<img
						src="../../assets/images/landing/workflow.png"
						alt="Image"
						class="why-smartcat-list__item--image"
					/>
					<h4 class="why-smartcat-list__item--title"> Workflow </h4>
					<p class="why-smartcat-list__item--text">
						Manage internal and external suppliers using one agreement and pay them with one invoice
					</p>
					<a
						href="https://www.smartcat.com/organizations/payments/"
						target="_blank"
						class="why-smartcat-list__item--link"
						>Learn more</a
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';

	@Component({
		components: {},
	})
	export default class LandingWhySmartcatSection extends Vue {}
</script>

<style lang="less" src="./landing-why-smartcat-section.less" scoped></style>
