import { LanguageSelectorLocation } from '@/shared';
import { LangSelectorConfig } from '@/script/lang-selector/lang-selector-config';

export function webflowPostInitHook(selector: HTMLElement, config: LangSelectorConfig) {
	if (config.location !== LanguageSelectorLocation.BottomRight) {
		return;
	}

	const { top, bottom, right } = config.offsets;
	const defaultOffset = 20;

	if (!(top === 0 && bottom === defaultOffset && right === defaultOffset)) {
		return;
	}

	const observer = new MutationObserver((mutations) => {
		for (const m of mutations) {
			if (!m.addedNodes) {
				continue;
			}

			for (const n of m.addedNodes) {
				if (n.nodeType === 1) {
					const el = n as HTMLElement;

					if (el.classList.contains('w-webflow-badge')) {
						console.debug('Webflow detected');
						webflowPostRenderHook(selector, config);
						return;
					}
				}
			}
		}
	});
	observer.observe(document.body, {
		childList: true,
	});
}

export function webflowPostRenderHook(selector: HTMLElement, config: LangSelectorConfig) {
	const webflowBadge = document.querySelector('.w-webflow-badge');

	if (!webflowBadge || config.location !== LanguageSelectorLocation.BottomRight) {
		return;
	}

	selector.style.right = `12px`;
	selector.style.bottom = `47px`;
}
