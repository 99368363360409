<template>
	<button
		class="icon-button"
		v-bind="$props"
		@click="$emit('click', $event)"
	>
		<slot />
		<p
			v-if="tooltip"
			class="icon-button__tooltip"
		>
			{{ tooltip }}
		</p>
	</button>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component
	export default class IconButton extends Vue {
		@Prop({ type: String, default: null })
		public readonly tooltip: string;
	}
</script>

<style lang="less" scoped>
	.icon-button {
		all: unset;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 21px;
		width: 21px;
		cursor: pointer;
		flex-shrink: 0;
		border-radius: 4px;
		position: relative;

		&:hover {
			background: #eee;
		}

		&:disabled img {
			opacity: 0.4;
		}

		&:hover {
			.icon-button__tooltip {
				opacity: 1;
				visibility: visible;
			}
		}

		&.browser-toolbar-close {
			.icon-button__tooltip {
				left: 110px;

				&::before {
					left: calc(10px);
				}
			}
		}

		&__tooltip {
			cursor: auto;
			transition: 0.1s ease;
			opacity: 0;
			visibility: hidden;
			color: #ffffff;
			background: #413a53;
			border-radius: 6px;
			padding: 8px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			position: absolute;
			bottom: calc(-100% - 20px);
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;
			z-index: 99999;

			&::after {
				content: '';
				height: 15px;
				position: absolute;
				top: -15px;
				left: 0;
				width: 100%;
			}

			&::before {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				position: absolute;
				margin: 0 5px;
				border-color: #413a53;
				z-index: 1;
				border-width: 0 5px 5px 5px;
				border-left-color: transparent !important;
				border-right-color: transparent !important;
				border-top-color: transparent !important;
				top: -5px;
				left: calc(50% - 10px);
			}
		}
	}
</style>
