import { render, staticRenderFns } from "./settings-popup.vue?vue&type=template&id=2662f43c&scoped=true&"
import script from "./settings-popup.vue?vue&type=script&lang=ts&"
export * from "./settings-popup.vue?vue&type=script&lang=ts&"
import style0 from "./settings-popup.less?vue&type=style&index=0&id=2662f43c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2662f43c",
  null
  
)

export default component.exports