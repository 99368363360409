import VueI18n from 'vue-i18n';
import { messages } from '@/i18n';

export function createI18n() {
	const silentWarn = process.env.NODE_ENV === 'production' || process.env.target === 'server';

	return new VueI18n({
		locale: 'en',
		fallbackLocale: 'en',
		silentFallbackWarn: silentWarn,
		silentTranslationWarn: silentWarn,
		messages,
	});
}
