<template>
	<div class="purchase-popup">
		<WtPopup
			:form-width="500"
			close-button
			@close="$emit('hide')"
		>
			<template #body>
				<img
					src="../../../assets/icons/smartcat-icon.svg"
					class="purchase-popup__logo"
					alt="Smartcat"
				/>
				<h3 class="purchase-popup__title"> Not enough Smartwords </h3>
				<p class="purchase-popup__text desc">
					You ran out of your Smartwords balance for this month. Please purchase
					<span>{{ purchaseSmartwordCount }}</span> Smartwords to complete page translation or wait until the
					next month when your balance will be renewed.
				</p>
				<WtButton
					label="Purchase Smartwords"
					class="purchase-popup__button"
					@click="purchase()"
				/>
			</template>
		</WtPopup>
	</div>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import { Prop } from '@/components/common/vue';

	@Component({
		components: { WtPopup, WtButton },
	})
	export default class PurchasePopup extends Vue {
		@Prop({ required: false })
		public purchaseSmartwordCount?: number;

		public purchase() {
			this.$emit('click-purchase');
		}
	}
</script>

<style lang="less" src="./purchase-popup.less"></style>
