<template>
	<BrowserLayout :is-loading="false">
		<template #toolbar>
			<h1>
				<LnCheck />
			</h1>
			<SignInPopup
				v-if="false"
				@click-register="alert('123')"
				@hide="alert('hide')"
			/>
			<PurchasePopup v-if="false" />
			<BrowserToolbar>
				<template #smartwords>
					<SmartwordIndicator
						max-smartwords="5000"
						current-smart-words-usage-count="3000"
						subscription-name="Free"
					/>
				</template>
				<template #buttons>
					<ToolbarButton>Publish</ToolbarButton>
					<ToolbarButton>Edit</ToolbarButton>
				</template>
				<template #navigation>
					<BrowserNavigation
						selected-lang="ru"
						url="https://ya.ru"
						machine-translation="10"
						professional-translation="23"
						:forward-disabled="true"
						:lang-list-is-loading="true"
						:backward-disabled="false"
						:langs="['en', 'es', 'ru']"
					/>
				</template>
			</BrowserToolbar>
			<ScriptPopup
				v-if="false"
				web-site-script="some script some script some script"
			/>
		</template>
		<template #frame>
			<iframe />
		</template>
	</BrowserLayout>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import BrowserLayout from '@/components/widgets/browser-layout/browser-layout.vue';
	import BrowserToolbar from '@/components/widgets/browser-toolbar/browser-toolbar.vue';
	import ToolbarButton from '@/components/widgets/toolbar-button/toolbar-button.vue';
	import BrowserNavigation from '@/components/widgets/browser-navigation/browser-navigation.vue';
	import ScriptPopup from '@/components/widgets/script-popup/script-popup.vue';
	import SignInPopup from '@/components/widgets/sign-in-popup/sign-in-popup.vue';
	import { LangSelector } from '@/script/lang-selector/lang-selector';
	import PurchasePopup from '@/components/widgets/purchase-popup/purchase-popup.vue';
	import LnCheck from '@/components/widgets/smartword-indicator/ln-check.vue';
	import SmartwordIndicator from '@/components/widgets/smartword-indicator/smartword-indicator.vue';
	import { LanguageSelectorLocation } from '@/shared';

	@Component({
		components: {
			SmartwordIndicator,
			LnCheck,
			PurchasePopup,
			SignInPopup,
			ScriptPopup,
			BrowserNavigation,
			ToolbarButton,
			BrowserToolbar,
			BrowserLayout,
		},
	})
	export default class WidgetsPage extends Vue {
		public mounted() {
			new LangSelector({
				getCurrentLang: () => ({
					name: 'English (United Kingdom)',
					locale: 'en',
					isSource: true,
					rtl: false,
					isPublished: true,
				}),
				switchLocale: (l) => {
					alert(l);
				},
				langs: () =>
					Promise.resolve([
						{
							name: 'English (United Kingdom)',
							locale: 'en',
							isSource: true,
							rtl: false,
							isPublished: true,
						},
						{ name: 'Russian', locale: 'ru', isSource: false, rtl: false, isPublished: true },
						{ name: 'German', locale: 'de', isSource: false, rtl: false, isPublished: true },
						{ name: 'Japaneese', locale: 'jp', isSource: false, rtl: false, isPublished: true },
						{ name: 'Deuth', locale: 'd2', isSource: false, rtl: false, isPublished: true },
						{ name: 'Korean', locale: 'ko', isSource: false, rtl: false, isPublished: true },
					]),
				location: LanguageSelectorLocation.BottomRight,
				offsets: {
					top: 0,
					bottom: 20,
					right: 20,
				},
			}).init();
		}

		public alert(v: any) {
			// console.log('hide!');
			alert(v);
		}
	}
</script>
