import { addMessageListener } from './message-listen';

export function argsToArray(args: IArguments) {
	const res = [];
	for (let i = 0; i < args.length; i++) {
		const arg = args[i];
		res[i] = arg;
	}
	return res;
}

const logPostedMessages = false;

export class ServiceServer {
	listeners: { [eventName: string]: (...args: any[]) => void } = {};
	private onmessage: (message: any) => void;
	private dispose: () => void;

	constructor(
		private service: any,
		private serviceName: string,
		private clientWindow: Window,
	) {}

	init() {
		this.onmessage = async (message: any) => {
			if (logPostedMessages) {
				console.debug(message);
			}

			if (message.target === this.serviceName) {
				if (message.eventName) {
					this.processEvent(message);
				} else if (message.methodName) {
					await this.processMethodCall(message);
				}
			}
		};
		this.dispose = addMessageListener(this.onmessage);
	}

	public stop() {
		this.dispose && this.dispose();
	}

	private async processMethodCall(message: any) {
		let result: any;
		let error: any;
		const method = this.service[message.methodName];
		try {
			if (!method) {
				console.error('Failed method name' + message.methodName, this.service);
				throw new Error('Method ' + message.methodName + 'not found');
			}
			result = await method.apply(this.service, message.arguments);
		} catch (e: any) {
			error = { message: typeof e === 'string' ? e : e.message, stack: e.stack };
			console.error(e);
			throw e;
		} finally {
			this.clientWindow.postMessage(
				{
					promiseId: message.promiseId,
					result,
					error,
				},
				'*',
			);
		}
	}

	private processEvent(message: any) {
		if (message.action == 'on') {
			if (this.listeners[message.eventName]) {
				return;
			}
			const method = this.service['on' + message.eventName];
			const handler = () => {
				this.clientWindow.postMessage({
					eventName: message.eventName,
					// @ts-ignore eslint-disable-next-line prefer-rest-params
					arguments: argsToArray(arguments),
				});
			};
			method.call(this.service, handler);
			this.listeners[message.eventName] = handler;
		} else if (message.action == 'off') {
			const handler = this.listeners[message.eventName];
			if (handler) {
				const method = this.service['off' + message.eventName];
				method.apply(this.service, handler);
			}
		}
	}
}
