import { render, staticRenderFns } from "./target-selector.vue?vue&type=template&id=e20b6cd4&scoped=true&"
import script from "./target-selector.vue?vue&type=script&lang=ts&"
export * from "./target-selector.vue?vue&type=script&lang=ts&"
import style0 from "./target-selector.vue?vue&type=style&index=0&id=e20b6cd4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e20b6cd4",
  null
  
)

export default component.exports