<template>
	<a
		href="https://smartcat.com/services"
		target="_blank"
		class="smartwords"
	>
		<img
			src="../../../assets/icons/smartwords.svg"
			alt="Smartwords"
		/>
		{{ formattedCount }}
	</a>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component
	export default class WtSmartwords extends Vue {
		@Prop({ type: Number, default: 0 })
		public readonly count: number;

		public get formattedCount() {
			const format = new Intl.NumberFormat();
			return format.format(this.count);
		}
	}
</script>

<style lang="less" scoped>
	.smartwords {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		border: 1.5px solid #dedce4;
		background: white;
		border-radius: 60px;
		padding: 8px 12px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
		color: #6b6679;
		height: 36px;

		img {
			margin-right: 6px;
		}
	}
</style>
