import { render, staticRenderFns } from "./toolbar-button.vue?vue&type=template&id=36c3a52e&scoped=true&"
import script from "./toolbar-button.vue?vue&type=script&lang=ts&"
export * from "./toolbar-button.vue?vue&type=script&lang=ts&"
import style0 from "./toolbar-button.less?vue&type=style&index=0&id=36c3a52e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c3a52e",
  null
  
)

export default component.exports