import { LanguagesStore } from '@/components/widgets/languages/language-store';
import { inject, registerDependencyInRootScope, registerInstanceInRootScope } from '@/components/common/di';
import { MixpanelService } from '@/services/mixpanel-service';
import { UserService } from '@/services/user-service';

export async function init() {
	registerDependencyInRootScope(UserService);

	const mixpanelService = new MixpanelService();
	mixpanelService.init().then();

	registerInstanceInRootScope(MixpanelService, mixpanelService);

	const languageStore: LanguagesStore = inject(LanguagesStore);
	await languageStore.init();
}
