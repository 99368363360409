<template>
	<section class="components">
		<div class="components__item">
			<h3 class="components__title"> Badges </h3>
			<div class="components__row">
				<WtBadge value="41" />
				<WtBadge
					value="41"
					icon="magic-stick"
					tooltip="Automatic translation completed."
				/>
				<WtBadge
					value="41"
					icon="magic-stick"
					disabled
				/>
				<WtBadge
					value="41"
					icon="magic-stick"
					disabled
					spin
				/>
				<WtBadge
					value="41"
					icon="user"
					view="info"
					disabled
				/>
				<WtBadge
					value="41"
					icon="user"
					view="info"
					disabled
					spin
				/>
				<WtBadge
					value="41"
					icon="user"
					view="info"
					:spin="isTestSpin"
					@click="testSpin"
				/>
			</div>
		</div>
		<div class="components__item">
			<h3 class="components__title"> Buttons </h3>
			<div class="components__row">
				<WtButton label="Button" />
				<WtButton
					label="Edit"
					icon="pencil"
				/>
				<WtButton
					label="Edit"
					icon="pencil"
					view="border"
				/>
				<WtButton
					label="Sign up to continue"
					view="gradient"
				/>
				<WtButton
					label="Edit"
					icon="pencil"
					disabled
				/>
				<WtButton
					label="Edit"
					icon="pencil"
					spin
				/>
				<WtButton
					:spin="isTestButton"
					label="Publish"
					icon="upload"
					@click="testButton"
				/>
				<WtButton
					:spin="isTestButton"
					label="Publish"
					icon="upload"
					view="border"
					@click="testButton"
				/>
				<WtButton
					:spin="isTestButton"
					label="Start translation"
					icon="magic"
					@click="testButton"
				/>
				<WtButton
					:spin="isTestButton"
					label="Start translation"
					icon="magic"
					view="border"
					@click="testButton"
				/>
			</div>
		</div>
		<div class="components__item">
			<h3 class="components__title"> Smartwords </h3>
			<div class="components__row">
				<WtSmartwords :count="13000" />
			</div>
		</div>
		<div class="components__item">
			<h3 class="components__title"> Code </h3>
			<div
				class="components__row"
				style="justify-content: space-between; flex-wrap: wrap"
			>
				<WtCode
					style="width: 49%"
					:code="code"
				/>
				<WtCode
					style="width: 49%"
					:code="code"
					error="Please add the following JavaScript code to the header of your website or share instructions with your colleague"
				/>
			</div>
		</div>
		<div class="components__item">
			<h3 class="components__title"> Popup </h3>
			<div class="components__row">
				<WtButton
					label="Script popup"
					@click="showScriptPopup"
				/>
				<Transition name="slide">
					<ScNotification
						v-if="scriptIsCopied"
						class="alert"
						type="success"
						@close="scriptIsCopied = false"
					>
						Script copied
					</ScNotification>
				</Transition>
				<Transition name="fade">
					<ScriptPopup
						v-if="code2"
						:script="code2"
						:validate-and-continue="asyncButton"
						code-error="some error"
						@cancel="code2 = null"
					/>
				</Transition>

				<WtButton
					label="Sign in popup"
					@click="isShowSignInPopup = true"
				/>

				<Transition name="fade">
					<SignInPopup v-if="isShowSignInPopup" />
				</Transition>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import WtBadge from '@/components/widgets/wt-badge/wt-badge.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import WtSmartwords from '@/components/widgets/wt-smartwords/wt-smartwords.vue';
	import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
	import WtTitle from '@/components/widgets/wt-title/wt-title.vue';
	import WtText from '@/components/widgets/wt-text/wt-text.vue';
	import WtCode from '@/components/widgets/wt-code/wt-code.vue';
	import { ScNotification } from '@smartcat/design-system-vue2';
	import ScriptPopup from '@/components/widgets/script-popup/script-popup.vue';
	import SignInPopup from '@/components/widgets/sign-in-popup/sign-in-popup.vue';

	@Component({
		components: {
			WtBadge,
			WtButton,
			WtSmartwords,
			WtPopup,
			WtTitle,
			WtText,
			WtCode,
			ScNotification,
			ScriptPopup,
			SignInPopup,
		},
	})
	export default class ComponentsPage extends Vue {
		public isTestSpin = false;
		public isTestButton = false;
		public isShowScriptPopup = false;

		public isScriptApplyDisabled = false;
		public scriptIsCopied = false;

		public code =
			'askl jdfasfgasdkgf asdfgasodifgasudfygasdkjhf akjsdfagjksgdfj asjdkhfasjkhfgasd fahsgf jhaksgfdkjasgdfjkashdjgfajskhg fjadhsgf jashgdfjkhasdgfj jhasdgf ahsgfhagfiyueqwfguiasyfbjksahdfvg8qyewgibfhausyfgfgsadiyufgasuyfgsadufags';

		public code2: string = null;

		public isShowSignInPopup = false;

		public testSpin() {
			this.isTestSpin = true;
			setTimeout(() => (this.isTestSpin = false), 1500);
		}

		public testButton() {
			this.isTestButton = true;
			setTimeout(() => (this.isTestButton = false), 1500);
		}

		public showScriptPopup() {
			this.code2 =
				'askl jdfasfgasdkgf asdfgasodifgasudfygasdkjhf akjsdfagjksgdfj asjdkhfasjkhfgasd fahsgf jhaksgfdkjasgdfjkashdjgfajskhg fjadhsgf jashgdfjkhasdgfj jhasdgf ahsgfhagfiyueqwfguiasyfbjksahdfvg8qyewgibfhausyfgfgsadiyufgasuyfgsadufags';
		}

		public async asyncButton() {
			try {
				await this.asyncFunc();
			} catch (e) {
				console.error(e);
			}
		}

		public asyncFunc() {
			return new Promise((resolve) => {
				setTimeout(resolve, 1000);
			});
		}

		public async copyToClipboard() {
			await navigator.clipboard.writeText(this.code);
			this.$emit('copied');
			this.isScriptApplyDisabled = false;
			this.scriptIsCopied = true;
		}
	}
</script>

<style lang="less">
	#app {
		width: 100%;
	}

	.components {
		font-family: 'Inter', sans-serif;
		display: flex;
		flex-direction: column;
		width: 1115px;
		margin: 0 auto;
		padding: 20px;

		&__item {
			margin-right: 30px;
			border-bottom: 1px solid #e3e3e3;
			padding-right: 30px;
		}

		&__title {
			font-size: 22px;
			margin-top: 20px;
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 20px 0;
		}

		hr {
			height: 1px;
			width: 100%;
			background: #e3e3e3;
			margin: 10px 0 0 0;
		}

		.wt-badge,
		.wt-button {
			margin: 10px;
		}
	}

	.alert {
		position: absolute;
		left: 10px;
		bottom: 0;
		z-index: 999999999;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: 0.3s all ease;
	}

	.fade-enter,
	.fade-leave-active {
		opacity: 0;
	}
</style>
