<template>
	<label :class="classes">
		<input
			ref="input"
			class="sc-radio__input"
			type="radio"
			:name="name"
			:value="value"
			:disabled="disabled"
			:checked="checked"
			@input="checkRadio"
		/>
		<span class="sc-radio__label">
			<slot />
		</span>
	</label>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component({})
	export default class WtRadio extends Vue {
		@Prop({ type: String })
		public readonly name: string;

		@Prop({ type: String })
		public readonly value: string;

		@Prop({ type: [Boolean] })
		public readonly checked: boolean;

		@Prop({ type: Boolean, default: false })
		public readonly readonly: boolean;

		@Prop({ type: Boolean, default: false })
		public readonly disabled: boolean;

		public get classes(): Record<string, boolean> {
			return {
				'sc-radio': true,
				'sc-radio_checked': this.checked,
				'sc-radio_readonly': this.readonly,
				'sc-radio_disabled': this.disabled,
			};
		}

		public checkRadio(): void {
			this.$emit('input', this.value);
		}
	}
</script>

<style lang="less">
	@import '~@smartcat/design-system-vue2/colors';

	.sc-radio {
		display: inline-block;
		position: relative;
		padding-left: 2.6rem;
		min-height: 2rem;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 2rem;
			height: 2rem;
			box-shadow: 0 0 0 2px @mulberry-purple-40 inset;
			border-radius: 50%;
			transition: box-shadow 0.1s;
		}

		&:hover {
			&:before {
				box-shadow: 0 0 0 2px @mulberry-purple inset;
			}
		}

		&.sc-radio_checked {
			&:before {
				box-shadow: 0 0 0 6px @fuchsia-blue inset;
			}

			&:hover {
				&:before {
					box-shadow: 0 0 0 6px @fuchsia-blue-60 inset;
				}
			}
		}

		.sc-radio__input {
			border: 0;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
			outline: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
		}

		.sc-radio__label {
			display: inline-block;
			height: 100%;
			min-height: 2rem;
			text-align: left;
		}

		&.sc-radio_readonly {
			pointer-events: none;
		}

		&.sc-radio_centered:before {
			top: 50%;
			transform: translateY(-50%);
		}

		&.sc-radio_disabled {
			&:before {
				box-shadow: 0 0 0 2px @mulberry-purple-30 inset;
			}

			&.sc-radio_checked {
				&:before {
					box-shadow: 0 0 0 6px @fuchsia-blue-30 inset;
				}
			}
		}
	}
</style>
