<template>
	<WtPopup
		class="crawler-popup"
		data-testid="crawler-popup"
		:form-width="600"
		:close-button="false"
		@close="onCancel"
	>
		<template #body>
			<div class="popup-title"> Crawl website </div>
			<div class="popup-content">
				<ScLabel
					class="popup-field"
					caption="URLs to crawl (optional, supports wildcards *)"
				>
					<ScTextarea
						data-testid="urls-to-crawl"
						:placeholder="'Example: ' + rootUrl"
						size="88"
						:model-value="pathRulesStr"
						@update:modelValue="pathRulesStr = $event"
					/>
				</ScLabel>
				<details class="sc-details" data-testid="details">
					<summary>
						<ScText>Advanced options</ScText>
					</summary>
					<ScLabel
						class="popup-field"
						caption="Target languages *"
					>
						<ScCheckbox
							data-testid="target-languages"
							v-for="lang in targetLanguages"
							:modelValue="selectedLanguages.includes(lang)"
							@update:modelValue="setLangSelected(lang, $event)"
							:key="lang"
						>{{ languageMap.get(lang).englishName }}</ScCheckbox
						>
					</ScLabel>
					<ScLabel
						class="popup-field"
						caption="Cleanup options"
					>
						<ScCheckbox
							data-testid="cleanup-options"
							:modelValue="clearTranslationMode === ClearTranslationsMode.OnlyMissing"
							@update:modelValue="setClearTranslationsMode(ClearTranslationsMode.OnlyMissing, $event)"
						>
							Delete translations for elements that are no longer on page
						</ScCheckbox>
					</ScLabel>
				</details>
			</div>
		</template>
		<template #buttons>
			<ScButton
				data-testid="cancel-button"
				view="simple"
				size="36"
				@click="onCancel"
				>Cancel</ScButton
			>
			<ScButton
				data-testid="start-crawling-button"
				view="cta-black"
				size="36"
				@click="onStart"
				:disabled="selectedLanguages.length === 0"
				>Start crawling</ScButton
			>
		</template>
	</WtPopup>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ScButton, ScCheckbox, ScLabel, ScTextarea, ScLegend, ScText } from '@smartcat/design-system-vue2';
	import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
	import { ClearTranslationsMode } from '@/shared';
	import { getLanguageMap } from '@/resources/languages';

	export interface ICrawlOptions {
		pathRulesToCrawl: string[];
		targetLanguages: string[];
		clearTranslationsMode: ClearTranslationsMode;
	}

	@Component({
		computed: {
			ClearTranslationsMode() {
				return ClearTranslationsMode;
			},
		},
		components: { WtPopup, ScButton, ScTextarea, ScCheckbox, ScLabel, ScLegend, ScText },
	})
	export default class CrawlerPopup extends Vue {
		@Prop()
		public rootUrl: string;

		@Prop({ default: [] })
		public targetLanguages: string[];

		public pathRulesStr = '';

		public selectedLanguages: string[] = [];
		public clearTranslationMode: ClearTranslationsMode = ClearTranslationsMode.None;

		public languageMap = getLanguageMap();

		public onStart() {
			const pathRulesToCrawl = this.pathRulesStr
				.split('\n')
				.flatMap((x) => x.split(','))
				.filter((x) => !!x);

			const options: ICrawlOptions = {
				pathRulesToCrawl,
				clearTranslationsMode: this.clearTranslationMode,
				targetLanguages: this.selectedLanguages
			};

			this.$emit('confirm', options);
		}

		public mounted() {
			this.selectedLanguages = [...this.targetLanguages];
		}

		public onCancel() {
			this.$emit('cancel');
		}

		public setLangSelected(lang: string, checked: boolean) {
			if (checked) {
				this.selectedLanguages.push(lang);
				return;
			}
			const index = this.selectedLanguages.indexOf(lang);

			if (index >= 0) {
				this.selectedLanguages.splice(index, 1);
			}
		}

		public setClearTranslationsMode(mode: ClearTranslationsMode, checked: boolean) {
			this.clearTranslationMode = checked ? mode : ClearTranslationsMode.None;
		}
	}
</script>

<style scoped lang="less">
	.popup-title {
		text-align: left;
		color: #231d33;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
	}

	.popup-content {
		margin: 20px 0 20px;

		& .popup-field:nth-child(n + 1) {
			margin-top: 15px;
		}
	}

	.sc-details {
		margin-top: 15px;

		summary {
			cursor: pointer;

			&:hover {
				opacity: 0.9;
			}
		}
	}
</style>
