<template>
	<div :class="classes">
		<input
			type="checkbox"
			name="wt-checkbox"
			class="wt-checkbox__checkbox"
			:checked="value"
			@change="$emit('input', $event.target.checked)"
		/>
		<div
			class="wt-checkbox__box"
			@click="$emit('input', !value)"
		>
			<svg
				width="10"
				height="8"
				viewBox="0 0 10 8"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M3.75 7.5C3.4375 7.5 3.125 7.375 2.875 7.125L0.375 4.625C-0.125 4.125 -0.125 3.375 0.375 2.875C0.875 2.375 1.6875 2.375 2.125 2.875L3.75 4.5L7.875 0.375C8.375 -0.125 9.125 -0.125 9.625 0.375C10.125 0.875 10.125 1.625 9.625 2.125L4.625 7.125C4.375 7.375 4.0625 7.5 3.75 7.5Z"
					fill="white"
				/>
			</svg>
		</div>
		<label
			class="wt-checkbox__label"
			@click="$emit('input', !value)"
		>
			<slot />
		</label>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component({})
	export default class WtCheckbox extends Vue {
		@Prop({ type: Boolean, default: false })
		public value: boolean;

		public get classes(): Record<string, boolean> {
			return {
				'wt-checkbox': true,
				'wt-checkbox_checked': this.value,
			};
		}
	}
</script>

<style lang="less" scoped>
	@import '~@smartcat/design-system-vue2/colors';

	.wt-checkbox {
		display: flex;
		align-items: center;

		input {
			display: none;
		}

		&.wt-checkbox_checked {
			.wt-checkbox__box {
				border: 2px solid @fuchsia-blue;
				background: @fuchsia-blue;

				svg {
					display: block;
				}
			}
		}
	}

	.wt-checkbox__box {
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		width: 20px;
		height: 20px;
		border: 2px solid @mulberry-purple-30;
		border-radius: 4px;
		// padding: 5px;
		cursor: pointer;

		svg {
			display: none;
		}
	}

	.wt-checkbox__label {
		margin-left: 10px;
		cursor: pointer;
	}
</style>
