<template>
	<div
		:class="classes"
		@click="togglePopover"
		ref="popoverContainer"
	>
		<div ref="activator">
			<slot name="activator"/>
		</div>
		<div
			v-if="isPopoverShown"
			ref="popover"
			class="sc-popover__box"
		>
			<slot/>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { onClickOutside } from '@/utils/click-outside';

	@Component({})
	export default class WTPopover extends Vue {
		@Prop({
			type: String,
			default: 'medium',
			validator: (value: string) => ['medium', 'small'].includes(value),
		})
		public readonly size: 'medium' | 'small';

		@Prop({
			type: String,
			default: 'top left',
			validator: (value: string) => [
				'top left',
				'top center',
				'top right',
				'bottom left',
				'bottom center',
				'bottom right',
			].includes(value),
		})
		public readonly position: string;

		@Prop({ type: Boolean, default: false })
		public readonly disabled: boolean;

		@Prop({ type: Number, default: 20 })
		public readonly verticalOffset: number;

		public isPopoverShown = false;

		private _clickOutsideUnsub: any = null;

		public get classes(): Record<string, boolean> {
			return  {
				'sc-popover': true,
				'sc-popover_opened': this.isPopoverShown,
				'sc-popover_disabled': this.disabled,
				[`sc-popover_size-${this.size}`]: true
			};
		}

		public mounted() {
			this._clickOutsideUnsub = onClickOutside(
				document.body,
				() => this.$refs.popoverContainer as any,
				() => {
					this.isPopoverShown = false;
				}
			);
		}

		public beforeDestroy() {
			this._clickOutsideUnsub?.();
		}

		public togglePopover(): void {
			this.isPopoverShown ? this.hidePopover() : this.showPopover();
		}

		public showPopover(): void {
			if (!this.disabled) {
				this.isPopoverShown = true;
				this.$nextTick(() => {
					this.setPopoverPosition();
				});
			}
		}

		public hidePopover(): void {
			if (!this.disabled) {
				this.isPopoverShown = false;
			}
		}

		private setPopoverPosition(): void {
			const [positionY, positionX] = this.position.split(' ');

			const popover = this.$refs.popover as HTMLDivElement;
			const activator = this.$refs.activator as HTMLDivElement;

			const popoverRect = popover.getBoundingClientRect();
			const activatorRect = activator.getBoundingClientRect();
			if (positionY !== 'top') {
				popover.style.marginTop = this.verticalOffset + 'px';
			} else {
				popover.style.marginTop = (popoverRect.height + activatorRect.height + this.verticalOffset) * (-1) + 'px';
			}

			if (positionX === 'center') {
				popover.style.marginLeft = (activatorRect.width - popoverRect.width) / 2 + 'px';
			} else if (positionX === 'right') {
				popover.style.marginLeft = activatorRect.width - popoverRect.width + 'px';
			}
		}
	}
</script>

<style lang="less" scoped>
@import '@smartcat/design-system-vue2/colors';

.sc-popover {
	position: relative;
	display: inline-block;
	cursor: pointer;
	user-select: none;

	.sc-text {
		color: white;
	}

	&.sc-popover_opened {
		.sc-popover__box {
			visibility: visible;
			opacity: 1;
		}
	}

	&.sc-popover_disabled {
		cursor: unset;
	}
}

.sc-popover__box {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: max-content;
	position: absolute;
	padding: 8px;
	background: @mulberry-purple;
	box-shadow: 0 2px 20px 0 #453B5E33;
	border-radius: 6px;
	visibility: hidden;
	opacity: 0;
	z-index: 17;
	left: 0;
	max-width: 80rem;
	top: 100%;
	margin-top: 0;
}

.sc-popover_size-medium {
	.sc-popover__box {
		padding: 2rem;
		min-width: 25.6rem;
	}
}

.sc-popover_size-small {
	.sc-popover__box {
		padding: 0.8rem 1.2rem;
		max-width: 40rem;
	}
}
</style>
