<template>
	<div>
		<ConfirmPopup
			v-for="confirm in confirmations"
			v-bind:key="confirm.message"
			:message="confirm.message"
			:description="confirm.description"
			:button-caption="confirm.buttonCaption"
			@click-confirm="confirm.resolve(true)"
			@close="confirm.resolve(false)"
		 />
	</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { confirmationContainer } from "@/components/widgets/confirm-popup/show-confirm";
import ConfirmPopup from "@/components/widgets/confirm-popup/confirm-popup.vue";


@Component({ components: { ConfirmPopup } })
export default class ConfirmationsContainer extends Vue {
	public get confirmations() {
		return confirmationContainer.items;
	}
}
</script>

