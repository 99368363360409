<template>
	<section
		id="how"
		class="how"
	>
		<div class="container">
			<h2 class="how-title"> How It Works </h2>
			<div class="how-list">
				<div class="how-list__item">
					<img
						src="../../assets/images/landing/preview-your-website.svg"
						alt="Image"
						class="how-list__item--image"
					/>
					<p class="how-list__item--text"> Preview your website translation in seconds </p>
				</div>
				<div class="how-list__item">
					<img
						src="../../assets/images/landing/select-which-languages.svg"
						alt="Image"
						class="how-list__item--image"
					/>
					<p class="how-list__item--text"> Select which languages and pages you want to translate </p>
				</div>
				<div class="how-list__item">
					<img
						src="../../assets/images/landing/edit-selected-pages.svg"
						alt="Image"
						class="how-list__item--image"
					/>
					<p class="how-list__item--text"> Edit selected pages by yourself or with linguists </p>
				</div>
				<div class="how-list__item">
					<img
						src="../../assets/images/landing/instantly-preview.svg"
						alt="Image"
						class="how-list__item--image"
					/>
					<p class="how-list__item--text"> Instantly preview the edits on your website </p>
				</div>
				<div class="how-list__item">
					<img
						src="../../assets/images/landing/embed-simple-code.svg"
						alt="Image"
						class="how-list__item--image"
					/>
					<p class="how-list__item--text"> Embed a simple javascript code in your website to push it live </p>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';

	@Component({
		components: {},
	})
	export default class LandingHowSection extends Vue {}
</script>

<style lang="less" src="./landing-how-section.less" scoped></style>
