<template>
	<div class="edit-dropdown-item"
		 @click="$emit('click', $event)"
	>
		{{ title }}
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import WtBadge from '@/components/widgets/wt-badge/wt-badge.vue';

	@Component({
		components: { WtBadge },
	})
	export default class StatsPopoverItem extends Vue {
		@Prop({ required: true })
		public title: string;
	}
</script>

<style lang="less" scoped>
@import "@smartcat/design-system-vue2/colors";

.edit-dropdown-item {
	color: @mulberry-purple;
	font-size: 14px;
	font-weight: normal;
	cursor: pointer;
	padding: 10px 18px;

	&:hover {
		background: @mulberry-purple-20;
	}
}
</style>
