/* eslint-disable comma-dangle */
import { Event } from '@/shared/event';
import { FrameLangChangeData, UserNavigatedEventData, UserTranslatedEventData } from '@/services/dto/frame-events';
import { FrameProxyProvider } from '@/services/logic/frame-proxy-provider';
import { addMessageListener } from '@/shared';
import Vue from 'vue';
import { LangSelectorConfig } from '@/script/lang-selector/lang-selector-config';

export class TranslatedFrameService {
	public get isLoaded() {
		return this._frameProxyProvider.isLoaded;
	}

	public isProxyTranslating = false;
	public translationStatusUpdated = new Event<UserTranslatedEventData>();
	public userNavigated = new Event<UserNavigatedEventData>();
	public frameInitialized = new Event<any>();
	public frameLoaded = new Event<any>();
	public frameLangChange = new Event<FrameLangChangeData>();
	public userLinkClick = new Event<UserNavigatedEventData>();
	public smartwordsRequired = new Event<void>();
	public pageLimitExceeded = new Event<any>();

	private readonly _frame: HTMLIFrameElement;
	private readonly _frameProxyProvider: FrameProxyProvider;

	constructor(frame: HTMLIFrameElement) {
		this._frame = frame;
		this._frameProxyProvider = new FrameProxyProvider(this._frame);
		Vue.observable(this);
	}

	init(frameUrl: string) {
		this._frame.src = frameUrl;
		this._frameProxyProvider.init(async (proxy) => {
			const location = await proxy.getLocation();
			const canonicalUrl = await proxy.getCanonicalUrl();
			this.frameInitialized.trigger({});
			this.userNavigated.trigger({
				url: location,
				canonicalUrl,
			});
		});

		addMessageListener((data: any) => {
			const { type, ...payload } = data;
			if (type) {
				this.processEvent(type, payload);
			}
		});
	}

	async getCanonicalUrl() {
		const p = await this._frameProxyProvider.waitProxy();
		return  await p.getCanonicalUrl();
	}

	async getLocation() {
		const p = await this._frameProxyProvider.waitProxy();
		return await p.getLocation();
	}

	load(frameUrl: string) {
		this._frame.src = frameUrl;
	}

	reload() {
		this._frameProxyProvider.waitProxy().then((p) => {
			p.reload().catch(console.error);
		});
	}

	public async restartScript() {
		const p = await this._frameProxyProvider.waitProxy();
		await p.restartScript();
	}

	public async enableLinksRouting() {
		const p = await this._frameProxyProvider.waitProxy();
		await p.enableLinksRouting();
	}

	public async getFragments() {
		const p = await this._frameProxyProvider.waitProxy();
		return await p.getFragments();
	}

	public async getFragmentsThatRequireMoreSmartwords() {
		const p = await this._frameProxyProvider.waitProxy();
		return await p.getFragmentsThatRequireMoreSmartwords();
	}

	public async blockLinks() {
		const p = await this._frameProxyProvider.waitProxy();
		await p.blockLinks();
	}

	public async getStats() {
		const p = await this._frameProxyProvider.waitProxy();
		return p.getTranslationStats();
	}

	public async isTranslationStarted() {
		const p = await this._frameProxyProvider.waitProxy();
		return await p.isTranslationStarted();
	}

	public async updateLangSelectorConfig(config: Partial<LangSelectorConfig>) {
		const p = await this._frameProxyProvider.waitProxy();
		await p.updateLangSelectorConfig(config);
	}

	public navigate(url: string) {
		this._frame.src = url;
	}

	public async beginTranslation() {
		const proxy = await this._frameProxyProvider.waitProxy();
		await proxy.enableNewElementsTranslation();
		await proxy.translate();
	}

	public processEvent(type: string, payload: any) {
		if (type === 'sc-proxy-not-enough-smartwords') {
			this.smartwordsRequired.trigger();
		} else if (type === 'sc-proxy-change-locale') {
			this.frameLangChange.trigger({ locale: payload.locale });
		} else if (type === 'sc-proxy-loaded') {
			this.frameLoaded.trigger(null);
		} else if (type === 'sc-proxy-link-click') {
			this.userLinkClick.trigger(payload);
		} else if (type === 'sc-proxy-translation-updated') {
			this.translationStatusUpdated.trigger(payload);
		} else if (type === 'sc-proxy-smartwords-required') {
			this.smartwordsRequired.trigger(payload);
		}
	}
}
