<template>
	<div class="wt-icon">
		<svg style="display: none">
			<symbol
				v-for="(icon, key) in icons"
				:id="`wt-icon_${icon.name}`"
				:key="key"
			>
				<path
					:d="icon.d"
					:stroke-width="icon.strokeWidth || false"
					:stroke-linecap="icon.strokeLinecap || false"
					:stroke-linejoin="icon.strokeLinejoin || false"
				/>
			</symbol>
		</svg>

		<svg
			class="wt-icon__svg"
			:fill="!getIcon.stroke ? iconColor : 'rgba(0,0,0,0)'"
			:stroke="getIcon.stroke ? iconColor : 'rgba(0,0,0,0)'"
			:width="size || wight"
			:height="size || height"
			:viewBox="getViewBox"
			image-rendering="optimizeQuality"
			shape-rendering="geometricPrecision"
		>
			<use :xlink:href="'#wt-icon_' + name" />
		</svg>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { Icon, icons } from '@/components/widgets/wt-icon/icons';

	@Component
	export default class WtIcon extends Vue {
		@Prop({ type: String, default: '' })
		public readonly name: string;

		@Prop({ type: Boolean, default: false })
		public readonly disabled: boolean;

		@Prop({ type: String, default: 'inherit' })
		public readonly color: string;

		@Prop({ type: String, default: '0 0 14 14' })
		public readonly viewBox: string;

		@Prop({ type: Number, default: 14 })
		public readonly height: number;

		@Prop({ type: Number, default: 14 })
		public readonly wight: number;

		@Prop({ type: Number, default: null })
		public readonly size: number;

		private readonly colors: any = {
			inherit: {
				current: '#6b6679',
				disabled: '#ABA7B3',
			},
			green: {
				current: '#226735',
				disabled: '#98DDAA',
			},
		};

		public readonly icons: Icon[] = icons;

		public get iconColor(): string {
			if (this.colors[this.color]) {
				return this.disabled ? this.colors[this.color].disabled : this.colors[this.color].current;
			} else {
				return this.color;
			}
		}

		public get getIcon() {
			return this.icons.filter((icon) => icon.name === this.name).shift();
		}

		public get getViewBox() {
			return this.size !== null ? `0 0 ${this.size} ${this.size}` : this.viewBox;
		}
	}
</script>

<style lang="less" scoped>
	.wt-icon {
		display: flex;
		align-items: center;
	}
</style>
