export function throttle(func: any, timeout = 300) {
	let timer: any;

	return (...args: any) => {
		if (timer) {
			return;
		}

		func(...args);

		timer = setTimeout(() => {
			clearTimeout(timer);
			timer = null;
		}, timeout);
	};
}

export function debounce(func: any, timeout = 300) {
	let timer: any;

	return (...args: any) => {
		if (timer) {
			clearTimeout(timer);
			timer = null;
		}

		timer = setTimeout(() => {
			func(...args);
			timer = null;
		}, timeout);
	};
}

export function debounceWithCancellation(func: any, timeout = 300): [any, () => void] {
	let timer: any;

	const cancelFunc = () => {
		if (timer) {
			clearTimeout(timer);
			timer = null;
		}
	};

	const wrappedFunc = (...args: any) => {
		cancelFunc();

		timer = setTimeout(() => {
			func(...args);
			timer = null;
		}, timeout);
	};

	return [wrappedFunc, cancelFunc];
}

export function asyncDebounce(func: (...args: any) => Promise<any>, timeout: number) {
	let timer: any;
	let activePromise: Promise<any> | null;

	return async (...args: any) => {
		if (activePromise) {
			await activePromise;
			activePromise = null;
		}

		if (timer) {
			clearTimeout(timer);
			timer = null;
		}

		timer = setTimeout(async () => {
			activePromise = func(...args);
			timer = null;
		}, timeout);
	};
}
