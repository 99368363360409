import languages from '@/resources/languages.json';
import { ILanguageModel } from '@/components/widgets/languages/language-client';

export function defaultLanguages(): ILanguageModel[] {
	return languages;
}

export function getLanguageMap() {
	return new Map<string, ILanguageModel>(languages.map(x => [x.cultureName, x]));
}

export function findLanguageByLocale(locale: string) {
	return languages.find(x => x.cultureName == locale);
}
