import { sanitize } from '@/script/utils/sanitize';
import { kebabCase } from '@/script/utils/kebab-case';

export abstract class ShopifyBaseSection {
	constructor(protected el: Element, protected url: string, protected knownWords = new Set<string>()) {
	}

	protected isAncestorOrItemMatchSelector(selector: string) {
		return (
			!!this.el.closest(selector) || this.el.getAttribute('id')?.includes(selector)
		);
	}

	protected isHeader() {
		return this.isAncestorOrItemMatchSelector('header');
	}

	protected isFooter() {
		return this.isAncestorOrItemMatchSelector('footer');
	}

	protected isShoppingCart() {
	}

	protected isProductInCart() {
	}

	protected isCollectionsFilter() {
	}

	protected isCollectionsSorting() {
	}

	protected isProductCard() {
	}

	protected isCollections() {
		const urlObj = new URL(this.url);
		return urlObj.pathname.includes('collections');
	}

	protected getCollectionsSection() {
		return 'collections-page';
	}


	protected isProductsPage() {
		const urlObj = new URL(this.url);
		return urlObj.pathname.includes('products/');
	}

	protected getProductSection() {
		const urlObj = new URL(this.url);
		if (this.isAncestorOrItemMatchSelector('product__title')) {
			const productName = urlObj.pathname.split('products/')[1];
			if (productName) {
				return 'dynamic/product/' + productName;
			}
		}
		return 'product-page';
	}

	protected getProductInCartSection(selector: string) {
		const upsellItem = this.el.closest(selector);
		const link = upsellItem?.querySelector('a');
		if (link && link.href !== 'javascript:void(0)') {
			const linkObj = new URL(link.href);
			return this.urlify(linkObj.pathname);
		}
		return 'product'
	}

	protected getProductCardSectionBySelector(selector: string) {
		const recProductItem = this.el.closest(selector);
		const link = recProductItem?.querySelector('a') || recProductItem?.closest('a');
		if (link && link.href !== 'javascript:void(0)') {
			const linkObj = new URL(link.href);
			return this.urlify(linkObj.pathname);
		}
	}

	protected getProductCardSectionByAttribute(selector: string, attribute: string) {
		const recProductItem = this.el.closest(selector);
		const datasetLink = recProductItem?.getAttribute(attribute)
		if (datasetLink) {
			return this.urlify(datasetLink);
		}
	}

	protected getShoppingCartSection(selector: string) {
		const cartItem = this.el.closest(selector);
		const link = cartItem?.querySelector('a')
		if (link && link.href !== 'javascript:void(0)') {
			const linkObj = new URL(link.href);
			return this.urlify(linkObj.pathname);
		}
		return 'cart';
	}

	protected isBlogsPage() {
		const urlObj = new URL(this.url);
		return urlObj.pathname.includes('blogs');
	}

	public getSection(): string {
		return ''
	}

	protected isCheckouts() {
		const urlObj = new URL(this.url);
		return urlObj.pathname.includes('checkouts');
	}

	protected getCheckoutSection() {
		if (this.isAncestorOrItemMatchSelector('aside') && this.isAncestorOrItemMatchSelector('span')) {
			if (this.el.textContent) {
				const kebabCaseText = kebabCase(this.el.textContent);
				if (this.knownWords.has(kebabCaseText) || kebabCaseText.includes('subtotal')) {
					return 'checkout-page';
				}
				return 'dynamic/checkout/' + kebabCaseText;
			}
		}
		return 'checkout-page';
	}

	protected urlify(path: string) {
		return 'dynamic/product/' + sanitize(path.replace('products/', ''));
	}
}
