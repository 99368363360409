import Vue from 'vue';
import App from './app.vue';
import router from './router';
import { init } from '@/init';
import { createI18n } from '@/components/common/i18n-utils';
import VueI18n from 'vue-i18n';
import Teleport from 'vue2-teleport';
import * as Sentry from "@sentry/vue";
import '@smartcat/design-system-vue2/style.css';

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.component('Teleport', Teleport);

if (process.env.VUE_APP_VERSION) {
	Sentry.init({
		Vue,
		dsn: "https://6afe7e818ec594a21e078079e4edc691@o1126729.ingest.sentry.io/4506773537816577",

		release: process.env.VUE_APP_VERSION,

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		// tracePropagationTargets: [/^https:\/\/translator((-us)|(-ea))?\.smartcat\.com\/api/],
	});
}

(async () => {
	await init();
	new Vue({
		i18n: createI18n(),
		router,
		render: (h: any) => h(App),
	} as any).$mount('#app');
})();
