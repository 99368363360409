<template>
	<div class="wt-switch">
		<ScSwitch
			:readonly="readonly"
			:disabled="disabled"
			:value="value"
			@input="$emit('input', $event)"
		/>
		<span
			class="wt-switch__label"
			@click="onLabelClick"
		>
			<slot />
		</span>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ScSwitch } from '@smartcat/design-system-vue2';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';

	@Component({
		components: {
			WtButton,
			ScSwitch,
		},
	})
	export default class WtSwitch extends Vue {
		@Prop({ type: Boolean })
		public value: boolean;

		@Prop({ type: Boolean, default: false })
		public readonly readonly: boolean;

		@Prop({ type: Boolean, default: false })
		public readonly disabled: boolean;

		onLabelClick() {
			if (!this.readonly && !this.disabled) {
				this.$emit('input', !this.value);
			}
		}
	}
</script>

<style lang="less" scoped>
	.wt-switch {
		display: flex;
		align-items: center;
	}

	.wt-switch__label {
		margin-left: 5px;
		cursor: pointer;
		user-select: none;
	}
</style>
