import { Component, Vue } from 'vue-property-decorator';

@Component
export default class extends Vue {
	public validate: any;

	public useParentValidation: boolean;

	public get dirty() {
		return this.validate != null && this.validate.$dirty;
	}

	public set dirty(value: boolean) {
		if (this.validate != null && value) {
			this.validate.$touch();
		}
	}

	public get hasError() {
		return this.dirty && this.validate.$invalid;
	}
}
