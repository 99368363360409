<template>
	<section class="landing__main">
		<div class="manage__header">
			<img
				src="../../assets/images/smartcat-logo-color.svg"
				class="manage__header--logo"
				alt="Smartcat"
			/>
			<img
				src="../../assets/images/website-translator-logo.svg"
				alt="Smartcat"
			/>
		</div>
		<div class="landing__main-container">
			<div class="manage">
				<h1 class="manage__title"> Translate your website into multiple languages in minutes </h1>
				<p class="manage__text">
					Simple to use, works with any CMS and combines automatic translation with human review for
					unparalleled speed and quality
				</p>
				<form
					class="manage__container"
					@submit.prevent="createDemoWebSite"
				>
					<div class="manage__site-url">
						<p class="manage__site-url-header manage-label"> I want to translate my website: </p>
						<ScInput
							:modelValue="url"
							@change="url = $event.target.value"
							type="text"
							size="44"
							:error="invalidFields.includes('url')"
							placeholder="https://www.my-awesome-website.com"
							fluent-outline
							@blur="onUrlBlur"
						/>
					</div>
					<div class="manage__languages">
						<div class="languages">
							<div class="languages__source">
								<p class="languages__source-header manage-label"> from: </p>
								<SourceLanguageSelector
									v-model="sourceLanguageId"
									@input="sourceLanguageId = $event"
									class="languages__source-input"
									:error="invalidFields.includes('source')"
									:target-language-ids="targetLanguageIds"
								/>
							</div>
							<div class="languages__separator">
								<ScIcon name="arrow-right" size="24" />
							</div>
							<div class="languages__targets">
								<p class="languages__targets-header manage-label"> into: </p>
								<TargetLanguageSelector
									v-model="targetLanguageIds"
									@input="targetLanguageIds = $event"
									class="languages__targets-input"
									:error="invalidFields.includes('target')"
									:source-language-id="sourceLanguageId"
								/>
							</div>
						</div>
					</div>
					<div class="manage__buttons">
						<ScButton
							class="manage__submit"
							view="cta-black"
							:has-loader="createDemoLoader"
							type="submit"
							size="44"
						>
							Preview my website
						</ScButton>
					</div>
				</form>
			</div>
		</div>
		<Transition name="slide-top">
			<div
				v-if="isShowFooter"
				class="landing__main-footer"
			>
				<p class="landing__main-footer__text"> Why you need a multilingual website </p>
				<RouterLink
					to="#why"
					class="landing__main-footer__mouse"
				>
					<img
						src="../../assets/icons/landing-mouse.svg"
						alt="Mouse"
					/>
				</RouterLink>
			</div>
		</Transition>
		<Transition name="slide">
			<ScNotification
				v-if="isShowAlert"
				type="alert"
				class="alert"
				@close="isShowAlert = false"
			>
				{{ alertText }}
			</ScNotification>
		</Transition>
	</section>
</template>

<script lang="ts">
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import SourceLanguageSelector from '@/components/widgets/languages/source-selector.vue';
	import TargetLanguageSelector from '@/components/widgets/languages/target-selector.vue';
	import { ScButton, ScInput, ScNotification, ScIcon } from '@smartcat/design-system-vue2';
	import { inject } from '@/components/common/di';
	import { apiClient } from '@/shared';
	import { LanguagesStore } from '@/components/widgets/languages/language-store';
	import { Roles, validator } from '@/utils/validator';
	import { MixpanelService } from '@/services/mixpanel-service';

	@Component({
		components: { SourceLanguageSelector, TargetLanguageSelector, ScButton, ScIcon, ScInput, ScNotification },
	})
	export default class LandingMainSection extends Vue {
		@Prop({ type: Boolean, default: true })
		public readonly isShowFooter: boolean;

		public readonly mixpanelService = inject(MixpanelService);

		@Watch('url')
		urlChanged(newVal: string) {
			if (newVal.indexOf('http://') !== 0 && newVal.indexOf('https://') !== 0) {
				this.urlErrorMessage = newVal.length > 0 ? 'Use http(s) in the site address' : '';
			} else {
				this.urlErrorMessage = '';
			}
		}

		public invalidFields: string[] = [];

		public isShowAlert = false;
		public alertText = '';
		public url = '';
		public sourceLanguageId: number = null;
		public targetLanguageIds: number[] = [];
		public createDemoLoader = false;
		public urlErrorMessage = '';

		public onUrlBlur() {
			if (this.url) {
				this.url = this.url.trim();

				if (!this.url.match(/^https?:/)) {
					this.url = 'https://' + this.url;
				}
			}
		}

		public async createDemoWebSite() {
			this.createDemoLoader = true;
			this.invalidFields = [];

			this.invalidFields = validator([
				{ key: 'url', role: Roles.URL, value: this.url },
				{ key: 'source', role: Roles.Required, value: this.sourceLanguageId },
				{ key: 'target', role: Roles.ArrayNotEmpty, value: this.targetLanguageIds },
			]);

			if (this.invalidFields.length > 0) {
				this.createDemoLoader = false;
				this.isShowAlert = true;
				this.alertText = 'Field validation error';
				return;
			}

			try {
				const body = {
					rootUrl: this.url,
					sourceLanguage: this.languagesStore.getById(this.sourceLanguageId).cultureName,
					targetLanguages: this.targetLanguageIds.map((x) => this.languagesStore.getById(x).cultureName),
				};

				const response = await apiClient.createDemo(body);

				this.mixpanelService.trackDemoPreviewFormFilled(
					body.rootUrl,
					body.sourceLanguage,
					body.targetLanguages,
				);

				await this.$router.push({ name: 'DemoBrowser', params: { webSiteId: response.webSiteId } });
			} catch (e) {
				console.error(e);
				this.isShowAlert = true;
				this.alertText = 'Error creating demo';
			}
			this.createDemoLoader = false;
		}

		private readonly languagesStore = inject(LanguagesStore);
	}
</script>
<style lang="less" src="./landing-main-section.less" scoped />
