import { inject, registerDependencyInRootScope } from '@/components/common/di';
import { ILanguageModel, LanguagesClient } from '@/components/widgets/languages/language-client';
import { defaultLanguages } from '@/resources/languages';

export class LanguagesStore {
	public all: ILanguageModel[] = [];
	private languagesById: Map<number, ILanguageModel> = new Map<number, ILanguageModel>();
	private languagesByCultureName: Map<string, ILanguageModel> = new Map<string, ILanguageModel>();

	constructor(private languageApi = inject(LanguagesClient)) {}

	public getByCultureName(cultureName: string) {
		return this.languagesByCultureName.get(cultureName);
	}

	public get getById() {
		return (id: number) => {
			const language = this.languagesById.get(id);
			if (language == null) {
				throw new Error(`Unknown language ${id}`);
			}
			return language;
		};
	}

	public get allSortedByName() {
		return [...this.all].sort((a, b) =>
			a.englishName > b.englishName ? 1 : a.englishName < b.englishName ? -1 : 0,
		);
	}

	public async init() {
		// this.setLanguages(await this.languageApi.getAvailableLanguages());
		this.setLanguages(defaultLanguages());
		await Promise.resolve();
	}

	private setLanguages(languages: ILanguageModel[]) {
		this.all = languages;
		for (const language of this.all) {
			this.languagesById.set(language.id, language);
			this.languagesByCultureName.set(language.cultureName, language);
			// this.languagesById[language.id] = language;
		}
	}
}

registerDependencyInRootScope(LanguagesStore);
