import VueRouter from 'vue-router';
import { watch } from '@/components/browser/logic/observer';
import { Event } from '@/shared/event';
import { PageRouteEvent } from '@/components/browser/logic/page-route-event';

export class RouteMonitor {
	public routeChanged = new Event<PageRouteEvent>();
	private $router: VueRouter;
	private disableRouteListening = false;

	constructor($router: VueRouter) {
		this.$router = $router;
	}

	init() {
		watch(
			() => this.$router.currentRoute,
			() => {
				if (this.disableRouteListening) {
					return;
				}
				const url = this.$router.currentRoute.query.pageUrl as string;
				const lang = this.$router.currentRoute.query.scProxyLang as string;
				this.routeChanged.trigger({ lang, pageUrl: url });
			},
			{ immediate: true },
		);
	}

	updateCurrentRoute(pageUrl: string, lang: string) {
		const currentUrl = this.$router.currentRoute.query.pageUrl as any as string;
		const currentLang = this.$router.currentRoute.query.scProxyLang as any as string;
		if (currentUrl == pageUrl && currentLang == lang) {
			return;
		}
		this.disableRouteListening = true;
		this.$router.push({
			query: {
				pageUrl,
				scProxyLang: lang,
			},
		});
		setTimeout(() => {
			this.disableRouteListening = false;
		}, 50);
	}
}
