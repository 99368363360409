<template>
	<WtPopup :form-width="400"
			 :close-button="true"
			 @close="$emit('close')"
	>
		<template #body>
			<div style="padding-bottom: 20px">

			<div class="message">
				{{ message }}
			</div>
			<div class="description">
				{{ description }}
			</div>
			</div>
		</template>
		<template #buttons>
			<div class="buttons">
				<ScButton
					view="simple"
					@click="$emit('close')">
					Cancel
				</ScButton>
				<WtButton @click="$emit('click-confirm')" :label="buttonCaption">
				</WtButton>
			</div>

		</template>
	</WtPopup>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
import WtButton from '@/components/widgets/wt-button/wt-button.vue';
import { ScButton } from '@smartcat/design-system-vue2';

@Component({
	components: { ScButton, WtPopup, WtButton },
})
export default class ConfirmPopup extends Vue {
	@Prop({ required: true })
	public message: string;

	@Prop({ required: false })
	public description: string;

	@Prop({ required: true })
	public buttonCaption: string;
}
</script>
<style lang="less" scoped>
@import "../../../../node_modules/@smartcat/design-system-vue2/colors";

.message {
	padding: 10px 0px 10px 0px;
	font-size: 18px;
	color: @mulberry-purple-80;
	text-align: center;
}
.description{
	color: @mulberry-purple-40;
	text-align: center;
	padding: 0px 0px 10px 0px;


}
.buttons{
	display: flex;
	gap: 10px;
	align-items: center;
	align-content: center;
	width: 100%;
	flex: 1;
	justify-items: center;
	justify-content: center;
}
</style>
