import Vue, { CreateElement, VNode } from 'vue';
import { Component, Inject, Prop, Provide, Watch } from 'vue-property-decorator';
import { RawLocation, Route } from 'vue-router';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

export { Component, Inject, Prop, Provide, Watch, Vue };

export type { RawLocation, Route, VNode };

export type CreateVNode = CreateElement;
export type RouteNext = (to?: RawLocation | false | ((vm: Vue) => any) | void) => void;
