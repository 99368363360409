<template>
	<div id="app">
		<RouterView />
	</div>
</template>

<script>
	import Vue from 'vue';

	export default class app extends Vue {}
</script>
<style lang="less">
	@import 'assets/styles/null';
	@import 'assets/styles/root';
	@import 'assets/styles/mixins';
	@import 'assets/styles/variables';
	//@font-face {
	//  font-family: "smartcat-icons";
	//  src: url("./assets/fonts/smartcat-icons.eot?v=1537364661839");
	//  src: url("./assets/fonts/smartcat-icons.eot?#iefix&v=1537364661839") format("embedded-opentype"),
	//  url("./assets/fonts/smartcat-icons.woff2?v=1537364661839") format("woff2"),
	//  url("./assets/fonts/smartcat-icons.woff?v=1537364661839") format("woff"),
	//  url("./assets/fonts/smartcat-icons.ttf?v=1537364661839") format("truetype"),
	//  url("./assets/fonts/smartcat-icons.svg?v=1537364661839#smartcat-icons") format("svg");
	//  font-weight: normal;
	//  font-style: normal;
	//}

	#app {
		display: flex;
		color: #2c3e50;
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
</style>
