<template>
	<div
		ref="container"
		class="lang-selector-container"
	>
		<div class="tool-wrapper">
			<div class="lang-selector__button-group">
				<div>
					<button
						class="lang-selector__button"
						:class="{ active: showPanel, disabled: isLoading }"
						@click="togglePanel"
					>
						<span class="lang-selector__button__text">
							<WtIcon
								v-if="isLoading"
								name="spinner"
							/>
							<slot name="button-caption" />
						</span>
						<img
							:class="{ 'lang-selector__button__icon': true, 'active': showPanel }"
							src="../../../assets/icons/arrow-down.svg"
						/>
					</button>
				</div>
			</div>
		</div>
		<div>
			<div
				v-if="showPanel"
				id="panel"
				class="lang-selector__panel"
				@keydown="handleKeyNavigation($event)"
			>
				<div class="lang-selector__panel--search">
					<input
						id="search"
						v-model="searchField"
						type="text"
						placeholder="Search"
						class="lang-selector__panel--search"
						autocomplete="off"
						@focus="selectedLanguageId = null"
					/>
					<img
						src="../../../assets/icons/search.svg"
						alt="Search"
					/>
				</div>
				<div
					v-if="langListIsLoading"
					class="skeleton"
				>
					<div class="skeleton__title" />
					<div class="skeleton__language" />
					<div class="skeleton__title" />
					<div class="skeleton__language" />
					<div class="skeleton__language" />
					<div class="skeleton__language" />
					<div class="skeleton__language" />
					<div class="skeleton__language" />
				</div>

				<div
					v-if="!langListIsLoading"
					id="list"
					class="lang-selector__list"
				>
					<p class="lang-selector__panel--title"> Project languages </p>
					<div
						v-if="langsCount > 0"
						@keydown="handleKeyNavigation($event)"
					>
						<div
							v-for="lang in langsList"
							:id="`lang-${lang.id}`"
							:key="lang.id"
							class="lang-selector-list__item"
							:class="{ active: selectedLanguageId === lang.id }"
							@click="select(lang, $event)"
						>
							{{ lang.englishName }}
						</div>
					</div>
					<p
						v-if="langsCount === 0"
						class="lang-selector__panel--no-items"
					>
						No matches found
					</p>
					<p v-if="!disableLanguageAddition" class="lang-selector__panel--title"> Available languages </p>
					<div v-if="availableLangsCount > 0 && !disableLanguageAddition">
						<div
							v-for="lang in availableLangsList"
							:id="`lang-${lang.id}`"
							:key="lang.id"
							class="lang-selector-list__item"
							:class="{ active: selectedLanguageId === lang.id }"
							@click="selectAvailableLang(lang, $event)"
						>
							{{ lang.englishName }}
						</div>
					</div>
					<p
						v-if="availableLangsCount === 0"
						class="lang-selector__panel--no-items"
					>
						No matches found
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import langs from '@/resources/languages.json';
	import { ILanguageModel } from '@/components/widgets/languages/language-client';
	import { onClickOutside } from '@/utils/click-outside';
	import WtIcon from '@/components/widgets/wt-icon/wt-icon.vue';

	@Component({ components: { WtIcon } })
	export default class LangSelector extends Vue {
		@Prop()
		public langListIsLoading?: boolean;

		@Prop()
		public disableLanguageAddition?: boolean;

		@Prop({ default: false })
		public isLoading?: boolean;

		@Prop({ required: true })
		public sourceLang: string;

		@Prop({ default: () => null as any })
		public langs: ILanguageModel[] | null;

		@Prop({ default: () => null as any })
		public availableLangs: ILanguageModel[] | null;

		@Prop({ default: () => false })
		public hideOnOutsideClick: boolean;

		@Watch('searchField')
		searchFieldChanged() {
			this.selectedLanguageId = null;
			this.selectedLanguageIndex = -1;
		}

		public nameFilter = '';
		public showPanel = false;
		public selectedIndex = -1;
		public searchField = '';
		public selectedLanguageId: number = null;
		public selectedLanguageIndex = -1;

		private clickOutsideUnsub: (() => void) | null;

		public get selectedItem() {
			return this.langsList[this.selectedIndex];
		}

		public get langsList() {
			const languages = this.langs || langs;
			return languages.filter(
				(l) => l.englishName.toLocaleLowerCase().indexOf(this.searchField.toLocaleLowerCase()) !== -1,
			);
		}

		public get langsCount() {
			return this.langsList.length;
		}

		public get availableLangsList() {
			const projectLanguageIds = this.langs.map((l) => l.id);
			return langs.filter(
				(l) =>
					!projectLanguageIds.includes(l.id) &&
					this.sourceLang != l.cultureName &&
					l.englishName.toLocaleLowerCase().indexOf(this.searchField.toLocaleLowerCase()) !== -1,
			);
		}

		public get availableLangsCount() {
			return this.availableLangsList.length;
		}

		public handleChange() {
			this.selectedIndex = -1;
		}

		public test(e: Event) {
			console.log(e);
		}

		public handleKeyNavigation(e: any) {
			if (e.keyCode === 27) {
				this.showPanel = false;
				return;
			}

			const languages = this.langsList.concat(this.availableLangsList);
			const list = document.getElementById('list');

			if (languages.length === 0) {
				return;
			}

			if (this.selectedLanguageId === null) {
				if (e.keyCode === 40) {
					this.selectedLanguageId = languages[0].id;
					this.selectedLanguageIndex = 0;
				}
			} else {
				if (e.keyCode === 40) {
					if (languages[this.selectedLanguageIndex + 1] !== undefined) {
						this.selectedLanguageIndex += 1;
						this.selectedLanguageId =
							this.selectedLanguageIndex === -1 ? null : languages[this.selectedLanguageIndex].id;
						const lang = document.getElementById(`lang-${this.selectedLanguageId}`);
						lang.scrollIntoView({ block: 'nearest', inline: 'nearest' });
					}
				} else if (e.keyCode === 38) {
					if (this.selectedLanguageIndex > 0) {
						this.selectedLanguageIndex -= 1;
					} else {
						this.selectedLanguageIndex = -1;
					}
					this.selectedLanguageId =
						this.selectedLanguageIndex === -1 ? null : languages[this.selectedLanguageIndex].id;
					if (this.selectedLanguageId !== null) {
						const lang = document.getElementById(`lang-${this.selectedLanguageId}`);
						lang.scrollIntoView({ block: 'nearest', inline: 'nearest' });
					} else {
						list.scrollTop = 0;
					}
				} else if (e.keyCode === 13) {
					if (this.selectedLanguageId) {
						if (this.langsList.find((l) => l.id === this.selectedLanguageId)) {
							this.$emit('select-lang', languages[this.selectedLanguageIndex]);
							this.showPanel = false;
						} else {
							this.$emit('select-available-lang', languages[this.selectedLanguageIndex]);
							this.showPanel = false;
						}
					}
				}
			}
		}

		// public handleKeyDown(e: { keyCode: number }) {
		//     const down = 40;
		//     const up = 38;
		//     if (e.keyCode === 13) {
		//         this.select(this.selectedItem);
		//         return;
		//     } else if (e.keyCode === up) {
		//         this.selectedIndex = Math.max(0, this.selectedIndex - 1);
		//     } else if (e.keyCode === down) {
		//         this.selectedIndex = Math.min(this.langsList.length - 1, this.selectedIndex + 1);
		//     } else {
		//         return;
		//     }
		//     document.getElementById('lang-' + this.selectedItem.id).scrollIntoView({ block: 'nearest', inline: 'nearest' });
		// }

		public select(lang: ILanguageModel, e?: MouseEvent) {
			e?.preventDefault();
			e?.stopPropagation();

			this.searchField = '';
			this.$emit('select-lang', lang);
			this.showPanel = false;
		}

		public selectAvailableLang(lang: ILanguageModel, e?: MouseEvent) {
			e?.preventDefault();
			e?.stopPropagation();

			this.searchField = '';
			this.$emit('select-available-lang', lang);
			this.showPanel = false;
		}

		public togglePanel(/* e: MouseEvent */) {
			/*		e.preventDefault();
				e.stopPropagation(); */

			if (this.showPanel) {
				this.showPanel = false;
				return;
			}

			this.showPanel = true;
			this.nameFilter = '';
			this.$emit('show-lang-panel');
			setTimeout(() => {
				const input = this.$el.getElementsByTagName('input')[0];
				if (input) {
					input.focus();
				}
			});
		}

		public mounted() {
			window.addEventListener('blur', this.onWindowBlur);

			this.clickOutsideUnsub = onClickOutside(
				this.$root.$el as HTMLElement,
				this.$refs.container as Node,
				() => {
					this.showPanel = false;
				},
				() => this.showPanel,
			);
		}

		public onWindowBlur() {
			this.showPanel = false;
		}

		public beforeDestroy() {
			this.clickOutsideUnsub?.();
			window.removeEventListener('blur', this.onWindowBlur);
		}
	}
</script>

<style lang="less" src="./lang-selector.less"></style>
